import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent extends HeaderComponent {
  placeHolders:any;
  reportPlaceHolders:any;
  placeholders = environment.placeholders as { [key: string]: string };
  @Input() sidebarId: string = "sidebar";
  @Input() userFullName = '';
  @Input() userRoleType = '';
  
  readonly colorModes = [
    { name: 'en', text: 'English', icon: 'cilLanguage' },
    { name: 'fr', text: 'French', icon: 'cilLanguage' },
    // { name: 'auto', text: 'Auto', icon: 'cilContrast' }
  ];

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  merchantName:any;
  status:any;

  constructor(private classToggler: ClassToggleService,private cStorage: ClientSideStorageService, private translationService:TranslationService) {
    super();
    this.placeHolders = this.placeholders['Side_Menu_bar']
    this.reportPlaceHolders = this.placeholders['Reports_page']
    let marketplaceData = JSON.parse(this.cStorage.get("marketplaceData"));
    this.merchantName = marketplaceData.marketplace_name
    this.status = marketplaceData.status
    this.cStorage.updateStatus(this.status)
  }

  ngOnInit(){
    this.cStorage.status$.subscribe(name => {
      this.status = name; // Update in real-time
    });

    // Load from session if available
    this.cStorage.loadFullNameFromSession();
  }

  colorMode(param:any){
    this.translationService.changeLocale(param)
  }

  getStatusClass(status: string): any {
    switch(status) {
      case 'A': return 'active-chip';
      case 'P': return 'pending-chip';
      case 'S': return 'paused-chip'
      case 'E': return 'expired-chip';
      case 'IA': return 'inactive-chip';
      case 'D': return 'draft-chip';
      case 'U': return 'upcoming-chip';
      case 'R': return 'rejected-chip';
      case 'N': return 'new-chip';
      case 'C': return 'completed-chip';
      default: return '';
    }
  }
  
  getStatusText(status: string): any {
    switch(status) {
      case 'A': return this.reportPlaceHolders['LBL_ACTIVE'];
      case 'P': return this.reportPlaceHolders['LBL_PENDING'];
      case 'E': return this.reportPlaceHolders['LBL_EXPIRED'];
      case 'IA': return this.reportPlaceHolders['LBL_INACTIVE'];
      case 'D': return this.reportPlaceHolders['LBL_DRAFT'];
      case 'U': return this.reportPlaceHolders['LBL_UPCOMING'];
      case 'S': return this.reportPlaceHolders['LBL_PAUSED'];
      case 'R': return this.reportPlaceHolders['LBL_REJECTED'];
      case 'N': return this.reportPlaceHolders['LBL_NEW'];
      case 'C': return this.reportPlaceHolders['LBL_COMPLETED'];
      default: return '';
    }
  }

}
