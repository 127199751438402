import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable, of, Subscription } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService, NavigationService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
// import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { PreviewdealwidgetComponent } from '../sharedComponent/previewdealwidget/previewdealwidget.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from 'src/app/services/payment.service';
import { CanComponentDeactivate } from '../../../app/utils/can-deactivate.guard'
import { PaymentsummaryComponent } from '../paymentsummary/paymentsummary.component';

interface FormData {
  field1: string;
  field2: string;
  field3: string;
  // Add more fields as needed
}
export interface AgeRange {
  label: string;
  minAge: number;
  maxAge: number;
}


@Component({
  selector: 'app-addcampaign',
  templateUrl: './addcampaign.component.html',
  styleUrl: './addcampaign.component.scss'
})

export class AddcampaignComponent implements CanComponentDeactivate {
  showImage: boolean = true;
  hasUnsavedChanges = true;

  canDeactivate(): boolean {
    if (this.hasUnsavedChanges) {
      return window.confirm('You have unsaved changes. If you leave now, you will lose them. Are you sure you want to proceed?');
    }
    return true;
  }
  excludedStatuses = ['P', 'U', 'R', 'E', 'D', ""];
  private apiResponseSubscription: Subscription | null = null;
  isProd: boolean = true;
  payBtnLabel: string;
  paymentInitiate: boolean = false
  billAmount!: number;
  totalCost!: number;
  billCurrency: string = 'usd';
  billDescription!: string;
  type: any;
  brandId: any;
  dealId: any;
  deal_name: any;
  deal_uuid: any;
  deal_budget: any;
  postOfficeId: any;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  popUpMsg: any;
  toolTips: any;
  activeStep = 1;
  date = Date;
  fileName: any = null;
  allCategory: any;
  editResponse: any;
  brandimage: any;
  editData: any = null;
  submitted = false;
  images3Path: any = null;
  isFileUploaded = false;
  country: any;
  countryid: any;
  targetAudience = false;
  targetCity = false;
  macAddress: any;
  personID: any;
  countryData: any;
  userData: any;
  tagsDropdownSettings: IDropdownSettings = {};

  fData = new FormData();
  allTagsKewords: any = [];
  tagKeywords: any = [];

  favId: any;
  clickId: any;
  clickCost: any;
  favCost: any;
  claimCost: any;
  successCost: any;

  stateData: any;
  cityData: any;
  charCount: number = 0;
  dealTitlechar: number = 0;

  todate: any;
  maxDate: any;
  presentDate: any;
  presentDateTime: any;
  afterPresentDate: any;
  createCampaign = true;
  rejectedDealinfo: string | any;

  isSaveDraft = false;
  savedDraft = false;
  isValidStatus = false;
  isGoLive = false;
  isFlash = false;
  createFlash = false;
  btnLabel: any;
  btnMsg: any = "";
  isDraftRadiobtnVisible = true;
  isPauseRadiobtnVisible = true;
  isdiscountpercentenable = true;
  isdiscountdescription = false;
  budgetchanged = false;

  hoursOptions: number[] = [4, 6, 8, 12];
  selectedHour: number = 0;
  storeInfo: any;
  tagsInfo: any;

  startDate: any;
  endDate: any;

  gender = [{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "All", name: "All" }];
  ageRanges: AgeRange[] = [
    // { label: '0-12', minAge: 0, maxAge: 12 },
    { label: 'All', minAge: 1, maxAge: 100 },
    { label: '13-17', minAge: 13, maxAge: 17 },
    { label: '18-25', minAge: 18, maxAge: 25 },
    { label: '26-40', minAge: 26, maxAge: 40 },
    { label: '41-60', minAge: 41, maxAge: 60 },
    { label: '61-Above', minAge: 61, maxAge: 100 },
  ];
  selectedAgeRanges: AgeRange[] = [];
  lowerAgeGroup = [{ id: 0, lower_age: "0" }, { id: 10, lower_age: "10" }, { id: 20, lower_age: "20" }, { id: 30, lower_age: "30" }, { id: 40, lower_age: "40" },
  { id: 50, lower_age: "50" }, { id: 60, lower_age: "60" }, { id: 70, lower_age: "70" }, { id: 80, lower_age: "80" }];

  upperAgeGroup = [{ id: 10, upper_age: "10" }, { id: 20, upper_age: "20" }, { id: 30, upper_age: "30" }, { id: 40, upper_age: "40" },
  { id: 50, upper_age: "50" }, { id: 60, upper_age: "60" }, { id: 70, upper_age: "70" }, { id: 80, upper_age: "80" }];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  // tagCtrl = new FormControl('');
  filteredTags: Observable<string[]> | undefined;
  tagsKeywords: string[] = [];
  allTags: string[] = ['Greeting', 'Home', 'Dineout', 'Food', 'Bag'];
  countryInitials: any
  disTypeName: any;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | any;
  uuid: any;
  currency: any;
  announcer = inject(LiveAnnouncer);
  amountNote: any;
  // private paymentSuccessSubscription!: Subscription;
  // private paymentErrorSubscription!: Subscription;

  constructor(private notifyService: NotificationAlertService, private campaignService: CampaignService,
    private dashboardService: DashboardService, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute,
    private cStorage: ClientSideStorageService, private service: CommonUtilService, private commonUtilService: CommonUtilService,
    private confirmationDialogService: ConfirmationDialogService, private navigationService: NavigationService,
    private paymentService: PaymentService, private router: Router, private dialog: MatDialog, @Inject('COUNTRY_CONFIG') private countryConfig: any) {

    this.placeHolders = this.placeholders['Create_Deal']
    this.popUpMsg = this.placeholders['Pop_Up_Msg']
    this.toolTips = this.placeholders['tooltip']
    this.storeInfo = this.toolTips['TIP_LOCATION_DEAL_REDEEM']
    this.tagsInfo = this.toolTips['TIP_TAGS_KEYWORDS_HELPFUL']

    this.gender = [
      { id: "male", name: this.placeholders['locale'] === 'fr' ? 'Homme' : 'Male' },
      { id: "female", name: this.placeholders['locale'] === 'fr' ? 'Femme' : 'Female' },
      { id: "All", name: this.placeholders['locale'] === 'fr' ? 'Tous' : 'All' }
    ];
    this.disTypeName = this.placeholders['locale'] === 'fr' ? 'Sélectionnez le type de remise' : 'Select Discount Type'
    this.amountNote = this.isAmountAuthorized ? 'Your payment is authorized now you can proceed with submit for review' : 'Note: Once your payment is successfully authorized, you will be able to proceed with submitting your request for review.'
    this.currency = this.countryConfig.currency;
    this.payBtnLabel = 'Authorize Payment';
    if (environment.authUrl.toLowerCase().includes('app.ship2myid.com')) {
      this.isProd = false;
    }
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if (params["createFlash"]) {
        this.activeStep = 1
        this.showSection2 = true
        this.showSection1 = false
        this.createFlash = true;
      }
    });
    this.personID = JSON.parse(this.cStorage.get("userId"));
    // this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
    this.allCategory = this.dashboardService.getData();
    this.allCategory = this.allCategory.source._value.data;
    // this.allTagsKewords = this.campaignService.getAllTagsKeyword().subscribe((res: any) => {
    //   if (res?.body?.data) {
    //     this.allTagsKewords = res.body.data;
    //   }

    // });

    this.tagsDropdownSettings = {
      idField: "tag",
      textField: "tag",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    const curDate = new Date();
    this.presentDateTime = curDate
    this.createCampaign = true;
    this.presentDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.afterPresentDate = curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (curDate.getDate() + 1)).slice(-2);


    this.submitted = false;
    this.editData = this.campaignService.getData();

    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();
    this.navigationService.programmaticNavigation = this.editResponse ? true : false;
    this.uuid = this.editData?.uuid
    this.todate = new Date(this.editData?.available_to)
    this.maxDate = this.todate.getFullYear() +
      "-" +
      ("0" + (this.todate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + this.todate.getDate()).slice(-2);

    if (this.editResponse === false) {
      this.createForm();
    } else {
      this.editForm();
    }
    this.filteredTags = this.campaignBasicDetailForm.get('tagCtrl')?.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this.allTags.slice())),
    );



    if (this.userData) {
      this.route.data.subscribe((data: any) => {
        this.countryData = data.resolvedData.data
        if (this.countryData) {
          let countryId = parseInt(this.userData.country);
          let CountryValues = this.countryData.filter((country: any) => country.country_id === countryId);//this.countryData.filter((option:any) => option.country_id === this.userData.country);
          this.country = CountryValues[0].country_name;

          this.countryid = CountryValues[0].country_id
          if (this.countryid === 72) {
            this.countryInitials = 'B'
          }
          // this.countryData.forEach((element: any) => {
          //   if ((element.country_id).toString() === this.userData.country) {
          //     this.country = element.country_name;
          //     this.countryid = element.country_id
          //   }
          // });
          this.campaignService.eventCostByCountryCosts(this.countryid).subscribe((response: any) => {
            if (response?.body?.data?.countryEventsCostsList) {
              let event = response?.body?.data?.countryEventsCostsList;
              this.clickCost = event.find((el: any) => el.event_type === "DEAL_CLICK_EVENT");
              this.favCost = event.find((el: any) => el.event_type === "DEAL_FAVOURITE_EVENT");
              this.claimCost = event.find((el: any) => el.event_type === "DEAL_COUPON_CODE_VIEW_EVENT");
              this.successCost = event.find((el: any) => el.event_type === "SUCCESS_FEE");
              if (this.editResponse === false) {
                this.createbudgetForm();
              } else {
                this.editbudgetForm();
              }
            }
          });
        }


        if (this.editResponse === false) {
          this.createtargetForm();
          this.createValidityForm();
          // this.createbudgetForm();
        } else {
          this.editValidityForm();
          this.edittargetForm();
          // this.editbudgetForm();
        }
      });

    }
  }

  add(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();
    const value = typeof event === 'string' ? event : (event.value || '').trim();

    // Add our tag
    if (value) {
      this.tagsKeywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.campaignBasicDetailForm.get('tagCtrl')?.setValue(null);
  }

  remove(item: string): void {
    const index = this.tagsKeywords.indexOf(item);

    if (index >= 0) {
      this.tagsKeywords.splice(index, 1);

      this.announcer.announce(`Removed ${item}`);
    }

    this.campaignBasicDetailForm.get('tagCtrl')?.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagsKeywords.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.campaignBasicDetailForm.get('tagCtrl')?.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(item => item.toLowerCase().includes(filterValue));
  }

  private tagsArrayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.tagsKeywords.length === 0 ? { 'tagsRequired': true } : null;
    };
  }

  isFormValid = false;
  isValidityFormValid = false;
  ngOnInit(): void {
    const dontShowAgain = localStorage.getItem('dontShowImage');
    if (!dontShowAgain && !this.editResponse) {
      this.navigationService.programmaticNavigation = true;
      this.dialog.open(PreviewdealwidgetComponent, {
        data: { previewFromScreen: false },
        position: {},
        maxWidth: '60vw',
        maxHeight: '100vh'
      });
    }


    this.apiResponseSubscription = this.paymentService.apiResponse$.subscribe((response: any) => {

      if (response) {
        // Handle response
        console.log('Response received:', response);
        if (this.showSection2) {
          // this.isGoLive = true;
          // this.flashDateSave();
          // this.processPaymentsaveFlashDeal();
          this.paymentInitiated = true;
          this.isAmountAuthorized = true;
          this.payBtnLabel = 'Payment Authorized'
          this.v['budget'].disable();
          // this.processPaymentSaveDeal();
        } else {
          // this.dialog.closeAll();
          // this.isGoLive = true;
          this.paymentInitiated = true;
          this.isAmountAuthorized = true;
          this.payBtnLabel = 'Payment Authorized'
          this.v['budget'].disable();
          // this.processPaymentSaveDeal();

        }


        // Unsubscribe when a successful response is received
        // this.apiResponseSubscription.unsubscribe();
        this.paymentService.resetApiResponse();
        this.apiResponseSubscription = null;
      }
    });

    this.countrySelectionForState();
    // this.getBrandSpecificCategory();
    this.getCategory();
    this.selectedHour = this.hoursOptions[0];
    this.campaignBasicDetailForm.valueChanges.subscribe(() => {
      // this.isFormValid = this.campaignBasicDetailForm.valid;
      this.validateForm()
    });
    this.validityStatusForm.valueChanges.subscribe(() => {
      this.isValidityFormValid = this.validityStatusForm.valid;

      console.log(this.validityStatusForm.get('to')?.errors?.['dateRangeError'])
      if (this.validityStatusForm.value.from === 'Invalid date' || this.validityStatusForm.value.from === null || this.validityStatusForm.value.to === 'Invalid date') {
        // this.notifyService.showError("Please Select Dates", "Invalid Form");
        this.isValidityFormValid = this.validityStatusForm.invalid;
      }
    });

    this.campaignBasicDetailForm.get('discountPercentage')?.valueChanges.subscribe((value) => {
      if (this.f['discountType'].value === 'fixedDiscount') {
        let discountValue = value
        let discountText = this.placeholders['locale'] === 'fr' ? `Obtenez ${discountValue}% de` : `Get ${discountValue}% Off`;
        this.campaignBasicDetailForm.get('discountDescription')?.setValue(discountText);
      }
    });


    this.campaignBasicDetailForm.get('discountType')?.valueChanges.subscribe((value) => {


      if (value === 'fixedDiscount') {
        this.campaignBasicDetailForm.get('discountPercentage')?.enable();
        this.campaignBasicDetailForm.get('discountPercentage')?.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.max(100)
        ]);
        this.campaignBasicDetailForm.get('discountPercentage')?.updateValueAndValidity();


        this.campaignBasicDetailForm.get('discountDescription')?.disable();
        this.campaignBasicDetailForm.get('discountDescription')?.clearValidators();
        this.campaignBasicDetailForm.get('discountDescription')?.updateValueAndValidity();
        this.campaignBasicDetailForm.get('offerCode')?.setValue('');
        this.campaignBasicDetailForm.get('offerCode')?.enable();
        this.campaignBasicDetailForm.get('offerCode')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('offerCode')?.updateValueAndValidity();

        this.isdiscountpercentenable = true;
        this.isdiscountdescription = false;
      } else if(value === 'others') {
        this.campaignBasicDetailForm.get('discountPercentage')?.disable();
        this.campaignBasicDetailForm.get('discountPercentage')?.clearValidators();
        this.campaignBasicDetailForm.get('discountPercentage')?.updateValueAndValidity();
        this.campaignBasicDetailForm.get('discountDescription')?.setValue('');
        this.campaignBasicDetailForm.get('discountDescription')?.enable();
        this.campaignBasicDetailForm.get('discountDescription')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('discountDescription')?.updateValueAndValidity();
        this.campaignBasicDetailForm.get('offerCode')?.setValue('');
        this.campaignBasicDetailForm.get('offerCode')?.enable();
        this.campaignBasicDetailForm.get('offerCode')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('offerCode')?.updateValueAndValidity();

        this.isdiscountpercentenable = false;
        this.isdiscountdescription = true;
      } 
      else{
        this.campaignBasicDetailForm.get('discountPercentage')?.disable();
        this.campaignBasicDetailForm.get('discountPercentage')?.setValue(0);
        this.campaignBasicDetailForm.get('discountPercentage')?.clearValidators();
        this.campaignBasicDetailForm.get('discountPercentage')?.updateValueAndValidity();
        this.campaignBasicDetailForm.get('discountDescription')?.setValue('Promotion');
        this.campaignBasicDetailForm.get('discountDescription')?.disable();
        this.campaignBasicDetailForm.get('offerCode')?.disable();
        this.campaignBasicDetailForm.get('offerCode')?.clearValidators();
        this.campaignBasicDetailForm.get('offerCode')?.setValue('NA');
        // this.campaignBasicDetailForm.get('discountDescription')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('discountDescription')?.updateValueAndValidity();
        
        this.isdiscountpercentenable = true;
        this.isdiscountdescription = false;
      }
    });

    this.campaignBasicDetailForm.get('description')?.valueChanges.subscribe(value => {
      this.charCount = value.length;
    });
    this.campaignBasicDetailForm.get('title')?.valueChanges.subscribe(value => {
      this.dealTitlechar = value.length;
    });
  }

  validateForm() {
    this.isFormValid = this.campaignBasicDetailForm.valid && this.isFileUploaded;
  }

  getCategory() {
    this.dashboardService.getCategory().subscribe((response: any) => {
      this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      // this.allCategory.push({
      //   "category_id": 0,
      //   "category_type": "All",
      //   "status": "A",
      //   "image_url": "category/DineOut.png",
      //   "created_by": "clmcore",
      //   "created_date": "2023-07-19T08:38:46.469+00:00",
      //   "modified_by": "clmcore",
      //   "modified_date": "2023-07-19T08:38:46.469+00:00"
      // })
      this.dashboardService.setData(response?.body);

      if (response && this.editResponse) {
        this.detectCategories()
      }
    });
  }

  getBrandSpecificCategory() {
    this.dashboardService.getBrandCategory(this.userData['marketplace_id']).subscribe((response: any) => {
      // this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      this.allCategory.push({
        "category_id": 0,
        "category_type": "All",
        "status": "A",
        "image_url": "category/DineOut.png",
        "created_by": "clmcore",
        "created_date": "2023-07-19T08:38:46.469+00:00",
        "modified_by": "clmcore",
        "modified_date": "2023-07-19T08:38:46.469+00:00"
      })
      this.dashboardService.setData(response?.body);

      if (response && this.editResponse) {
        this.detectCategories()
      }
    });

  }


  campaignBasicDetailForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    category: new FormControl(''),
    offerCode: new FormControl(''),
    description: new FormControl(''),
    image: new FormControl(''),
    url: new FormControl(''),
    country: new FormControl(''),
    discountType: new FormControl(''),
    discountPercentage: new FormControl(''),
    discountDescription: new FormControl('')
  });
  targetForm: FormGroup = new FormGroup({
    country: new FormControl(''),
    select: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl('')
  });
  BudgetDetailForm: FormGroup = new FormGroup({
    cpc: new FormControl(''),
    cpf: new FormControl(''),
    currency: new FormControl(''),
    // budget: new FormControl(''),
  });
  validityStatusForm: FormGroup = new FormGroup({
    to: new FormControl(''),
    from: new FormControl(''),
    status: new FormControl(''),
    flashdate: new FormControl(''),
    selectedHour: new FormControl(''),
    budget: new FormControl(''),
  });

  createForm() {
    this.fileName = null;
    this.campaignBasicDetailForm = this.fb.group({
      id: [null],
      uuid: [null],
      title: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]], //Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      category: ['', Validators.required],
      offerCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      description: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: ['', [Validators.required]],
      url: ['', [this.websiteUrlValidator()]],
      discountType: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      discountPercentage: [{ value: '', disabled: true }, [Validators.required]],
      tag: ['',],
      tagCtrl: [null, [this.tagsArrayValidator()]],
      location: ['', [Validators.minLength(5),
      Validators.maxLength(200)]],
      discountDescription: [{ value: '', disabled: true }, [Validators.minLength(5),
      Validators.maxLength(50)]]
    }, {
      validators: this.atLeastOneRequiredValidator('url', 'location')
    });

    this.campaignBasicDetailForm.get('url')?.disable()
    this.campaignBasicDetailForm.get('location')?.disable()
  }

  selectedCategory: any;
  isCategoriesChanged: boolean = false;
  paymentDone: boolean = false;
  isAmountAuthorized: boolean = false;
  editForm() {
    let res = this.editData;
    // let tagKeyword = [];
    // res.
    this.paymentDone = res.paymentDone

    if (!this.createFlash) {
      this.showSection2 = res.flash ? true : false;
      this.showSection1 = !res.flash ? true : false;
    }

    this.isAmountAuthorized = !!res.authorizedAmount;
    this.payBtnLabel = this.isAmountAuthorized ? 'Payment Authorized' : 'Authorize Payment'
    this.charCount = res.product_description.length;
    this.dealTitlechar = res.product_name.length;
    this.tagsKeywords = res.keywords;
    if (res.categories.length === this.allCategory.length) {
      this.selectedCategory = res.categories
    } else {
      this.selectedCategory = res.categories
    }
    this.campaignBasicDetailForm = this.fb.group({
      id: [res.product_id],
      uuid: [res.uuid],
      title: [res.product_name, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]],//Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      category: [this.selectedCategory, Validators.required],
      offerCode: [res.coupon_code, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      description: [res.product_description, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: [res.product_image, Validators.required],
      url: [res.product_url, [this.websiteUrlValidator()]],
      discountType: [res.discountType, [Validators.required, Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      discountPercentage: [res.discountPercentage, [Validators.required]],
      tag: [res.keywords],
      tagCtrl: [],
      location: [res.location, [Validators.minLength(5),
      Validators.maxLength(200)]],
      discountDescription: [res.discountDescription, [Validators.minLength(5),
      Validators.maxLength(50)]]
    },
      {
        validators: this.atLeastOneRequiredValidator('url', 'location')
      });

    if ((res.product_url && res.location) || (res.product_url !== '' && res.location !== '')) {
      this.checkbox1 = true;
      this.checkbox2 = true;
    } else if (res.product_url || res.product_url !== '') {
      this.checkbox1 = true;
    } else if (res.location || res.location !== '') {
      this.checkbox2 = true;
    }


    if (res.discountType === 'fixedDiscount') {
      this.campaignBasicDetailForm.get('discountPercentage')?.disable();
      this.isdiscountpercentenable = true;
      this.isdiscountdescription = false;
    } else if(res.discountType === 'others'){
      this.campaignBasicDetailForm.get('discountDescription')?.disable();
      this.isdiscountpercentenable = false;
      this.isdiscountdescription = true;
    }
    else{
      this.campaignBasicDetailForm.get('discountPercentage')?.disable();
      this.campaignBasicDetailForm.get('offerCode')?.disable();
      this.isdiscountpercentenable = true;
      this.isdiscountdescription = false;
      
     
    }
    this.fileName = res.product_image;
    if (res.product_image != null) {
      this.isFileUploaded = true;
      this.brandimage = environment.assetsUrl + '/assets/' + res.product_image
      this.showImage = false;
    }
  }

  createtargetForm() {
    this.targetAudience = true;
    this.targetForm = this.fb.group({
      country: [this.country],
      select: ['EC'],
      state: [''],
      city: [''],
      gender: ['All'],
      lowerAge: [1],
      upperAge: [100]
    });
    this.selectedAgeRanges = this.ageRanges.filter(
      (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
    );
  }
  edittargetForm() {
    let res = this.editData;
    this.rejectedDealinfo = res.rejectedDealComments

    if (res.state !== null && res.state !== undefined && res.state !== "" && res.state !== "all") {
      // let stateId = parseInt(res.state);
      // let setStateId = this.stateData.filter((state: any) => state.state_id === stateId);
      // let statecode = this.editResponse ? setStateId[0].state_code:this.stateData[0]?.state_code;

      this.targetAudience = true;
      this.targetCity = true;
      this.targetForm = this.fb.group({
        //lower_age
        country: [this.country],
        select: ['AC'],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required],
        state: [res?.state],
        city: [res?.cities]
      });
      if (res?.lower_age === 1 && res?.upper_age === 100) {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge === res?.lower_age! && range.maxAge === res?.upper_age!
        );
      } else {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge >= res?.lower_age! && range.maxAge <= res?.upper_age!
        );
      }
    }
    else {
      this.targetAudience = true;
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC'],
        state: [res?.state],
        city: [res?.cities],
        gender: [res?.gender],
        lowerAge: [res?.lower_age],
        upperAge: [res?.upper_age]
      });
      if (res?.lower_age === 1 && res?.upper_age === 100) {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge === res?.lower_age! && range.maxAge === res?.upper_age!
        );
      } else {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge >= res?.lower_age! && range.maxAge <= res?.upper_age!
        );
      }
    }
  }
  createbudgetForm() {
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost?.cost],
      cpf: [this.favCost?.cost],
      currency: [this.clickCost?.currency],
      // budget: ['', Validators.required],
    });
  }
  editbudgetForm() {
    let res = this.editData;
    let setBudget = this.createFlash ? 0 : res.budget
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost?.cost],
      cpf: [this.favCost?.cost],
      currency: [this.clickCost?.currency],
      // budget: [setBudget, Validators.required],
    });
  }

  createValidityForm() {
    this.createCampaign = true;
    this.validityStatusForm = this.fb.group({
      to: ['', [Validators.required, this.dateRangeValidator.bind(this)]],
      from: ['', [Validators.required, this.dateRangeFromValidator.bind(this)]],
      status: [''],
      flashdate: [''],
      selectedHour: [''],
      budget: ['', Validators.required],
      // flashofferCode: ['', [Validators.minLength(2),
      // Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      // flashbudget: [''],
    });

    this.btnLabel = this.placeHolders['BTN_SAVE_AS_DRAFT']
    this.btnMsg = this.popUpMsg['MSG_SAVE_THIS_DEAL_DRAFT']
  }


  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromValue = new Date(this.validityStatusForm.get('from')?.value);
    const toValue = new Date(control.value);

    if (fromValue > toValue) {
      return { dateRangeError: true };
    }

    return null;
  }

  dateRangeFromValidator(control: AbstractControl): ValidationErrors | null {
    const fromValue = new Date(control.value);
    const toValue = new Date(this.validityStatusForm.get('to')?.value);

    if (fromValue > toValue) {
      return { dateRangeError: true };
    }

    return null;
  }

  calculateTimeDifference(fromTime: string, toTime: string): string {
    const fromDate = new Date(fromTime);
    const toDate = new Date(toTime);

    const diffInMs = toDate.getTime() - fromDate.getTime();

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

    return `${diffInHours}`; //hours, ${diffInMinutes % 60} minutes, ${diffInSeconds % 60} seconds
  }

  timeDifference: string = "";
  selectedDuration: any;
  flashDealDay: any;
  editValidityForm() {
    let res = this.editData;
    let setBudget = this.createFlash ? 0 : res.budget


    this.createCampaign = false;
    // let res = this.editData;
    if (res.available_from) {
      if (res.flash) {
        this.isFlash = res.flash;
        this.showSection2 = true;
        this.showSection1 = false;
        this.timeDifference = this.calculateTimeDifference(res.available_from, res.available_to);
        this.selectedDuration = parseInt(this.timeDifference)//this.hoursOptions.indexOf(parseInt(this.timeDifference));
        // this.selectedDuration = this.hoursOptions.filter(option => option === parseInt(this.timeDifference));
        res.available_from = new Date(res.available_from)
        let parsedDate = moment(res.available_from, "YYYY-MM-DD hh:mm:ss").local();
        this.flashDealDay = parsedDate.format("YYYY-MM-DD");
        res.available_from = parsedDate.format("HH:mm:ss");
        this.startDate = res.available_from && moment(res.available_from, moment.ISO_8601, true).isValid() ? res.available_from : null
        res.available_to = new Date(res.available_to)
        let parseDate = moment(res.available_to, "YYYY-MM-DD hh:mm:ss").local();
        res.available_to = parseDate.format("HH:mm:ss");
        this.endDate = res.available_to && moment(res.available_to, moment.ISO_8601, true).isValid() ? res.available_to : null


      } else {
        res.available_from = new Date(res.available_from)
        let parsedDate = moment(res.available_from, "YYYY-MM-DD");
        res.available_from = parsedDate.format("YYYY-MM-DD");
        this.startDate = res.available_from && moment(res.available_from, moment.ISO_8601, true).isValid() ? res.available_from : null
        res.available_to = new Date(res.available_to)
        let parseDate = moment(res.available_to, "YYYY-MM-DD");
        res.available_to = parseDate.format("YYYY-MM-DD");
        this.endDate = res.available_to && moment(res.available_to, moment.ISO_8601, true).isValid() ? res.available_to : null

        if (this.createFlash) {
          this.flashDealDay = this.presentDate;
          res.status = ''
        }
      }

    }

    this.flashDealDay = this.flashDealDay ? this.flashDealDay : null
    this.validityStatusForm = this.fb.group({
      to: [res.available_to, [Validators.required, this.dateRangeValidator.bind(this)]],
      from: [res.available_from, [Validators.required, this.dateRangeFromValidator.bind(this)]],
      status: [res.status],
      flashdate: [this.flashDealDay],
      selectedHour: [this.selectedDuration],
      budget: [setBudget, Validators.required],
      // flashofferCode: [res.coupon_code, [Validators.required, Validators.minLength(2),
      // Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      // flashbudget: [res.budget, Validators.required],
    });

    if (res.paymentDone || this.isAmountAuthorized) {
      this.v['budget'].disable();
    }

    if (this.v['status'].value === 'E' || this.v['status'].value === 'C') {
      this.f['offerCode'].disable();
      this.v['from'].disable();
      this.f['category'].disable();
      this.f['url'].disable();
      this.f['discountType'].disable();
      this.f['discountPercentage'].disable();
      this.f['discountDescription'].disable();
      this.f['title'].disable();
      this.f['description'].disable();
      this.f['location'].disable();
      if (res.flash) {
        this.v['to'].disable();
        this.v['flashdate'].disable();
        this.v['selectedHour'].disable();
      }
    }

    if (this.v['status'].value === 'A' || this.v['status'].value === 'S' || this.v['status'].value === 'U') {
      if (!this.createFlash) {
        this.f['offerCode'].disable();
        this.v['from'].disable();
        this.f['category'].disable();
        this.f['url'].disable();
        this.f['discountType'].disable();
        this.f['discountPercentage'].disable();
        this.f['discountDescription'].disable();
        this.f['title'].disable();
        this.f['description'].disable();
        this.f['location'].disable();
      }
      if (res.flash) {
        this.v['to'].disable();
        this.v['flashdate'].disable();
        this.v['selectedHour'].disable();
      }


    }

    if (this.v['status'].value === 'A' || this.v['status'].value === 'S') {
      this.isDraftRadiobtnVisible = false;
    }

    if (this.v['status'].value === 'D' || this.v['status'].value === 'P') {
      this.isPauseRadiobtnVisible = false
    }

    if (this.v['status'].value === 'A') {
      this.btnLabel = this.placeHolders['BTN_SAVE']
      this.btnMsg = this.popUpMsg['MSG_SAVE_THIS_DEAL']
    }
    else if (this.v['status'].value === 'D') {
      this.btnLabel = this.placeHolders['BTN_SAVE_AS_DRAFT']
      this.btnMsg = this.popUpMsg['MSG_SAVE_THIS_DEAL_DRAFT']
    }
    else {
      this.btnLabel = this.placeHolders['BTN_SAVE']
      this.btnMsg = this.popUpMsg['MSG_SAVE_THIS_DEAL']
    }


  }

  get b(): { [key: string]: AbstractControl } {
    return this.BudgetDetailForm.controls;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.campaignBasicDetailForm.controls;
  }
  get t(): { [key: string]: AbstractControl } {
    return this.targetForm.controls;
  }
  get v(): { [key: string]: AbstractControl } {
    return this.validityStatusForm.controls;
  }


  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url = control.value;
      if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        control.setValue('https://' + url);
      }
      return null;
    };
  }


  selectClick() {
    this.targetAudience = true;
    this.targetCity = false;
    let res = this.editData;
    if (res?.gender !== null && res?.gender !== undefined) {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required]
      });
    } else {

      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC', Validators.required],
        gender: ['All', Validators.required],
        lowerAge: [1, Validators.required],
        upperAge: [100, Validators.required]
      });
      this.selectedAgeRanges = this.ageRanges.filter(
        (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
      );
    }
  }
  selectClickCity() {
    this.targetAudience = true;
    this.targetCity = true;
    let res = this.editData;
    this.countrySelectionForState()
    if (res?.state !== null && res?.state !== undefined && res?.state !== '') {
      this.targetForm = this.fb.group({
        country: [this.country],
        state: [res?.state],
        city: [res?.cities],
        select: ['AC', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required]
      });
    } else {

      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['AC', Validators.required],
        state: [this.stateData[0]?.state_code],
        city: ['All'],
        gender: ['All', Validators.required],
        lowerAge: [1, Validators.required],
        upperAge: [100, Validators.required]
      });
      this.selectedAgeRanges = this.ageRanges.filter(
        (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
      );
    }

  }
  customClick() {
    this.targetAudience = true;
    this.targetCity = false;
    let res = this.editData;
    if (res?.gender !== null && res?.gender !== undefined) {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['C', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: ['', Validators.required],
        upperAge: ['', Validators.required]
      });
    } else {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['C', Validators.required],
        gender: ['', Validators.required],
        lowerAge: ['', Validators.required],
        upperAge: ['', Validators.required]
      });
    }

  }

  onSelectionChange(event: any) {
    const allOption = this.ageRanges.find(range => range.label === 'All');
    if (allOption) {
      if (event.value.includes(allOption)) {
        this.selectedAgeRanges = [allOption];
      } else {
        this.selectedAgeRanges = event.value.filter((range: AgeRange) => range.label !== 'All');
      }
    }
  }

  removeImage() {
    this.campaignBasicDetailForm.value.image = null;
    this.fileName = null;
    this.fData = new FormData();
    this.images3Path = null;
    const imageControl = this.campaignBasicDetailForm.get('image');
    this.imagePreview = null;

    // Reset the file input
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
      this.cdr.detectChanges();
    }
    if (imageControl) {
      const customError = { invalidImage: true }; // Custom validation error object
      imageControl.setErrors(customError);
      imageControl.markAsDirty(); // Mark control as touched to trigger validation messages
      this.campaignBasicDetailForm.updateValueAndValidity();
      this.isFormValid = this.campaignBasicDetailForm.valid;
      this.isFileUploaded = false;
      this.showImage = true;
      this.cdr.detectChanges();

    }

  }

  // Method to manually set validation state to true for a control
  setValidationTrue(controlName: string): void {
    const control = this.campaignBasicDetailForm.get(controlName);
    if (control) {
      control.setErrors(null); // Clear any existing errors
      control.markAsDirty(); // Mark control as touched to trigger validation
    }
  }

  updateValidators(controlName: string): void {
    const control = this.targetForm.get(controlName);
    const otherControlName = controlName === 'upperAge' ? 'lowerAge' : 'upperAge';
    const otherControl = this.targetForm.get(otherControlName);

    if (control && otherControl) {
      const selectedValue = control.value;
      const otherValue = otherControl.value;

      if (controlName === 'upperAge') {
        control.setValidators([Validators.required, Validators.min(+otherValue + 1)]);
        otherControl.setValidators([Validators.required, Validators.max(+selectedValue - 1)]);
      } else {
        control.setValidators([Validators.required, Validators.max(+otherValue - 1)]);
        otherControl.setValidators([Validators.required, Validators.min(+selectedValue + 1)]);
      }
      control.updateValueAndValidity();
      otherControl.updateValueAndValidity();
    }
  }


  // imageUpload() {
  //   debugger
  //   this.campaignService.uploadImage(this.fData).subscribe((res: any) => {
  //     debugger
  //     if (res?.data?.s3Path) {
  //       this.images3Path = res?.data?.s3Path;
  //       this.notifyService.showSuccess(
  //         "Image Successfully Uploaded",
  //         res?.status
  //       );
  //       this.isFormValid = this.campaignBasicDetailForm.valid;
  //       this.isFileUploaded = true;
  //     } else {
  //       this.notifyService.showSuccess(
  //         res?.message,
  //         res?.status
  //       );
  //     }
  //   });
  // }

  imageUpload() {
    this.campaignService.uploadImage(this.fData).pipe(
      catchError((error) => {

        // Handle the error
        this.notifyService.showError(
          "An error occurred while uploading the image,maximum allowed limit below 1MB.",
          "Upload Failed"
        );
        return of(null); // Return a safe fallback value to continue the stream
      })
    ).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.notifyService.showSuccess(
          "Image Successfully Uploaded",
          res?.status
        );
        this.isFormValid = this.campaignBasicDetailForm.valid;
        this.isFileUploaded = true;
      } else if (res) {
        this.notifyService.showError(
          res?.message || "An unknown error occurred.",
          res?.status || "Error"
        );
      }
    });
  }

  imagePreview: string | ArrayBuffer | null = null;
  uploadDoc(event: any) {
    let files = event.target.files[0];
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png" || doctypeName === "webp") &&
      files.size <= 300000) {
      this.fileName = files.name;
      this.fData.append("file", files);
      const imageControl = this.campaignBasicDetailForm.get('image');
      if (imageControl) {
        imageControl.setErrors(null); // Clear custom validation errors
        imageControl.markAsPristine(); // Mark control as pristine
        this.campaignBasicDetailForm.updateValueAndValidity();
        // this.isFormValid = this.campaignBasicDetailForm.valid;
      }
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
          this.showImage = false;
        };
        reader.readAsDataURL(file);
      }
      this.notifyService.showInfo("Image selected please upload", "")
    } else {
      this.notifyService.showError("The image must be in JPG, PNG, or JPEG format and should not exceed a size of 300 KB.", "Invalid File")
    }
    event.target.value = '';
  }

  formData: FormData = {
    field1: '',
    field2: '',
    field3: '',
    // Initialize other fields as needed
  };




  saveData1(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBasicDetails(data, this.images3Path, this.tagKeywords);
    } else {
      this.campaignService.setBasicDetails(data, this.images3Path, this.tagKeywords);
      // this.data.push(values);
    }
  }
  saveData2(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditTargetDetails(data);
    } else {
      this.campaignService.setTargetDetails(data);
      // this.data.push(values);
    }
  }
  saveData3(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBudgetDetail(data, this.userData['marketplace_id']);
    } else {
      this.campaignService.setBudgetDetail(data, this.userData['marketplace_id']);
      // this.data.push(values);
    }
  }



  nextStep1(form: any) {
    this.navigationService.programmaticNavigation = false;
    if (form.valid) {

      let values = this.campaignBasicDetailForm.value;

      if (this.isFileUploaded) {
        ++this.activeStep
        this.cdr.markForCheck();
      } else {
        this.notifyService.showError("Please upload the image", "Invalid Form");
        return;
      }

      // this.router.navigate(["/campaign/target"]);
      //   }
      // });
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }
  }

  nextStep2(form: any) {
    const lowerAge = Math.min(...this.selectedAgeRanges.map(range => range.minAge));
    const upperAge = Math.max(...this.selectedAgeRanges.map(range => range.maxAge));
    this.targetForm.get('lowerAge')?.setValue(lowerAge);
    this.targetForm.get('upperAge')?.setValue(upperAge);

    if (form.valid) {
      if (this.targetForm.invalid || this.selectedAgeRanges.length === 0) {
        this.notifyService.showError("Please provide mandatory details", "ERROR");
        return;
      } else {
        // this.confirmationDialogService
        //   .confirm("Confirmation ", "Are you sure you want to Save? ")
        //   .then((confirmed) => {
        //     if (confirmed) {
        let values = this.targetForm.value;
        this.saveData2(values);
        ++this.activeStep
        this.cdr.markForCheck();
        // this.router.navigate(["/campaign/budget"]);
        //   }
        // });
      }
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }
  }



  nextStep3(form: any) {



    if (form.valid) {
      if (this.BudgetDetailForm.invalid) {
        this.notifyService.showError("Please provide mandatory details", "ERROR");
        return;
      } else {
        // this.confirmationDialogService
        //   .confirm("Confirmation ", "Are you sure you want to Save? ")
        //   .then((confirmed) => {
        //     if (confirmed) {
        let values = this.BudgetDetailForm.value;
        this.saveData3(values);
        ++this.activeStep
        this.cdr.markForCheck();
        // this.router.navigate(["/campaign/validatstatus"]);
        //   }
        // });
      }
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }
  }

  nextStep(form: any) {
    this.isGoLive = true;
    if (this.validityStatusForm.value.from > this.presentDate) {
      this.notifyService.showInfo("Your Deal will be in 'Upcoming' state till the Mentioned Date Hits", "");
    }
    this.btnMsg = this.popUpMsg['MSG_SEND_THIS_DEAL_APPROVAL']
    this.saveCampaignDraft()

  }




  saveDeal() {
    let values = this.validityStatusForm.value;
    if ((this.validityStatusForm.value.from === 'Invalid date' || this.validityStatusForm.value.from === null || this.validityStatusForm.value.to === 'Invalid date') && this.showSection1) {
      this.notifyService.showError("Please Select Dates", "Invalid Form");
    } else {
      this.saveCampaignDraft()
    }

  }

  resultArray: string[] = [];




  private isArrayInObjectFormat(arr: any[]): boolean {
    // Check if every element in the array is an object with a 'tag' property
    return arr.every(item => typeof item === 'object' && item !== null && 'tag' in item);
  }

  onCardClick(step: number) {
    if (this.campaignBasicDetailForm.valid && this.isFileUploaded && this.BudgetDetailForm.valid) {
      this.activeStep = step;
      this.navigationService.programmaticNavigation = false;
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }
  }

  onPrevClick(step: number) {
    if (this.campaignBasicDetailForm.valid) {
      this.activeStep = step;
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }
  }



  getFormattedTitle() {
    return this.campaignBasicDetailForm.get('title')?.value || this.placeHolders['LBL_DEAL_TITLE'];
  }

  reset() {

    if (this.activeStep === 4) {
      this.validityStatusForm.controls["budget"].reset();
    }
    if (this.activeStep === 2) {
      this.targetForm.reset()
      this.targetForm.get('select')?.setValue('EC');
      this.selectClick();

    }

  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", this.popUpMsg['MSG_CANCEL_THIS_OPERATION'])
      .then((confirmed) => {
        if (confirmed) {
          this.navigationService.programmaticNavigation = true;
          this.router.navigate(["/dashboard"]);
        }
      });
  }


  countrySelectionForState() {
    // this.administartiveForm.controls["state"].reset();
    // this.administartiveForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    const selectedCountryCode = this.countryid//this.targetForm.get('country')?.value;
    this.stateData = [];
    this.cityData = [];
    this.service.getState(selectedCountryCode, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res.body?.data;

      let stateId = parseInt(this.editData?.state);
      let setStateId = this.stateData.filter((state: any) => state.state_id === stateId);
      let stateid = this.editResponse && !Number.isNaN(stateId) ? setStateId[0].state_id : this.stateData[0]?.state_id;

      this.stateSelectionForCity(stateid);
      this.cityData.push({
        "zip_id": 0,
        "city_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "country_id": 0
      })
    });
  }

  stateSelectionForCity(event: any) {
    // this.targetForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    // let stateId = parseInt(this.editData.state);
    const selectedStateCode = event.target !== undefined ? parseInt(event.target.value) : event;
    let setStateId = this.stateData.filter((state: any) => state.state_id === selectedStateCode);
    let statecode = setStateId[0].state_code

    //this.targetForm.get('state')?.value;
    const selectedCountryCode = this.countryid//this.targetForm.get('country')?.value;
    this.service.getCity(selectedCountryCode, statecode, this.country, this.macAddress).subscribe((res: any) => {

      this.cityData = [{
        "zip_id": null,
        "city_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "country_id": 0
      }, ...(res.body?.data)]
    });
  }

  selectLiveAction(selectedStatus: string) {
    if (selectedStatus === 'A') {

      this.saveAction(selectedStatus)

    }
    else if (selectedStatus === 'S') {

      this.saveAction(selectedStatus)

    }
  }


  saveAction(selectedStatus: string) {
    if (this.editData) {
      this.createCampaign = false;
      this.isValidStatus = selectedStatus === 'A' ? true : false;
      let res = this.editData;
      let parsedDate = moment(res.available_from, "YYYY-MM-DD");
      res.available_from = parsedDate.format("YYYY-MM-DD");

      let parseDate = moment(res.available_to, "YYYY-MM-DD");
      res.available_to = parseDate.format("YYYY-MM-DD");

      this.validityStatusForm.get("status")?.patchValue(selectedStatus);

      // this.validityStatusForm = this.fb.group({
      //   // to: [res.available_to,[Validators.required, this.dateRangeValidator.bind(this)]],
      //   // from: [res.available_from],
      //   status: [selectedStatus]
      // });

    } else {
      this.isValidStatus = true;
      this.validityStatusForm.get("status")?.patchValue(selectedStatus);
    }
  }


  showSection1 = true; // Initial state
  showSection2 = false;

  toggleSections() {
    this.showSection1 = !this.showSection1;
    this.showSection2 = !this.showSection2;
  }








  SetendTime() {
    this.validityStatusForm.value.to = this.incrementTime(this.validityStatusForm.value.from);
    this.validityStatusForm.get('to')?.setValue(this.validityStatusForm.value.to);
    this.v['to'].disable();
  }

  incrementTime(originalTime: string): string {
    const [hoursStr, minutesStr] = originalTime.split(':');
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);

    // Add 4 hours
    hours += parseInt(this.v["selectedHour"].value);

    // Handle hour overflow
    if (hours >= 24) {
      hours -= 24;
    }

    // Format hours and minutes back into "HH:mm"
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  incrementDateTime(originalDateTime: string, incrementHours: number): string {

    // Parse the original date and time
    const date = new Date(originalDateTime);

    // Add the increment hours to the current time
    date.setHours(date.getHours() + incrementHours);

    // Format the new date as "yyyy-MM-dd"
    const newDate = date.toLocaleDateString().split('T')[0];
    const formatedDate = this.convertDateToISOFormat(newDate)
    // Format the new time as "HH:mm"
    const newTime = date.toTimeString().slice(0, 5);
    const combinedDateTime = `${formatedDate}T${newTime}`
    return combinedDateTime;
  }

  convertDateToISOFormat(dateStr: string): string {
    // Split the date string by '/'
    const [month, day, year] = dateStr.split('/');

    // Format the date to 'YYYY-MM-DD'
    const isoFormattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

    return isoFormattedDate;
  }


  compareDates(fromDateStr: string, toDateStr: string): number {
    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);

    // Compare the dates using getTime() method of Date
    if (fromDate.getTime() > toDate.getTime()) {
      return 1; // From date is greater than to date
    } else if (fromDate.getTime() < toDate.getTime()) {
      return -1; // From date is less than to date
    } else {
      return 0; // Dates are equal
    }
  }

  detectCategoryChange() {
    let res = this.editData
    let selectedCategories = this.allCategory.filter((value: any) => value.category_id !== 0);
    if (res.categories.length < selectedCategories.length && selectedCategories.length > 1) {
      this.categorySelect(0);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
      }

    } else if (res.categories.length === selectedCategories.length && selectedCategories.length > 1) {
      this.categorySelect(0);
    }
    else {
      this.selectedCategory = res.categories[0]
    }
  }

  selectedCategories: number[] = []
  categorySelect(event: any) {
    if (event.value === 0 || event === 0) {
      let selectedCategories = this.allCategory.map((category: any) => category.category_id);
      this.selectedCategories = selectedCategories.filter((value: any) => value !== 0);
      this.selectedCategories.forEach(id => this.processCategories(id));
    } else {
     
      this.selectedCategories = event.value !== undefined ? [event.value] : [event];
      this.selectedCategories.flat().forEach(id => this.processCategories(id));
      // this.processCategories(this.selectedCategories[0])
    }

  }

  processCategories(categoryId: number) {
    this.allCategory.forEach((category: any) => {
      if (category.category_id === categoryId) {
        const categoryType = category.category_type;
        // Check if the tagsKeywords array already contains the categoryType
        if (!this.tagsKeywords.includes(categoryType)) {
          // If it doesn't exist, push the categoryType
          this.tagsKeywords.push(categoryType);
        }
      }
    }
    );
    this.campaignBasicDetailForm.get('tagCtrl')?.updateValueAndValidity();
  }

  detectCategories() {
    let res = this.editData
    let selectedCategories = this.allCategory.filter((value: any) => value.category_id !== 0);
    if (res.categories.length === this.allCategory.length) {
      this.categorySelect(0);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
        if (this.validityStatusForm.value.from !== 'Invalid date') {
          this.isValidityFormValid = this.validityStatusForm.valid;
        }
      }

    }
    else {
      this.selectedCategory = res.categories
      this.categorySelect(this.selectedCategory);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
        if (this.validityStatusForm.value.from !== 'Invalid date') {
          this.isValidityFormValid = this.validityStatusForm.valid;
        }
      }
    }
  }

  openImagePreview() {
    this.navigationService.programmaticNavigation = true;
    this.dialog.open(PreviewdealwidgetComponent, {
      data: { previewFromScreen: true },
      position: {},
      maxWidth: '60vw',
      maxHeight: '100vh'
    });
  }

  atLeastOneRequiredValidator(
    controlName1: string,
    controlName2: string
  ): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control1 = formGroup.get(controlName1);
      const control2 = formGroup.get(controlName2);

      if (!control1 || !control2) {
        return null; // Return null if controls are not found (shouldn't happen)
      }

      const isValid = control1.value || control2.value;

      if (!isValid) {
        control1.setErrors({ required: true });
        control2.setErrors({ required: true });
        return { atLeastOneRequired: true };
      } else {
        control1.setErrors(null);
        control2.setErrors(null);
      }

      if (!this.checkbox1 && !this.checkbox2) {
        this.showError = true;
        control1.setErrors({ required: true });
        control2.setErrors({ required: true });
        return { atLeastOneRequired: true };
      } else {
        this.showError = false;
        control1.setErrors(null);
        control2.setErrors(null);
      }

      return null;
    };
  }

  back() {
    this.paymentInitiate = !this.paymentInitiate;
  }

  pay() {

    this.billAmount = this.v['budget'].value;
    const fee = this.billAmount * 0.029;


    // Calculate the total cost by adding the fee to the billAmount
    const finalCost = (this.billAmount + fee) * 0.029
    this.totalCost = parseFloat((this.billAmount + finalCost).toFixed(2));
    this.brandId = this.userData.marketplace_id;
    this.dealId = this.campaignBasicDetailForm.value.id
    this.deal_name = this.campaignBasicDetailForm.value.title
    this.deal_uuid = this.editData.uuid
    this.deal_budget = this.validityStatusForm.value.budget
    this.postOfficeId = parseInt(this.userData.country)
    this.billDescription = 'Payment authorized'
    this.type = 'campaign'
    this.paymentInitiate = true;
  }


  getDynamicClass() {
    return this.placeholders['locale'] === 'en' ? 'classEn' : 'classFr';
  }


  // Refactoring Code and methods here onwards

  combinedFromDateTime: any
  combinedToDateTime: any
  private buildCampaignPayload(isFlashDeal: boolean = false): any {

    if (isFlashDeal) {
      this.presentDate
      this.validityStatusForm.value.from
      this.validityStatusForm.value.to

      if (this.presentDate && this.validityStatusForm.value.from && this.v['to'].value) {
        const dateParts = this.presentDate.split('-'); // Split the date into year, month, and day
        const fromtimeParts = this.v['from'].value.split(':');
        const totimeParts = this.v['to'].value.split(':'); // Split the time into hours and minutes

        if (this.validityStatusForm.value.from) {
          this.combinedFromDateTime = new Date(Date.UTC(
            +dateParts[0], // Year
            +dateParts[1] - 1, // Month (JavaScript months are 0-indexed)
            +dateParts[2], // Day
            +fromtimeParts[0], // Hours
            +fromtimeParts[1] // Minutes
          ));
          const dateTimeString = this.combinedFromDateTime.toISOString().slice(0, 19); // "2024-04-15 10:30:00"
          this.combinedFromDateTime = new Date(dateTimeString);
        }
        // Create a new Date object using the parts and combine date and time

        if (this.v['to'].value) {
          this.combinedToDateTime = new Date(Date.UTC(
            +dateParts[0], // Year
            +dateParts[1] - 1, // Month (JavaScript months are 0-indexed)
            +dateParts[2], // Day
            +totimeParts[0], // Hours
            +totimeParts[1] // Minutes
          ));
          const dateTimeString = this.combinedToDateTime.toISOString().slice(0, 19);
          this.combinedToDateTime = dateTimeString;

        }
        this.combinedToDateTime = this.incrementDateTime(this.combinedFromDateTime, parseInt(this.v['selectedHour'].value));
        this.combinedToDateTime = new Date(this.combinedToDateTime);
        this.isFlash = true;
        this.btnMsg = this.popUpMsg['MSG_YOU_WANT_TO_SAVE']
      }
    }

    let values = this.validityStatusForm.value;
    // Use moment.js to check for validity
    let fromDate = moment(values.from, moment.ISO_8601, true);
    let toDate = moment(values.to, moment.ISO_8601, true);

    // Assign null if invalid
    values.from = fromDate.isValid() ? fromDate.hours(0).minutes(0).seconds(1).utc().format() : null;
    values.to = toDate.isValid() ? toDate.hours(23).minutes(59).seconds(59).utc().format() : null;

    const payload: any = {
      categories: this.selectedCategories[0],
      product_description: this.f['description'].value,
      // product_image: this.images3Path || null,
      coupon_code: this.f['offerCode'].value,
      product_name: this.f['title'].value,
      product_id: this.campaignBasicDetailForm.value.id,
      discountPercentage: this.f['discountPercentage'].value,
      discountType: this.f['discountType'].value,
      discountDescription: this.f['discountDescription'].value,
      keywords: this.tagsKeywords,
      merchant_id: this.userData['marketplace_id'],
      // product_url: this.f['url'].value,
      // location: this.f['location'].value || null,
      cities: this.t['city']?.value || 'All',
      state: this.t['state']?.value || '',
      country: this.countryid,
      budget: this.v['budget']?.value || null,
      flash: isFlashDeal,
      noOfHours: isFlashDeal ? this.v['selectedHour'].value : null,
      available_from: isFlashDeal ? this.combinedFromDateTime : this.validityStatusForm.value.from || null,
      available_to: isFlashDeal ? this.combinedToDateTime : this.validityStatusForm.value.to || null
    };

    if (this.checkbox1) {
      payload.product_url = this.f['url'].value
    } else {
      payload.product_url = ''
    }

    if (this.checkbox2) {
      payload.location = this.f['location'].value || null
    } else {
      payload.location = ''
    }




    if (this.images3Path !== null && this.images3Path !== undefined) {
      payload.product_image = this.images3Path;
    } else {
      payload.product_image = this.f['image']?.value
    }


    if (this.t['gender']) {
      payload.gender = this.t['gender'].value;
      payload.lower_age = this.t['lowerAge']?.value;
      payload.upper_age = this.t['upperAge']?.value;
    }
    return payload;
  }

  saveCampaignDraft() {

    if (!this.campaignBasicDetailForm.valid) {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return;
    }

    const dialogMsg = this.btnMsg || this.popUpMsg['MSG_YOU_WANT_TO_SAVE'];
    this.confirmationDialogService.confirm("Confirmation", dialogMsg).then((confirmed) => {
      if (!confirmed) return;

      const payload = this.showSection2 ? this.buildCampaignPayload(true) : this.buildCampaignPayload(false);
      payload.status = this.getStatusForPayload();

      this.saveOrUpdateCampaign(payload);
    });
  }

  private getStatusForPayload(): string {

    const fromValue = this.formatDate(this.validityStatusForm.value.from);
    if (fromValue > this.presentDate && this.isGoLive) return "P";
    if (fromValue === this.presentDate && this.isGoLive) return "P";
    if (this.isGoLive) return "P";
    return this.v['status']?.value || "D";
  }

  private formatDate(date: any): string {
    return moment(new Date(date)).format("YYYY-MM-DD");
  }



  private saveOrUpdateCampaign(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const isUpdate = Boolean(this.campaignBasicDetailForm.value.id);
      const action = isUpdate ? this.campaignService.updateCampaign(payload) : this.campaignService.addCampaign(payload);

      action.subscribe(
        (res: any) => {
          const message = res.body?.status === 'Error' ? res.body?.message : this.popUpMsg['SAVED_SUCCESFULLY'];
          res.body?.status === 'Error'
            ? this.notifyService.showError(message, res.body?.status)
            : this.notifyService.showSuccess(message, res.body?.status);

          // Perform any additional actions after saving
          if (!this.paymentInitiated) {
            this.afterSaveActions(isUpdate);
          } else {
            this.paymentInitiated = false;
          }

          // Resolve the promise with the response data
          resolve(res);
        },
        (error) => {
          // Reject the promise if there's an error
          this.notifyService.showError("Failed to save campaign", "Error");
          reject(error);
        }
      );
    });
  }

  private afterSaveActions(isUpdate: boolean) {
    this.isSaveDraft = true;
    this.hasUnsavedChanges = false;
    this.navigationService.programmaticNavigation = true;
    this.router.navigate(["/dashboard"]);
  }


  paymentInitiated: boolean = false;
  async initiatePaymentProcess() {

    this.paymentInitiated = true;
    let dealId = this.campaignBasicDetailForm.value.id;

    // If dealId is not present, call processPaymentSaveDeal
    if (!dealId) {
      const saveDealResponse = await this.processPaymentSaveDeal();

      if (saveDealResponse) {
        this.openPaymentDialog(saveDealResponse);
        this.campaignBasicDetailForm.patchValue({ id: saveDealResponse?.body.data.product_id });
        this.campaignBasicDetailForm.patchValue({ uuid: saveDealResponse?.body.data.uuid });
        this.paymentInitiated = false;
      }
    } else {
      // If dealId is already there, open the dialog directly
      this.openPaymentDialog();
      this.paymentInitiated = false;
    }
  }

  async processPaymentSaveDeal() {

    if (!this.campaignBasicDetailForm.valid) {
      this.notifyService.showError("Please provide mandatory details", "ERROR");
      return null;
    }

    const payload = this.showSection2 ? this.buildCampaignPayload(true) : this.buildCampaignPayload(false);
    // payload.paymentDone = true;
    payload.status = this.getStatusForPayload();

    // Assuming saveOrUpdateCampaign returns a promise that resolves with response data
    return await this.saveOrUpdateCampaign(payload);
  }

  openPaymentDialog(saveDealResponse: any = null) {
    const res = saveDealResponse?.body.data || this.editData
    if (res !== null) {
      if (res?.flash) {
        this.isFlash = res.flash;
        // this.showSection2 = true;
        // this.showSection1 = false;
        // this.timeDifference = this.calculateTimeDifference(res.available_from, res.available_to);
        // this.selectedDuration = parseInt(this.timeDifference)//this.hoursOptions.indexOf(parseInt(this.timeDifference));
        // this.selectedDuration = this.hoursOptions.filter(option => option === parseInt(this.timeDifference));
        res.available_from = new Date(res.available_from)
        let parsedDate = moment(res.available_from, "YYYY-MM-DD hh:mm:ss").local();
        this.flashDealDay = parsedDate.format("YYYY-MM-DD");
        res.available_from = parsedDate.format("HH:mm:ss");
        this.startDate = res.available_from
        res.available_to = new Date(res.available_to)
        let parseDate = moment(res.available_to, "YYYY-MM-DD hh:mm:ss").local();
        res.available_to = parseDate.format("HH:mm:ss");
        this.endDate = res.available_to


      } else {
        res.available_from = new Date(res.available_from)
        let parsedDate = moment(res.available_from, "YYYY-MM-DD");
        res.available_from = parsedDate.format("YYYY-MM-DD");
        this.startDate = res.available_from
        res.available_to = new Date(res.available_to)
        let parseDate = moment(res.available_to, "YYYY-MM-DD");
        res.available_to = parseDate.format("YYYY-MM-DD");
        this.endDate = res.available_to
      }
    }
    console.log(saveDealResponse)
    const dialogData = {
      billAmount: this.v['budget'].value,
      billCurrency: this.billCurrency,
      billDescription: 'Payment authorized',
      brandId: this.userData.marketplace_id,
      dealId: saveDealResponse?.body.data.product_id || this.campaignBasicDetailForm.value.id,
      postOfficeId: parseInt(this.userData.country),
      deal_name: this.campaignBasicDetailForm.value.title,
      type: 'campaign',
      deal_uuid: saveDealResponse?.body.data.uuid || this.campaignBasicDetailForm.value.uuid,
      deal_budget: this.validityStatusForm.value.budget,
      startDate: this.startDate || saveDealResponse?.body.data.available_from,
      endDate: this.endDate || saveDealResponse?.body.data.available_to,
      imagePreview: this.imagePreview,
      brandimage: this.brandimage,
      useremail: JSON.parse(this.cStorage.get("userName")),
      userFullName: JSON.parse(this.cStorage.get("userFullName")),
      brandname: this.userData.marketplace_name
    };

    this.dialog.open(PaymentsummaryComponent, {
      width: '60%',
      panelClass: 'custom-dialog-margin',
      data: dialogData,
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',
    });
  }

  checkbox1: boolean = false;
  checkbox2: boolean = false;
  showError: boolean = false;


  onCheckboxChange(): void {


    if (this.checkbox1) {
      this.campaignBasicDetailForm.get('url')?.enable()
    } else {
      this.campaignBasicDetailForm.get('url')?.disable()
    }

    if (this.checkbox2) {
      this.campaignBasicDetailForm.get('location')?.enable()
    } else {
      this.campaignBasicDetailForm.get('location')?.disable()
    }


    if (!this.checkbox1 && !this.checkbox2) {
      this.showError = true;

      this.campaignBasicDetailForm.get('url')?.setErrors({ atLeastOneRequired: true });
      this.campaignBasicDetailForm.get('location')?.setErrors({ atLeastOneRequired: true });

      // control1.setErrors({ required: true });
      // control2.setErrors({ required: true });
      // return { atLeastOneRequired: true };
    } else {
      this.showError = false;
      this.campaignBasicDetailForm.get('url')?.setErrors(null);
      this.campaignBasicDetailForm.get('location')?.setErrors(null);
    }
  }


  get isStatusAllowed(): boolean {
    // Log to check the status value
    return !this.excludedStatuses.includes(this.v['status']?.value);
  }

  get isValidPay(): boolean {

    return (this.isAmountAuthorized && this.isValidityFormValid) || (this.isAmountAuthorized || !this.isValidityFormValid);
  }


  ngOnDestroy() {
    if (this.apiResponseSubscription) {
      this.apiResponseSubscription.unsubscribe();
    }
  }

}
