import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { PaymentService } from 'src/app/services/payment.service';
import { RetargetingService } from 'src/app/services/retargeting.service';
import { environment } from 'src/environments/environment';
import { PaymentsummaryComponent } from '../../paymentsummary/paymentsummary.component';
import { MatDialog } from '@angular/material/dialog';

interface MessageData {
  sentOn: Date;
  description: string;
  couponCode: string;
  messagesSent: number;
  messagesViewed: number;
  totalSpent: number;
}

interface TableRow {
  sentOn: Date;
  description: string;
  couponCode: string;
  status?: string;
  messagesSent: number;
  messagesViewed: number;
  totalSpent: number;
  appliedFilter: string;
  originalData?: any;  // This will hold the entire original object
}

export interface AgeRange {
  label: string;
  minAge: number;
  maxAge: number;
}

@Component({
  selector: 'app-dealsretargeting',
  templateUrl: './dealsretargeting.component.html',
  styleUrl: './dealsretargeting.component.scss'
})
export class DealsretargetingComponent {
  private apiResponseSubscription: Subscription | null = null;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  tableData: TableRow[] = [];
  report_id: any;
  activeTab = 'messaging';
  selectedGender: string = '';
  selectedAgeRange: string = '';
  filteredAgeRanges: AgeRange[] = [];
  selectedState: string = '';
  selectedCity: string = '';
  dealData: any;
  userData: any;
  userId: any;
  country: any;
  countryName: any;
  macAddress: any;
  activePage: number = 1;
  totalAmountSpent: number = 0;
  targetProspectus: number = 0;
  msgSendCost: any;
  charCount: number = 0;
  countryData: any;

  messageForm!: FormGroup;

  ageRanges: AgeRange[] = [
    // { label: 'All', minAge: 1, maxAge: 100 },
    { label: '13-17', minAge: 13, maxAge: 17 },
    { label: '18-25', minAge: 18, maxAge: 25 },
    { label: '26-40', minAge: 26, maxAge: 40 },
    { label: '41-60', minAge: 41, maxAge: 60 },
    { label: '61-Above', minAge: 61, maxAge: 100 },
  ];

  ageRangeLabel: string = ''

  messageData: TableRow[] = [
    {
      sentOn: new Date('2024-08-11T10:00:00'),
      description: 'Summer Sale',
      couponCode: 'SUMMER20',
      messagesSent: 1000,
      messagesViewed: 800,
      appliedFilter: '',
      totalSpent: 5000
    }
    // Add more data objects here
  ];

  paymentInitiate: boolean = false
  billAmount!: number;
  billCurrency: string = 'usd';
  billDescription!: string;
  brandId: any;
  type: any;
  spent:any;


  constructor(private router: Router, private fb: FormBuilder, private _activateRoute: ActivatedRoute, private retargetingService: RetargetingService, private paymentService: PaymentService,
    private clientStorage: ClientSideStorageService, private notifyService: NotificationAlertService, private service: CommonUtilService, private campaignService: CampaignService,private dialog:MatDialog) {
    this.placeHolders = this.placeholders['Messaging_Page']
    this.report_id = this._activateRoute.snapshot.paramMap.get('id');
    this.userData = JSON.parse(this.clientStorage.get("marketplaceData"));
    this.userId = JSON.parse(this.clientStorage.get("userId"));
    this.country = JSON.parse(this.clientStorage.get("country"));
    this.macAddress = JSON.parse(this.clientStorage.get("macAddress"));

  }

  ngOnInit() {


    this.apiResponseSubscription = this.paymentService.apiResponse$.subscribe((response: any) => {
      if (response) {
        // Handle response
        console.log('Response received:', response);
        // this.isGoLive = true;
        // this.processPaymentSaveDeal();
        if (response.statusCode === '200') {
          // this.onSubmit();
        }
        else {
          this.notifyService.showError("Payment failed!",
            'ERROR')
        }


        // Unsubscribe when a successful response is received
        // this.apiResponseSubscription.unsubscribe();
        this.paymentService.resetApiResponse();
        this.apiResponseSubscription = null;
      }
      else {
        const rowData = this.retargetingService.getData();
        console.log(rowData)
        if (rowData) {
          this.dealData = rowData
          this.spent = this.dealData.details.totalSpend.toFixed(2)
          // Use the data as needed
          this.activeTab = this.dealData.deal.status !== 'A' ? 'history' : 'messaging';
          // Optionally clear the data after retrieving it
          this.filterAgeRanges(this.dealData.deal.lower_age, this.dealData.deal.upper_age);
          this.ageRangeLabel = this.getAgeRangeLabel(this.dealData.deal.lower_age, this.dealData.deal.upper_age);
          this.retargetingService.clearData();
          this.eventCost();
          this.getMessageHistory();
          this.countrySelectionForState();
          this.getFilteredData();
        } else {
          this.router.navigate(["/messaging"]);
        }

        this.initForm();
        this.messageForm.get('messageDescription')?.valueChanges.subscribe(value => {
          this.charCount = value.length;
        });
      }
    });
  }

  initForm() {
    this.messageForm = this.fb.group({
      targetProspectus: ['', [Validators.required, Validators.min(1)]],
      couponCode: ['', [Validators.required, Validators.minLength(3)]],
      messageDescription: ['', [Validators.required, Validators.maxLength(300)]],
      totalCost: ['', [Validators.required, Validators.min(0)]]
    });

    this.messageForm.get('targetProspectus')?.valueChanges.subscribe(value => {
      this.calculateTotalCost(value);
    });
  }

  eventCost() {
    this.campaignService.eventCostByCountryCosts(this.userData['country']).subscribe((response: any) => {
      if (response?.body?.data?.countryEventsCostsList) {
        let event = response?.body?.data?.countryEventsCostsList;
        this.msgSendCost = event.find((el: any) => el.event_type === "DEAL_FAVORITE_DM");
      }
    });
  }

  costTotal: any;
  calculateTotalCost(targetProspectusValue: number) {
    const totalCost = targetProspectusValue * this.msgSendCost.cost;
    this.messageForm.get('totalCost')?.setValue(totalCost.toFixed(2));
    this.costTotal = totalCost
  }


  recordTotalCount: any;

  getMessageHistory() {
    let data = {
      keywords: [],
      pagination: this.activePage,
      filterStatus: '',
      dealId: this.dealData.deal.product_id
    };

    this.retargetingService.getDealMsgHistory(data, this.userData.marketplace_id).subscribe((res: any) => {
      this.recordTotalCount = res?.body?.data?.totalMessages;

      this.tableData = res?.body?.data?.campaignMessages.map((item: any) => ({
        sentOn: item.createdDate,
        description: item.composeMessage,
        couponCode: item.discountCode,
        status: item.status,  // Note: Changed from item.deal.status to item.status based on your API response
        messagesSent: item.messagesSent,
        messagesViewed: item.totalMessageViewed,
        totalSpent: (item.messagesSent * this.msgSendCost.cost).toFixed(2),
        appliedFilter: item.appliedFilter,
        originalData: item  // Store the entire original item
      }));
      this.totalAmountSpent = this.tableData.reduce((total, row) => total + row.totalSpent, 0);
      this.messageData = this.tableData
    });
  }

  onSubmit() {
    if (this.messageForm.valid) {

      let data = {
        sendMessageTo: "selected",
        userIdList: this.dealData.details.pendingUserIds,
        favoritedDealId: this.dealData.deal.product_id,
        gender: this.selectedGender,
        ageRange: this.selectedAgeRange,
        state: this.selectedState,
        city: this.selectedCity,
        country: this.dealData.deal.country,
        occasionDeal: {
          dealType: 3,
          composeMessage: this.messageForm.value.messageDescription,
          discountCode: this.messageForm.value.couponCode,
          marketplace_id: this.userData.marketplace_id,
          status: 1,
          startDate: this.dealData.deal.available_from,//  "2024-07-15T00:00:00.000+00:00",
          endDate: this.dealData.deal.available_to,//"2024-07-30T00:00:00.000+00:00",
          msgToBePurhcased: this.messageForm.value.targetProspectus,
          paymentMade: true
        }
      }
      console.log(data);

      this.retargetingService.addMessage(data).subscribe((res: any) => {
        this.notifyService.showSuccess(
          "Message successfully added !!",
          "Success"
        );
        this.router.navigate(["/messaging"]);
      });
      // Handle form submission
    } else {
      // Mark all fields as touched to trigger validation messages
      Object.keys(this.messageForm.controls).forEach(key => {
        const control = this.messageForm.get(key);
        control!.markAsTouched();
      });
    }
  }

  getFilteredData() {
    let data = {
      userId: this.userId,
      dealId: this.dealData.deal.product_id,
      gender: this.selectedGender,
      ageRange: this.selectedAgeRange,
      state: this.selectedState,
      city: this.selectedCity,
      country: this.dealData.deal.country
    }

    this.retargetingService.getFilteredProspects(data, this.userData.marketplace_id).subscribe((res: any) => {
      let FilteredRecords = res?.body?.data?.FILTERED_PROSPECTS;
      console.log(FilteredRecords)
      this.targetProspectus = FilteredRecords.filteredPendingProspects;
      this.messageForm?.get('targetProspectus')?.setValue(this.targetProspectus);
    });
  }

  stateData: any;
  cityData: any;

  countrySelectionForState() {
    // this.administartiveForm.controls["state"].reset();
    // this.administartiveForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    const selectedCountryCode = parseInt(this.dealData.deal.country)//this.targetForm.get('country')?.value;
    this.stateData = [];
    this.cityData = [];
    this.service.getState(selectedCountryCode, this.country, this.macAddress).subscribe((res: any) => {
      const stateDataFromResponse = res.body?.data

      this.stateData = [{
        "zip_id": 0,
        "state_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "state_id": ""
      }, ...stateDataFromResponse];
    });

  }


  stateSelectionForCity(event: any) {
    // this.targetForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    // let stateId = parseInt(this.editData.state);
    const selectedStateCode = event.target !== undefined ? parseInt(event.target.value) : event;
    let setStateId = this.stateData.filter((state: any) => state.state_id === selectedStateCode);
    let statecode = setStateId[0].state_code

    //this.targetForm.get('state')?.value;
    const selectedCountryCode = parseInt(this.dealData.deal.country)//this.targetForm.get('country')?.value;
    this.service.getCity(selectedCountryCode, statecode, this.country, this.macAddress).subscribe((res: any) => {

      const cityDataFromResponse = res.body?.data;

      this.cityData = [{
        "zip_id": null,
        "city_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "country_id": 0
      }, ...cityDataFromResponse]//res.body?.data;

    });
  }


  getAgeRangeLabel(lowerAge: number, upperAge: number): string {
    // Sort ranges by specificity (smallest range first)
    const sortedRanges = [...this.ageRanges].sort((a, b) =>
      (a.maxAge - a.minAge) - (b.maxAge - b.minAge)
    );

    // Find the most specific matching range
    for (const range of sortedRanges) {
      if (lowerAge >= range.minAge && upperAge <= range.maxAge) {
        return range.label;
      }
    }

    if (lowerAge === 1 && upperAge === 100) {
      return 'All'
    }

    // If no match found, return a custom label
    return `${lowerAge}-${upperAge}`;
  }


  filterAgeRanges(lowerAge: number, upperAge: number) {
    this.filteredAgeRanges = this.ageRanges.filter(range => {
      return (range.minAge >= lowerAge && range.maxAge <= upperAge) ||
        (range.minAge <= lowerAge && range.maxAge >= upperAge);
    });
  }

  back() {
    this.paymentInitiate = !this.paymentInitiate;
  }

  pay() {
    this.billAmount = parseFloat(this.messageForm.controls['totalCost'].value);
    this.brandId = this.userData.marketplace_id;
    this.billDescription = 'Payment for message creation'
    this.type = 'Retargeting'
    this.paymentInitiate = true;
  }

  openPaymentDialog() {
    
    
    // this.paymentInitiate = true;
    // if (this.paymentInitiate) {
    this.dialog.open(PaymentsummaryComponent, {
      width: '60%',
      panelClass: 'custom-dialog-margin',
      // Adjust width as needed
      data: {
        // billAmount: this.v['budget'].value,
        // billCurrency: this.billCurrency,
        // billDescription: 'Payment authorized',
        // brandId: this.userData.marketplace_id,
        // dealId: this.campaignBasicDetailForm.value.id,
        // postOfficeId: parseInt(this.userData.country),
        deal_name: this.dealData.deal.product_name,
        // type: 'campaign',
        // deal_uuid: this.editData.uuid,
        // deal_budget: this.validityStatusForm.value.budget,
        startDate: this.dealData.deal.available_from,
        endDate: this.dealData.deal.available_to,
        // imagePreview: this.imagePreview,
        brandimage: environment.assetsUrl + '/assets/' + this.dealData.deal.product_image,
        useremail: JSON.parse(this.clientStorage.get("userName")),
        brandname: this.userData.marketplace_name,
        userFullName: JSON.parse(this.clientStorage.get("userFullName")),
        billAmount : parseFloat(this.messageForm.controls['totalCost'].value),
        brandId : this.userData.marketplace_id,
        billDescription : 'Payment for message',
        type : 'Retargeting'
        //  this.paymentInitiate = true;
      },
    });
    // }
  }
}
