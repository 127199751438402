<div>
    <div class="row">
        <!-- <div class="div-30">Deals Title</div> -->
        <mat-card style="height: 15vh;background-color: #36849d26;">
            <div class="row">
                <div class="col-10 mt-3">
                    <span class="div-30">{{ getFormattedTitle() }}
                        <mat-chip *ngIf="this.v['status'].value === 'D'" class="status-chip draft">Draft</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'A'" class="status-chip active">Active</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'P'" class="status-chip pending">Pending</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'S'" class="status-chip pending">Paused</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'U'"
                            class="status-chip upcoming">Upcoming</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'C'"
                            class="status-chip upcoming">Completed</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'R'"
                            class="status-chip rejected">Rejected</mat-chip>
                        <mat-chip *ngIf="this.v['status'].value === 'E'" class="status-chip rejected">Expired</mat-chip>
                        <svg *ngIf="isFlash" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                            viewBox="0 0 48 48">
                            <path fill="#dba400" d="M33 22h-9.4L30 5H19l-6 21h8.6L17 45z" />
                        </svg>
                    </span>
                </div>
                <!-- <div *ngIf="this.v['status'].value !== ''" class="col-2 mt-3">
                    <mat-chip *ngIf="this.v['status'].value === 'D'" class="status-chip draft">Draft</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'A'" class="status-chip active">Active</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'P'" class="status-chip pending">Pending</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'S'" class="status-chip pending">Paused</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'U'" class="status-chip upcoming">Upcoming</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'C'" class="status-chip upcoming">Completed</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'R'" class="status-chip rejected">Rejected</mat-chip>
                    <mat-chip *ngIf="this.v['status'].value === 'E'" class="status-chip rejected">Expired</mat-chip>
                </div> -->
            </div>
        </mat-card>
        <!-- <div class="col-2">
        <div>
            <mat-chip *ngIf="this.v['status'].value === 'R'" class="status-chip rejected">Rejected</mat-chip>

            <svg matTooltip="{{rejectedDealinfo}}" matTooltipPosition="above" *ngIf="this.v['status'].value === 'R'"
                xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                <g fill="none" stroke="#f23607" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke-width="1.5"
                        d="M21.25 7.506v6.607a3.963 3.963 0 0 1-3.964 3.965h-2.643l-2.18 2.18a.636.636 0 0 1-.925 0l-2.18-2.18H6.713a3.964 3.964 0 0 1-3.964-3.965V7.506a3.964 3.964 0 0 1 3.964-3.964h10.572a3.964 3.964 0 0 1 3.964 3.964m-9.244 7.743v-5.05" />
                    <path stroke-width="2" d="M11.898 6.994h.006" />
                </g>
            </svg>
        </div>
    </div> -->
    </div>

    <div style="margin-top: -1.5rem;">
        <!--start Navigation for Steppers -->
        <div class="row">
            <div class="row col-12">
                <div class="col-3">
                    <mat-card (click)="onCardClick(1)" class="step-card div-32"
                        [ngClass]="{ 'active': activeStep === 1 }">
                        <div class="div-33" [ngClass]="getDynamicClass()">{{placeHolders['LBL_DEAL_DETAILS']}}</div>
                        <!-- <div class="div-34">Enter deal details</div> -->
                    </mat-card>
                </div>
                <div class="col-3">
                    <mat-card (click)="onCardClick(2)" class="step-card div-32"
                        [ngClass]="{ 'active': activeStep === 2 }">
                        <div class="div-33" [ngClass]="getDynamicClass()">{{placeHolders['LBL_TARGET_AUDIENCE']}}</div>
                        <!-- <div class="div-34">Select the target audience</div> -->
                    </mat-card>
                </div>
                <div class="col-3">
                    <mat-card (click)="onCardClick(3)" class="step-card div-32"
                        [ngClass]="{ 'active': activeStep === 3 }">
                        <div class="div-33" [ngClass]="getDynamicClass()">Pricing Details</div>
                        <!-- {{placeHolders['LBL_BUDGET_DETAILS']}} -->
                        <!-- <div class="div-34">Set budget</div> -->
                    </mat-card>
                </div>
                <div class="col-3">
                    <mat-card (click)="onCardClick(4)" class="step-card div-32"
                        [ngClass]="{ 'active': activeStep === 4 }">
                        <div class="div-33" [ngClass]="getDynamicClass()">{{placeHolders['LBL_BUDGET_DETAILS']}}</div>
                        <!-- {{placeHolders['LBL_EXPIRY_DETAILS']}}  -->
                        <!-- <div class="div-34">Enter validity</div> -->
                    </mat-card>
                </div>
            </div>
        </div>
        <!--End  Navigation for Tabs -->
        <div class="row pt-2">
            <div *ngIf="this.uuid" style="width: 35%; margin-bottom: -30px; color: gray;">Unique Deal ID : {{uuid}}
            </div>
            <div class="mb-2" *ngIf="this.activeStep === 1">
                <button type="button" (click)="openImagePreview()" class="btn btn-secondary"
                    style="background-color: #36849d80 ; float: right; margin-right: 24px;padding: 4px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32">
                        <circle cx="23" cy="24" r="2" fill="black" />
                        <path fill="black"
                            d="M30.777 23.479C29.354 20.149 26.3 18 23 18s-6.354 2.15-7.777 5.479L15 24l.223.522C16.646 27.85 19.7 30 23 30s6.354-2.15 7.777-5.478L31 24zM23 28c-2.206 0-4-1.794-4-4s1.794-4 4-4s4 1.794 4 4s-1.794 4-4 4" />
                        <path fill="black"
                            d="M14 28H9V8h14v7h2V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v24c0 1.103.897 2 2 2h5zM9 4h14v2H9z" />
                    </svg>
                    {{placeHolders['LBL_MOBILE_VIEW_SAMPLE']}} </button>
            </div>
            <div div class="col-12">
                <mat-card style="padding: 20px; width: 98%;">
                    <div class="form-container">
                        <div class="row">
                            <div *ngIf="isProd">
                                <div *ngIf="createCampaign===true" style="float: left;margin-bottom: 15px;"
                                    class="form-check form-switch"><input (click)="toggleSections()" type="checkbox"
                                        id="customSwitch1" class="form-check-input"><label for="customSwitch1"
                                        class="form-check-label" style="color: red;
                                font-weight: 500;">{{placeHolders['LBL_CREATE_FLASH_DEAL']}}</label>
                                </div>
                            </div>
                            <!-- //FLASH DEAL UNCOMMENT ABOVE TO WORK  -->
                            <div *ngIf="showSection2" class="alert alert-warning">
                                <span *ngIf="createFlash">{{placeHolders['MSG_DEAL_COPIED_NOW']}}&nbsp;</span>
                                <strong>{{placeHolders['MSG_FLASH_DEAL']}}</strong>&nbsp;
                                {{placeHolders['MSG_ONCE_CREATED_LASTS']}}
                            </div>
                            <div *ngIf="isCategoriesChanged" class="alert alert-danger">
                                <span>'Deal Categories' updated please reverify and &nbsp;</span>
                                <strong>Save </strong>&nbsp;again
                            </div>
                        </div>
                        <!-- Campaign Details Step 1-->
                        <div *ngIf="activeStep === 1">
                            <form #formStep1="ngForm" [formGroup]="campaignBasicDetailForm"
                                (ngSubmit)="nextStep1(campaignBasicDetailForm)">
                                <div class="col-12 row">
                                    <div class="col-7 form-group">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_DEAL_TITLE']}}</label>
                                        <svg style="margin-left: 4px;"
                                            matTooltip="eg: Buy More, Save More: Up to 30% Off!"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <input type="text" class="form-control" formControlName="title"
                                            placeholder="{{placeHolders['LBL_PH_ENTER_DEAL_TITLE']}}"
                                            [ngClass]="{ 'is-invalid': f['title'].errors  && (f['title'].dirty || f['title'].touched ) }"
                                            required />
                                        <div style="float: inline-end; font-size: small;">
                                            {{placeHolders['LBL_CHAR_COUNT']}}: {{
                                            dealTitlechar}}/50</div>
                                        @if (f['title'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['title'].errors['required']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_REQUIRED']}}</div>
                                            }
                                            @if (f['title'].errors['minlength']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_MIN_2_CHARS']}}</div>
                                            }
                                            @if (f['title'].errors['maxlength']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_MAX_50_CHARS']}}</div>
                                            }
                                            @if (f['title'].errors['pattern']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_ALPHANUMERIC']}}</div>
                                            }
                                        </div>
                                        }
                                    </div>
                                    <div class="col-4 form-group">
                                        <label class="asterix"
                                            for="Categories">{{placeHolders['LBL_DEAL_CATEGORIES']}}</label>
                                        <svg style="margin-left: 4px;"
                                            matTooltip="Select the category that this deal belongs to."
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <mat-form-field class="col-12">
                                            <mat-select formControlName="category" class="form-control multi-select"
                                                multiple (selectionChange)="categorySelect($event)">
                                                @for (category of allCategory; track category) {
                                                <mat-option [value]="category.category_id">{{category.category_type}}
                                                </mat-option>
                                                }
                                            </mat-select>
                                            <mat-error style="margin-top: -15px;margin-left: -15px;"
                                                *ngIf="{ 'is-invalid':  f['category'].errors && (f['category'].dirty || f['category'].touched )}">
                                                {{placeHolders['MSG_ERR_CATEGORY_REQUIRED']}}
                                            </mat-error>
                                        </mat-form-field>

                                    </div>

                                </div>
                                <div class="col-12 row pt-2">

                                    <div class="col-3 form-group">
                                        <label class="asterix"
                                            for="discountType">{{placeHolders['LBL_DISCOUNT_TYPE']}}</label>
                                        <svg style="margin-left: 4px;"
                                            matTooltip="Pick the type of discount to be offered"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <!-- <input type="text" title="eg:BUY 1 GET 1 FREE !!" formControlName="discountType"
                                        style="margin-top: 10px;" class="form-control" placeholder="Discount type"
                                        [ngClass]="{ 'is-invalid': f['discountType'].errors  && (f['discountType'].dirty || f['discountType'].touched ) }"
                                        required /> -->
                                        <mat-select required id="discountType" class="form-control"
                                            formControlName="discountType">
                                            <mat-option value="" disabled>{{disTypeName}}</mat-option>
                                            <mat-option
                                                value="fixedDiscount">{{placeHolders['LBL_DROP_FIXED_DISCOUNT']}}</mat-option>
                                            <mat-option value="promotions">Promotions</mat-option>
                                            <mat-option value="others">{{placeHolders['LBL_DROP_OTHERS']}}</mat-option>
                                        </mat-select>
                                        @if (f['discountType'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['discountType'].errors['required']) {
                                            <div>{{placeHolders['MSG_ERR_DISCOUNTTYPE_REQUIRED']}}</div>
                                            }
                                            @if (f['discountType'].errors['minlength']) {
                                            <div>{{placeHolders['MSG_ERR_DISCOUNTTYPE_MIN_2']}}</div>
                                            }
                                            @if (f['discountType'].errors['pattern']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_ALPHANUMERIC']}}</div>
                                            }
                                        </div>
                                        }

                                    </div>

                                    <div class="col-4 form-group" *ngIf="isdiscountpercentenable">
                                        <label class="asterix"
                                            for="discountPercentage">{{placeHolders['LBL_DISCOUNT_PERCENT']}}(%)</label>
                                        <svg style="margin-left: 4px;" matTooltip="Enter number between 1-100"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <input title="eg:50" type="number" formControlName="discountPercentage"
                                            (keypress)="preventDecimal($event)" class="form-control"
                                            placeholder="{{placeHolders['LBL_DISCOUNT_0_100']}} " min="1" max="100"
                                            [ngClass]="{ 'is-invalid': f['discountPercentage'].errors  && (f['discountPercentage'].dirty || f['discountPercentage'].touched ) }"
                                            required />
                                        @if ( f['discountPercentage'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['discountPercentage'].errors['pattern']) {
                                            <div>{{placeHolders['MSG_ERR_VALID_PERCENTAGE']}}</div>
                                            }
                                            @if (f['discountPercentage'].errors['max'] ||
                                            f['discountPercentage'].errors['min']) {
                                            <div>Enter number between 1-100</div>
                                            }
                                        </div>
                                        }
                                    </div>
                                    <div class="col-4 form-group" *ngIf="isdiscountdescription">
                                        <label class="asterix"
                                            for="discountDescription">{{placeHolders['LBL_DISCOUNT_DESCRIPTION']}}</label>
                                        <svg style="margin-left: 4px;" matTooltip="eg: Buy 1 Get 1 Free!"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <input title="eg:BUY 1 GET 1 FREE !" type="text"
                                            formControlName="discountDescription" class="form-control"
                                            placeholder="{{placeHolders['LBL_BUY_1_GET_1']}}"
                                            [ngClass]="{ 'is-invalid': f['discountDescription'].errors  && (f['discountDescription'].dirty || f['discountDescription'].touched ) }" />
                                    </div>
                                    <div *ngIf="!isPromotion" class="col-4 form-group">
                                        <label class="asterix" pattern="^[a-zA-Z0-9]+$"
                                            for="CompanyName">{{placeHolders['LBL_DISCOUNT_CODE']}}</label>
                                        <svg style="margin-left: 4px;"
                                            matTooltip="Code used to redeem this discount. eg: STARTSHOPPING20"
                                            matTooltipPosition="above" matTooltipClass="custom-tooltip"
                                            xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                                            viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <input type="text" class="form-control" formControlName="offerCode"
                                            placeholder="{{placeHolders['LBL_PH_DISCOUNT_CODE']}}"
                                            [ngClass]="{ 'is-invalid':  f['offerCode'].errors  && (f['offerCode'].dirty || f['offerCode'].touched ) }"
                                            required />
                                        @if ( f['offerCode'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['offerCode'].errors['required']) {
                                            <div>{{placeHolders['MSG_ERR_OFFERCODE_REQUIRED']}}</div>
                                            }
                                            @if (f['offerCode'].errors['minlength']) {
                                            <div>{{placeHolders['MSG_ERR_OFFERCODE_MIN_2']}}</div>
                                            }
                                            @if (f['offerCode'].errors['maxlength']) {
                                            <div>{{placeHolders['MSG_ERR_OFFERCODE_MAX_20']}}</div>
                                            }
                                            @if (f['offerCode'].errors['pattern']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_ALPHANUMERIC']}}</div>
                                            }
                                        </div>
                                        }
                                    </div>

                                    <div *ngIf="isPromotion" class="col-4 form-group">
                                        <label pattern="^[a-zA-Z0-9]+$" for="CompanyName">{{toolTips['TIP_2']}}</label>
                                        <svg style="margin-left: 4px;" matTooltip="{{toolTips['TIP_4']}}"
                                            matTooltipPosition="above" matTooltipClass="custom-tooltip"
                                            xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                                            viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <input type="text" class="form-control" formControlName="offerCode"
                                            placeholder="{{toolTips['TIP_1']}}"
                                            [ngClass]="{ 'is-invalid':  f['offerCode'].errors  && (f['offerCode'].dirty || f['offerCode'].touched ) }" />
                                        <!-- @if ( f['offerCode'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['offerCode'].errors['required']) {
                                            <div>{{toolTips['TIP_3']}}</div>
                                            }
                                            @if (f['offerCode'].errors['minlength']) {
                                            <div>{{placeHolders['MSG_ERR_OFFERCODE_MIN_2']}}</div>
                                            }
                                            @if (f['offerCode'].errors['maxlength']) {
                                            <div>{{placeHolders['MSG_ERR_OFFERCODE_MAX_20']}}</div>
                                            }
                                            @if (f['offerCode'].errors['pattern']) {
                                            <div>{{placeHolders['MSG_ERR_TITLE_ALPHANUMERIC']}}</div>
                                            }
                                        </div>
                                        } -->
                                    </div>
                                </div>
                                <div class="col-12 row pt-3">
                                    <div class="col-11 form-group">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_DEAL_DESCRIPTION']}}</label>
                                        <svg style="margin-left: 4px;"
                                            matTooltip="Summarize the main points of the deal and terms"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <textarea class="form-control" rows="3" formControlName="description"
                                            placeholder="{{placeHolders['LBL_PH_DESCRIPTION']}}"
                                            [ngClass]="{ 'is-invalid':  f['description'].errors  && (f['description'].dirty || f['description'].touched ) }"
                                            required></textarea>
                                        <div style="float: inline-end; font-size: small;">
                                            {{placeHolders['LBL_CHAR_COUNT']}}: {{
                                            charCount}}/500</div>
                                        @if ( f['description'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['description'].errors['required']) {
                                            <div>{{placeHolders['MSG_ERR_DESCRIPTION_REQUIRED']}}</div>
                                            }
                                            @if (f['description'].errors['minlength']) {
                                            <div>{{placeHolders['MSG_ERR_DESCRIPTION_MIN_5_CHARS']}}</div>
                                            }
                                            @if (f['description'].errors['maxlength']) {
                                            <div>{{placeHolders['MSG_ERR_DESCRIPTION_500_MAX']}}</div>
                                            }
                                        </div>
                                        }
                                        <small class="form-text text-muted" style="font-size: 14px;">
                                            <ul>
                                                <li style="margin-left: -25px;">{{placeHolders['MSG_MAX_LIMT_500']}}
                                                </li>
                                                <li style="margin-left: -25px;">
                                                    {{placeHolders['MSG_PLEASE_INCLUDE_TERMS']}}
                                                </li>
                                            </ul>
                                        </small>

                                    </div>

                                </div>

                                <div class="col-12 row pt-2">
                                    <label class="asterix" for="checkbox">Choose at least ONE of the below
                                        options</label>
                                    <!-- First Checkbox and Input -->
                                    <div class="col-12 row pt-2">
                                        <div class="col-3">
                                            <input type="checkbox" id="checkbox1" [(ngModel)]="checkbox1"
                                                [ngModelOptions]="{standalone: true}" (change)="onCheckboxChange()"
                                                style="zoom:1.5;" />
                                            <label for="checkbox1"> &nbsp; Click here to redeem online</label>
                                        </div>
                                        <div class="pt-1 col-6">
                                            <label for="CompanyName">{{placeHolders['LBL_MERCHANT_WEBSITE']}}</label>
                                            <svg style="margin-left: 4px;"
                                                matTooltip="Enter the destination URL where the deal can be accessed or redeemed."
                                                matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>
                                            <input type="text" class="form-control" pattern="^[^\s]+$"
                                                formControlName="url" placeholder="{{placeHolders['LBL_PH_ENTER_URL']}}"
                                                [ngClass]="{ 'is-invalid':  f['url'].errors  && (f['url'].dirty || f['url'].touched ) }"
                                                required />
                                            @if ( f['url'].errors) {
                                            <div class="invalid-feedback">
                                                @if (f['url'].errors['required']) {
                                                <div>{{placeHolders['MSG_ERR_URL_REQUIRED']}}</div>
                                                }
                                                @if (f['url'].errors['minlength']) {
                                                <div>Url must be at least 5 characters</div>
                                                }
                                            </div>
                                            }
                                        </div>

                                    </div>

                                    <!-- Second Checkbox and Input -->

                                    <div class="col-12 row">
                                        <div class="col-3">
                                            <input type="checkbox" id="checkbox2" [(ngModel)]="checkbox2"
                                                [ngModelOptions]="{standalone: true}" (change)="onCheckboxChange()"
                                                style="zoom:1.5;" />
                                            <label for="checkbox2"> &nbsp; Click here to redeem in store</label>
                                        </div>

                                        <div class="col-6 pt-1">
                                            <label for="CompanyName">{{placeHolders['LBL_STORE']}}</label>
                                            <svg style="margin-left: 4px;" matTooltip="{{storeInfo}}"
                                                matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>
                                            <textarea formControlName="location" class="form-control" rows="2"
                                                placeholder="{{placeHolders['LBL_ENTER_ADDRESS']}}"
                                                [ngClass]="{ 'is-invalid':  f['location'].errors  && (f['location'].dirty || f['location'].touched ) }"
                                                required></textarea>
                                        </div>

                                    </div>

                                    <!-- <div class="col-6 form-group">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_MERCHANT_WEBSITE']}}</label>
                                        <input type="text" class="form-control" pattern="^[^\s]+$" formControlName="url"
                                            placeholder="{{placeHolders['LBL_PH_ENTER_URL']}}"
                                            [ngClass]="{ 'is-invalid':  f['url'].errors  && (f['url'].dirty || f['url'].touched ) }"
                                            required />
                                        @if ( f['url'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['url'].errors['required']) {
                                            <div>{{placeHolders['MSG_ERR_URL_REQUIRED']}}</div>
                                            }
                                            @if (f['url'].errors['minlength']) {
                                            <div>Url must be at least 5 characters</div>
                                            }
                                        </div>
                                        }

                                    </div>
                                    <div style="font-size: medium; color: grey; width: 7%;"
                                        matTooltip="{{toolTips['TIP_PLEASE_FILL_AT_LEAST_ONE']}}"
                                        matTooltipPosition="above">
                                        {{placeHolders['LBL_AND_OR']}}</div>
                                    <div class="col-4 form-group">
                                        <label class="asterix" for="CompanyName">{{placeHolders['LBL_STORE']}}</label>
                                        <svg style="margin-left: 4px;" matTooltip="{{storeInfo}}"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <textarea class="form-control" rows="2" formControlName="location"
                                            placeholder="{{placeHolders['LBL_ENTER_ADDRESS']}}"
                                            [ngClass]="{ 'is-invalid':  f['location'].errors  && (f['location'].dirty || f['location'].touched ) }"
                                            required></textarea>
                                    </div> -->
                                    <div *ngIf="showError" style="color: red; margin-top: 10px;">
                                        Please select at least one checkbox and fill the corresponding details.
                                    </div>
                                </div>

                                <!-- Old Tags Part Commented -->

                                <!-- <div class="col-12 row pt-4">
                                <div class="col-6 form-group">
                                    <label class="asterix" for="CompanyName">Add tags/keywords</label>
                                    <ng-multiselect-dropdown [settings]="tagsDropdownSettings"
                                        [placeholder]="'Select tags/keywords'" [data]="allTagsKewords" bindLabel="tag"
                                        bindValue="tag" formControlName="tag"
                                        [ngClass]="{ 'is-invalid': f['tag'].errors  && (f['tag'].dirty || f['tag'].touched ) }"
                                        required>
                                    </ng-multiselect-dropdown>
                                    @if ( f['tag'].errors) {
                                    <div class="invalid-feedback">
                                        @if (f['tag'].errors['required']) {
                                        <div>Tags/Keywords is required</div>
                                        }
                                    </div>
                                    }
                                </div>

                            </div> -->

                                <!-- Adding Keywords -->
                                <div class="col-12 row pt-3">
                                    <div class="col-11 form-group mt--20">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_ADD_TAGS']}}</label>
                                        <svg style="margin-left: 4px;" matTooltip="{{tagsInfo}}"
                                            matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg" width="1.2em"
                                            height="1.2em" viewBox="0 0 24 24">
                                            <g fill="none">
                                                <path
                                                    d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#497604"
                                                    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                            </g>
                                        </svg>
                                        <mat-form-field class="example-chip-list form-control col-12">
                                            <mat-chip-grid #chipGrid aria-label="Add tags/keywords">
                                                @for (item of tagsKeywords; track item) {
                                                <mat-chip-row (removed)="remove(item)">
                                                    {{item}}
                                                    <button matChipRemove [attr.aria-label]="'remove ' + item">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                </mat-chip-row>
                                                }
                                            </mat-chip-grid>
                                            <input placeholder="{{placeHolders['LBL_PH_ADD_NEW_TAGS']}}" #tagInput
                                                formControlName="tagCtrl" [matChipInputFor]="chipGrid"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event)" />
                                            <!-- [matAutocomplete]="auto" -->
                                            <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                          @for (item of filteredTags | async; track item) {
                                            <mat-option [value]="item">{{item}}</mat-option>
                                          }
                                        </mat-autocomplete> -->
                                        </mat-form-field>
                                        <div *ngIf="f['tagCtrl'].errors && (f['tagCtrl'].dirty || f['tagCtrl'].touched)"
                                            class="invaliderror">
                                            <div *ngIf="f['tagCtrl'].hasError('tagsRequired')">
                                                {{placeHolders['MSG_ERR_TAGS_REQUIRED']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 row pt-3">
                                    <!-- <img style="height: 15vh;display:block;
                                width: 15vw;
                                padding-left: 20px;
                                padding-top: 10px;" [src]="brandimage" > -->
                                    <ng-container *ngIf="brandimage">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_UPLOADED_IMG']}}</label>

                                        <br>
                                        <img *ngIf="brandimage" [src]="brandimage" alt="Preview"
                                            style="width: 300px; height: 300px;">
                                        <br>
                                    </ng-container>
                                    <ng-container *ngIf="!brandimage || this.v['status'].value === 'D' || createFlash">
                                        <div class="col-3 form-group" *ngIf="fileName===null">
                                            <label class="asterix"
                                                for="CompanyName">{{placeHolders['LBL_UPLOAD_IMG']}}</label>
                                            <svg style="margin-left: 4px;"
                                                matTooltip="Please upload images with a resolution of at least 150x200 for clear and optimal display"
                                                matTooltipPosition="above" matTooltipClass="custom-tooltip"
                                                xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em"
                                                viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>

                                            <input class="form-control" type="file" (change)="uploadDoc($event)"
                                                formControlName="image" title="Upload"
                                                [ngClass]="{ 'is-invalid':  f['image'].errors  && (f['image'].dirty || f['image'].touched )}"
                                                required />
                                            @if ( f['image'].errors) {
                                            <div class="invalid-feedback">
                                                @if (f['image'].errors['required']) {
                                                <div>Image is required</div>
                                                }
                                            </div>
                                            }
                                            <small class="form-text text-muted" style="font-size: 14px;">
                                                <ul style="margin-left: -25px;">
                                                    <li style="width: 160%;">{{placeHolders['LBL_JPG_FORMAT_RULE_2MB']}}
                                                    </li>
                                                    <li style="width: 160%;">Maintain aspect ratio of 4:3
                                                    </li>
                                                </ul>
                                            </small>
                                        </div>


                                        <div class="col-3 form-group" *ngIf="fileName!==null">
                                            <label class="asterix" for="UploadImage">{{placeHolders['LBL_UPLOAD_IMG']}}
                                            </label>

                                            <div class="form-control file-name-container " matTooltip="{{fileName}}"
                                                matTooltipPosition="above" style="width: 240px;" type="text" disabled>
                                                {{fileName}}
                                            </div>
                                            <img *ngIf="fileName!==null" src="../../../assets/images/delete-icon.png"
                                                style="width: 12px;
                                                       margin-left: 215px;
                                                       margin-top: -60px;cursor: pointer;" (click)="removeImage()" />
                                            <!-- <span *ngIf="imagePreview !== null && !isFileUploaded"
                                                class="form-text text-muted"
                                                style="font-size: 14px; color: red !important;margin-left: 10px;">
                                                Image selected. please click upload
                                            </span> -->
                                            <small class="form-text text-muted" style="display: block;
                                            font-size: 14px;
                                            margin-top: -20px;">
                                                <ul style="margin-left: -25px;">
                                                    <li style="width: 160%;">{{placeHolders['LBL_JPG_FORMAT_RULE_2MB']}}
                                                    </li>
                                                    <li style="width: 160%;">Maintain aspect ratio of 4:3
                                                    </li>
                                                </ul>
                                            </small>
                                        </div>
                                        <div class="col-1 form-group" *ngIf="!isFileUploaded">
                                            <button class="btn div-37" type="button" (click)="imageUpload()" [ngStyle]="{
                                                'background-color': imagePreview !== null && !isFileUploaded ? '#87cb38' : 'gray',
                                                'color': 'black',
                                                'cursor': f['image'].valid ? 'pointer' : 'not-allowed'
                                              }" style="cursor: pointer; margin-top: 27px;margin-left: -10px;"
                                                [disabled]="!f['image'].valid">{{ imagePreview !== null &&
                                                !isFileUploaded ? 'Upload' : placeHolders['LBL_BTN_UPLOAD'] }}</button>
                                        </div>
                                        <div class="col-1 form-group" *ngIf="isFileUploaded">
                                            <img src="../../../assets/images/tickmark-icon.png"
                                                style="width:22px; margin-top: 27px;margin-left: -10px;" />
                                        </div>
                                        <div class="col-3 form-group" *ngIf="imagePreview">
                                            <!-- <label  for="preview">Preview</label> -->
                                            <img [src]="imagePreview" alt="Image Preview"
                                                style="width: 300px; height: 300px;" />
                                        </div>
                                        <div *ngIf="showImage" class="col-3 form-group image-container">
                                            <img matTooltip="Maintain a resolution of at least 150x200 and a 4:3 ratio to ensure clear and professional visuals on our platform!"
                                                style="width: 300px; height: 300px;"
                                                src="../../../assets/images/imageplaceholders.png" alt="Sample Image"
                                                class="" />
                                        </div>
                                    </ng-container>

                                </div>
                                <div class="row col-12 pt-2">
                                    <div class="text-right">
                                        <button type="submit" class="btn div-63"
                                            style="cursor: pointer;float: right;">{{placeHolders['BTN_NEXT']}}</button>
                                        <button
                                            *ngIf="this.v['status'].value !== 'C' && this.v['status'].value !== 'E' "
                                            type="button" class="btn div-63"
                                            style="cursor: pointer;float: right;margin-right: 5px;"
                                            (click)="saveCampaignDraft()"
                                            [disabled]="!isFormValid">{{btnLabel}}</button>
                                        <!-- <button type="button" (click)="openImagePreview()" class="btn btn-secondary"
                                        style="background-color: #36849d80 ; float: right;margin-right: 5px;">View
                                        sample</button> -->
                                    </div>
                                </div>
                                <!-- <mat-form-field>
                                <input matInput placeholder="Field 1" name="field1" required [(ngModel)]="formData.field1">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput placeholder="Field 2" name="field2" required [(ngModel)]="formData.field2">
                            </mat-form-field> -->
                                <!-- <button mat-button color="primary" type="submit">Next</button> -->
                            </form>
                        </div>
                        <!-- Target Details Step 2-->
                        <div *ngIf="activeStep === 2">
                            <form #formStep2="ngForm" [formGroup]="targetForm" (ngSubmit)="nextStep2(targetForm)">
                                <div class="col-12 row">
                                    <div class="col-4 form-group">
                                        <h5 for="Targetcountry">{{placeHolders['LBL_TAB_TARGET_AUDIENCE']}} :
                                            {{country}}
                                        </h5>
                                    </div>
                                </div>
                                <!-- <div class="col-12 row">
                                <div class="col-4 form-group pt-3">
                                    <input type="text" [readonly]="true" formControlName="country"
                                        class="form-control" />
                                </div>
                            </div> -->
                                <div class="col-12 row">
                                    <div class="col-6 form-group pt-3">
                                        <mat-radio-group aria-label="Select an option" formControlName="select">
                                            <mat-radio-button class="div-53" (change)="selectClick()"
                                                value="EC">{{placeHolders['LBL_ENTIRE_COUNTRY']}}</mat-radio-button>
                                            <mat-radio-button *ngIf="countryInitials !== 'B'" class="div-53 mx-2"
                                                (change)="selectClickCity()"
                                                value="AC">{{placeHolders['LBL_TAB_STATE']}}</mat-radio-button>
                                            <mat-radio-button *ngIf="countryInitials === 'B'" class="div-53 mx-2"
                                                (change)="selectClickCity()"
                                                value="AC">State/District</mat-radio-button>
                                            <!-- <mat-radio-button class="div-55 mx-2" (change)="customClick()"
                                            value="C">Custom</mat-radio-button> -->
                                        </mat-radio-group>

                                    </div>
                                </div>
                                <div class="col-12 row" *ngIf="targetCity!==false">
                                    <div class="col-3 form-group">
                                        <div class="custom-select">
                                            <label class="asterix" *ngIf="countryInitials !== 'B'"
                                                for="countrySelect">{{placeHolders['LBL_TAB_STATE']}}</label>
                                            <label class="asterix" *ngIf="countryInitials === 'B'"
                                                for="countrySelect">State/District</label>
                                            <select class="form-control" formControlName="state" id="stateSelect"
                                                (change)="stateSelectionForCity($event)">
                                                <option *ngFor="let option of stateData" [value]="option.state_id">
                                                    {{option.state_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3 form-group ">
                                        <div class="custom-select">
                                            <label class="asterix" for="countrySelect">City</label>
                                            <select class="form-control" formControlName="city" id="citySelect">
                                                <option *ngFor="let option of cityData" [value]="option.city_name">{{
                                                    option.city_name
                                                    }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 row" *ngIf="targetAudience!==false">
                                    <div class="col-2 form-group pt-3" style="margin-right: -40px;">
                                        <label class="asterix" for="CompanyName">{{placeHolders['LBL_GENDER']}}</label>
                                        <mat-form-field class="col-12">
                                            <mat-select formControlName="gender" class="form-control" required>
                                                @for (gen of gender; track gen) {
                                                <mat-option [value]="gen.id">{{gen.name}}
                                                </mat-option>
                                                }
                                            </mat-select>
                                            <mat-error style="margin-top: -13px;width: 150px;
                                        margin-left: -13px;"
                                                *ngIf="{ 'is-invalid': t['gender'].errors && (t['gender'].dirty || t['gender'].touched )}">
                                                {{placeHolders['MSG_ERR_GENDER_REQUIRED']}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-2 form-group pt-3" style="margin-right: -40px;">
                                    <label style="margin-left: 16px;" class="asterix" for="CompanyName">Lower
                                        Age</label>
                                    <mat-form-field class="col-12">
                                        <mat-select (selectionChange)="updateValidators('lowerAge')"
                                            formControlName="lowerAge" class="form-control" required>
                                            @for (age of lowerAgeGroup; track age) {
                                            <mat-option [value]="age.id">{{age.lower_age}}
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->
                                    <!-- <div class="col-2 form-group pt-3">
                                    <label style="margin-left: 16px;" class="asterix" for="CompanyName">Upper
                                        Age</label>
                                    <mat-form-field class="col-12">
                                        <mat-select (selectionChange)="updateValidators('upperAge')"
                                            formControlName="upperAge" class="form-control" required>
                                            @for (age of upperAgeGroup; track age) {
                                            <mat-option [value]="age.id">{{age.upper_age}}
                                            </mat-option>
                                            }
                                        </mat-select>
                                        <mat-error style="margin-top: -13px;width:13rem;"
                                            *ngIf="{ 'is-invalid':  t['upperAge'].errors && (t['upperAge'].dirty || t['upperAge'].touched )}">
                                            Please enter valid age range
                                        </mat-error>

                                    </mat-form-field>
                                </div> -->
                                    <div class="col-4 form-group pt-3">
                                        <label style="margin-left: 16px;" class="asterix"
                                            for="age">{{placeHolders['LBL_AGE_RANGE']}}</label>
                                        <mat-form-field style="margin-left: 25px;">
                                            <mat-select class="form-control" [(value)]="selectedAgeRanges"
                                                (selectionChange)="onSelectionChange($event)" multiple>
                                                <mat-option *ngFor="let range of ageRanges" [value]="range">
                                                    {{ range.label }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row col-12 pt-3">
                                    <div class="text-right">
                                        <button type="submit" class="btn div-63"
                                            style="cursor: pointer;float: right;">{{placeHolders['BTN_NEXT']}}</button>
                                        <button type="button" (click)="onPrevClick(1)" class="btn div-63"
                                            style="cursor: pointer;float: right;margin-right: 5px">{{placeHolders['BTN_PREV']}}</button>
                                        <button
                                            *ngIf="this.v['status'].value !== 'C' && this.v['status'].value !== 'E' "
                                            type="button" class="btn div-63"
                                            style="cursor: pointer;float: right;margin-right: 5px;"
                                            (click)="saveCampaignDraft()">{{btnLabel}}</button>
                                        <!-- <button *ngIf="!editResponse" type="button" (click)="reset()"
                                        class="btn btn-secondary" style="float: right;margin-right: 5px;">Reset</button> -->

                                    </div>
                                </div>
                                <!-- <mat-form-field>
                                <input matInput placeholder="Field 3" name="field3" required [(ngModel)]="formData.field3">
                            </mat-form-field> -->
                                <!-- <button mat-button color="primary" type="submit">Next</button> -->
                            </form>
                        </div>
                        <!-- Budget Details Step 3-->
                        <div *ngIf="activeStep === 3">
                            <form [formGroup]="BudgetDetailForm" (ngSubmit)="nextStep3(BudgetDetailForm)">
                                <div div class="col-12 row">
                                    <div class="col-4 form-group">
                                        <h5 for="Targetcountry">Pricing Details</h5>
                                        <!-- {{placeHolders['LBL_TAB_BUDGET_DETAILS']}} -->
                                    </div>
                                </div>
                                <div div class="col-12 row">
                                    <div class="col-sm-6 col-xl-3">
                                        <div class="p-2 text-center"><i
                                                class="mdi mdi-24px mdi-cart-plus text-primary"></i><!---->
                                            <p class="font-15 text-tile mb-0 text-muted">CPC <svg
                                                    style="margin-left: 4px;" matTooltip="{{toolTips['TIP_CPC']}}"
                                                    matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="#497604"
                                                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                    </g>
                                                </svg></p>
                                            <p class="font-15 text-tile mb-0 text-muted">({{placeHolders['LBL_CPC']}})
                                            </p>
                                            <b class="mb-0 mt-0 eventpricing ">
                                                <span>{{currency}}{{this.clickCost?.cost}}</span>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-3">
                                        <div class="p-2 text-center"><i
                                                class="mdi mdi-24px mdi-cart-plus text-primary"></i><!---->
                                            <p class="font-15 text-tile mb-0 text-muted">CPF <svg
                                                    style="margin-left: 4px;" matTooltip="{{toolTips['TIP_CPF']}}"
                                                    matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="#497604"
                                                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                    </g>
                                                </svg> </p>
                                            <p class="font-15 text-tile mb-0 text-muted">({{placeHolders['LBL_CPF']}})
                                            </p>
                                            <b class="mb-0 mt-0 eventpricing ">
                                                <span>{{currency}}{{this.favCost?.cost}}</span>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-3" style="width: 21%;">
                                        <div class="p-2 text-center"><i class="mdi mdi-24px text-primary"></i><!---->
                                            <p class="font-15 text-tile mb-0 text-muted">CPCV
                                                <svg style="margin-left: 4px;" matTooltip="{{toolTips['TIP_CPCV']}}"
                                                    matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="#497604"
                                                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                    </g>
                                                </svg>
                                            </p>
                                            <p class="font-15 text-tile mb-0 text-muted">({{placeHolders['LBL_CPCV']}})
                                            </p>
                                            <b class="mb-0 mt-0 eventpricing  ">
                                                <span>{{currency}}{{this.claimCost?.cost}}</span>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-xl-3" style="width: 17%;">
                                        <div class="p-2 text-center"><i
                                                class="mdi mdi-24px mdi-cart-plus text-primary"></i><!---->

                                            <p class="font-15 text-tile mb-0 text-muted">CPR
                                                <svg style="margin-left: 4px;" matTooltip="{{toolTips['TIP_CPR']}}"
                                                    matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="#497604"
                                                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                    </g>
                                                </svg>

                                            </p>
                                            <p class="font-15 text-tile mb-0 text-muted">({{placeHolders['LBL_CPR']}})
                                            </p>
                                            <b class="mb-0 mt-0 eventpricing  ">
                                                <span>{{currency}}{{this.clickCost?.cost}}</span>
                                            </b>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6 col-xl-2">
                                        <div class="p-2 text-center"><i
                                                class="mdi mdi-24px mdi-cart-plus text-primary"></i>
                                            <p class=" text-tile font-15 mb-0 text-muted">SF
                                                <svg style="margin-left: 4px;" matTooltip="{{toolTips['TIP_SF']}}"
                                                    matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                    width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                    <g fill="none">
                                                        <path
                                                            d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="#497604"
                                                            d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                    </g>
                                                </svg>
                                            </p>
                                            <p class="font-15 mb-0 text-tile text-muted">({{placeHolders['LBL_SF']}})
                                            </p>
                                            <b class="mb-0 mt-0 eventpricing ">
                                                <span>{{currency}}{{this.successCost?.cost}}</span>
                                            </b>
                                        </div>
                                    </div> -->






                                    <!-- <div class="col-3 form-group">
                                    <label class="asterix" for="CompanyName">CPC (Cost per Click):</label>
                                    <div class="input-group">
                                        <span class="currency-symbol">{{this.clickCost?.currency}}</span>
                                        <input type="text"
                                            style="background-color: #9da5b145;border:none;border-radius: 6px;"
                                            [readonly]="true" formControlName="cpc" class="form-control" />
                                    </div>
                                </div> -->
                                    <!-- <div class="col-3 form-group">
                                    <label class="asterix" for="CompanyName">CPF (Cost per Favourite)</label>
                                    <div class="input-group">
                                        <span class="currency-symbol">{{this.clickCost?.currency}}</span>
                                        <input type="text"
                                            style="background-color: #9da5b145;border:none;border-radius: 6px;"
                                            [readonly]="true" formControlName="cpf" class="form-control" />
                                    </div>
                                </div> -->
                                    <!-- <div class="col-3 form-group">
                                    <label class="asterix" for="CompanyName"> CPCV(Cost per Code View)</label>
                                    <div class="input-group">
                                        <span class="currency-symbol">{{this.clickCost?.currency}}</span>
                                        <input type="text" value="0.5"
                                            style="background-color: #9da5b145;border:none;border-radius: 6px;"
                                            [readonly]="true" class="form-control" />
                                    </div>
                                </div> -->

                                </div>
                                <!-- <div div class="col-12 row mt-3">
                                    <div class="col-2 form-group">
                                        <label class="asterix"
                                            for="CompanyName">{{placeHolders['LBL_ENTER_BUDGET']}}</label>
                                        <div class="input-group">
                                            <span class="currency-symbol">{{this.clickCost?.currency}}</span>
                                            <input type="number" pattern="^[^\s]+$" class="form-control"
                                                style="border:none;border-radius: 6px;" min="10" max="100000"
                                                formControlName="budget"
                                                [ngClass]="{ 'is-invalid':  b['budget'].errors  && (b['budget'].dirty || b['budget'].touched ) }"
                                                required />
                                            @if ( b['budget'].errors) {
                                            <div class="invalid-feedback">
                                                @if (b['budget'].errors['required']) {
                                                <div>{{placeHolders['MSG_ERR_BUDGET_REQUIRED']}}</div>
                                                }
                                            </div>
                                            }
                                        </div>
                                      

                                    </div>
                                </div> -->

                                <div class="row col-12 pt-3">

                                    <div class="text-right">

                                        <button type="submit" class="btn div-63"
                                            style="cursor: pointer;float: right;">{{placeHolders['BTN_NEXT']}}</button>
                                        <button type="button" (click)="onPrevClick(2)" class="btn div-63"
                                            style="cursor: pointer;float: right;margin-right: 5px">{{placeHolders['BTN_PREV']}}</button>
                                        <button
                                            *ngIf="this.v['status'].value !== 'C' && this.v['status'].value !== 'E' "
                                            type="button" class="btn div-63"
                                            style="cursor: pointer;float: right;margin-right: 5px;"
                                            (click)="saveCampaignDraft()">{{btnLabel}}</button>
                                        <!-- <button *ngIf="!editResponse" type="button" (click)="reset()"
                                        class="btn btn-secondary" style="float: right;margin-right: 5px;">Reset</button> -->

                                    </div>
                                    <small style="margin-top: -25px;width: 25rem;">
                                        Note: We don't charge for impressions
                                    </small>
                                </div>
                            </form>
                        </div>
                        <!-- Validity Details Step 4-->
                        <div *ngIf="activeStep === 4">

                            <div>
                                <form [formGroup]="validityStatusForm" (ngSubmit)="nextStep(validityStatusForm)">
                                    <div div class="col-12 row">
                                        <div class="col-4 form-group">
                                            <h5 for="Targetcountry">{{placeHolders['LBL_TAB_DEAL_VALIDITY']}}</h5>
                                            <!-- {{placeHolders['LBL_TAB_DEAL_VALIDITY']}} -->
                                        </div>
                                    </div>

                                    <div *ngIf="showSection1" div class="col-12 row">
                                        <div class="col-3 form-group">
                                            <label class="asterix"
                                                for="CompanyName">{{placeHolders['LBL_FROM']}}</label>
                                            <svg style="margin-left: 4px;"
                                                matTooltip="Select the date from which the deal will be active and visible to users."
                                                matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>
                                            <Input type="date" class="form-control" formControlName="from"
                                                min="{{ presentDate }}" onkeydown="return false"
                                                placeholder="Select Date" style="border: 0px solid transparent;"
                                                class="form-control" required ngDefaultControl />
                                            <div style="color: red;
                                        font-size: small;"
                                                *ngIf="this.validityStatusForm.get('from')?.errors?.['dateRangeError']">
                                                {{placeHolders['MSG_ERR_FROMDATE_LESS_TODATE']}}</div>
                                        </div>
                                        <div class="col-3 form-group">
                                            <label class="asterix" for="CompanyName">{{placeHolders['LBL_TO']}}</label>
                                            <svg style="margin-left: 4px;"
                                                matTooltip="Select the date on which this deal will expire."
                                                matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>
                                            <Input type="date" class="form-control" formControlName="to"
                                                min="{{ afterPresentDate }}" onkeydown="return false"
                                                placeholder="Select Date" style="border: 0px solid transparent;"
                                                class="form-control" required ngDefaultControl />
                                            <div style="color: red;
                                        font-size: small;"
                                                *ngIf="this.validityStatusForm.get('to')?.errors?.['dateRangeError']">
                                                {{placeHolders['MSG_ERR_TODATE_GREATER_FROMDATE']}}</div>
                                        </div>
                                    </div>

                                    <div *ngIf="showSection2" div class="col-12 row">
                                        <div class="col-3 form-group">
                                            <label class="asterix" for="CompanyName">{{placeHolders['LBL_DAY']}}</label>
                                            <Input type="date" class="form-control" formControlName="flashdate"
                                                min="{{ presentDate }}" onkeydown="return false"
                                                placeholder="Select Date" style="border: 0px solid transparent;"
                                                class="form-control" required ngDefaultControl />
                                        </div>
                                        <div class="col-3 form-group">
                                            <div class="custom-select">
                                                <label class="asterix"
                                                    for="CompanyName">{{placeHolders['LBL_NO_HOURS']}}</label>
                                                <select class="form-control" formControlName="selectedHour">
                                                    <option *ngFor="let hour of hoursOptions" [value]="hour">{{ hour }}
                                                        hours
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-2 form-group">
                                            <label class="asterix"
                                                for="CompanyName">{{placeHolders['LBL_START_TIME']}}</label>
                                            <Input type="time" class="form-control" formControlName="from"
                                                (change)="SetendTime()" min="{{ presentDate }}" onkeydown="return false"
                                                placeholder="Select Date" style="border: 0px solid transparent;"
                                                class="form-control" required ngDefaultControl />
                                        </div>
                                        <div class="col-2 form-group">
                                            <label class="asterix"
                                                for="CompanyName">{{placeHolders['LBL_END_TIME']}}</label>
                                            <Input type="time" class="form-control" formControlName="to"
                                                min="{{ presentDate }}" onkeydown="return false"
                                                placeholder="Select Date" style="border: 0px solid transparent;"
                                                class="form-control" required ngDefaultControl />
                                        </div>
                                    </div>
                                    <div class="separator"></div> <!-- Dotted line separator -->
                                    <div div class="col-12 row mt-3">
                                        <div class="col-4 form-group">
                                            <h5 for="Targetcountry">{{placeHolders['LBL_TAB_BUDGET_DETAILS']}}</h5>
                                            <!-- {{placeHolders['LBL_TAB_DEAL_VALIDITY']}} -->
                                        </div>
                                    </div>

                                    <div div class="col-12 row">
                                        <div class="col-2 form-group">
                                            <label style="margin-left: 4px;" class="asterix"
                                                for="CompanyName">{{placeHolders['LBL_ENTER_BUDGET']}}</label>
                                            <svg style="margin-left: 4px;"
                                                matTooltip="Maximum spend limit for this deal."
                                                matTooltipPosition="above" xmlns="http://www.w3.org/2000/svg"
                                                width="1.2em" height="1.2em" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path
                                                        d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                    <path fill="#497604"
                                                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.01 6c.558 0 1.01.452 1.01 1.01v5.124A1 1 0 0 1 12.5 18h-.49A1.01 1.01 0 0 1 11 16.99V12a1 1 0 1 1 0-2zM12 7a1 1 0 1 1 0 2a1 1 0 0 1 0-2" />
                                                </g>
                                            </svg>
                                            <div class="input-group">
                                                <span class="currency-symbol">{{currency}}</span>
                                                <input type="number" pattern="^[^\s]+$" class="form-control"
                                                    placeholder=">=5" style="border:none;border-radius: 6px;" min="5"
                                                    max="100" formControlName="budget" required />
                                                @if ( v['budget'].errors) {
                                                <div class="invalid-feedback">
                                                    @if (v['budget'].errors['required']) {
                                                    <div>{{placeHolders['MSG_ERR_BUDGET_REQUIRED']}}</div>
                                                    }
                                                </div>
                                                }

                                            </div>

                                            <!-- <span>{{this.clickCost?.currency}}</span> -->

                                        </div>

                                        <!-- <div class="col-2" *ngIf="isCharge">
                                            <button *ngIf="isProd" style="width: 15rem; 
                                            height: 36px;
                                            margin-top: 24px;" [disabled]=isValidPay (click)="initiatePaymentProcess()"
                                                type="button" class="btn btn-primary btn-block">{{payBtnLabel}}</button>
                                        </div> -->
                                        <span style="margin-left: -22px;
                                        margin-top: 5px;
                                        font-size: 14px;">
                                            <ul>
                                                <li>A minimum budget of $5 is required to get started.</li>
                                                <li>The maximum budget allowed is $100</li>
                                                <li>Charges will only be applied based on the actual spent</li>
                                            </ul>
                                        </span>

                                    </div>
                                    <div div class="col-12 row pt-2">
                                        <!-- <small class="form-text text-muted" style="font-size: 14px;"
                                        *ngIf="this.v['status'].value === 'D'">
                                        <ul style="margin-left: -25px;">
                                            <li>Deal is in draft state please send for approval, once it is
                                                approved it will be visible to live. </li>
                                        </ul>
                                    </small> -->
                                        <!-- <small
                                            *ngIf="this.v['status'].value === 'D' || this.v['status'].value === 'P' ">
                                            {{amountNote}}
                                        </small> -->
                                        <small class="form-text text-muted" style="font-size: 14px;"
                                            *ngIf="this.v['status'].value === 'U'">
                                            {{placeHolders['LBL_DEAL_IS_UPCOMING_MODE']}}
                                        </small>
                                    </div>
                                    <ng-container *ngIf="!excludedStatuses.includes(this.v['status'].value)">
                                        <div div class="col-12 row pt-3" *ngIf="isStatusAllowed">
                                            <label class="asterix" for="CompanyName">Deal status</label>
                                            <!-- <mat-radio-group aria-label="Select an option" formControlName="status" required
                                        *ngIf="this.validityStatusForm.get('status')?.value !== 'A'">
                                        <mat-radio-button value="A" (change)="selectLiveAction('A')">Live</mat-radio-button>
                                    </mat-radio-group> -->
                                            <mat-radio-group aria-label="Select an option" formControlName="status"
                                                required>
                                                <!-- <mat-radio-button value="D" *ngIf="isDraftRadiobtnVisible"
                                                (change)="selectLiveAction('D')">Draft</mat-radio-button>-->
                                                <mat-radio-button value="A" *ngIf="isPauseRadiobtnVisible"
                                                    (change)="selectLiveAction('A')">Live</mat-radio-button>
                                                <mat-radio-button value="S" (change)="selectLiveAction('S')"
                                                    *ngIf="isPauseRadiobtnVisible">Paused</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </ng-container>
                                    <div class="row col-12 pt-4">
                                        <div class="col-4">
                                            <!-- <button type="button" (click)="cancel()" class="btn btn-secondary"
                                            style="float: left;margin-right: 5px;">Cancel</button> -->
                                        </div>
                                        <div class="text-right col-8">

                                            <div *ngIf="isCharge"> <button
                                                    *ngIf="this.v['status'].value !== 'E' && this.v['status'].value !== 'A' && this.v['status'].value !== 'S' && this.v['status'].value !== 'C' && userData.status !== 'IA' && userData.status !== 'P' && userData.stripeCustomerId "
                                                    [disabled]="!isValidityFormValid" type="submit" class="btn div-63"
                                                    style="cursor: pointer;float: right;">Submit For Review</button>
                                            </div>
                                            <div *ngIf="!isCharge"><button [disabled]="!isValidityFormValid"
                                                    *ngIf="this.v['status'].value !== 'E'  && this.v['status'].value !== 'A' && this.v['status'].value !== 'S' && this.v['status'].value !== 'C' && userData.status !== 'IA' && userData.status !== 'P'"
                                                    type="submit" class="btn div-63"
                                                    style="cursor: pointer;float: right;">Submit For Review</button>
                                            </div>
                                            <!-- {{placeHolders['BTN_SEND_FOR_APPROVAL']}} -->
                                            <button
                                                *ngIf="this.v['status'].value !== 'C' && this.v['status'].value !== 'E' "
                                                type="button" [disabled]="!isValidityFormValid" (click)="saveDeal()"
                                                class="btn div-63"
                                                style="cursor: pointer;float: right;margin-right: 5px;">
                                                {{placeHolders['BTN_SAVE']}}</button>
                                            <button type="button" (click)="onPrevClick(3)" class="btn div-63"
                                                style="cursor: pointer;float: right;margin-right: 5px">{{placeHolders['BTN_PREV']}}</button>
                                            <!-- <button *ngIf="isProd" style="float: left;margin-left: -330px;"
                                                (click)="openPaymentDialog()" type="button"
                                                class="btn btn-primary btn-block">Authorize Payment</button>  -->
                                            <!-- {{placeHolders['BTN_PAY']}} -->

                                            <!-- <button *ngIf="!editResponse" type="reset" (click)="reset()"
                                            class="btn btn-secondary"
                                            style="float: right;margin-right: 5px;">Reset</button> -->

                                        </div>
                                    </div>
                                </form>
                                <div class="pt-3">

                                    <div *ngIf="userData.status === 'IA' && isCharge" class="error-container">
                                        <div class="error-icon">⚠️</div>
                                        <div class="error-text">
                                            <strong>🔴 Action Required: Payment Method Failed</strong><br>
                                            Your payment method could not be processed. Please update your payment
                                            details to activate your account and display your deals to customers.
                                        </div>
                                    </div>

                                    <div *ngIf="!userData.stripeCustomerId && isCharge && userData.status === 'A'" class="error-container">
                                        <div class="error-icon">❌</div>
                                        <div class="error-text">
                                            <strong>🔴 Action Required: Payment Method Setup</strong><br>
                                            Your payment method not found. Please update your payment
                                            details to activate your account and display your deals to customers.
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                        <!-- Add similar forms for Steps -->
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<!-- <mat-card *ngIf="paymentInitiate" style="padding: 10px;">
    <div class="col-12 row">

        <div class="col-1">
            <button (click)="back()" class="btn btn-primary btn-block">Back</button>
        </div>
        <div class="col-5 right-border p-3">
            <h3 style="justify-content: center;">Summary</h3><br>
            <span class="pt-3"><b>Deal Title</b>: {{getFormattedTitle()}}</span><br>
            <span><b>Start: </b> {{startDate}}</span> | <span><b>End:</b> {{endDate}}</span>

            <h5 class="pt-3">Budget : {{billAmount}}</h5>
            <div className=" p-4 space-y-2" style="background: #e3e3e3;border-radius: 6px;">
             
                <p style="margin-left: 10px;" className="text-sm text-muted-foreground">2.9% + $0.30 for online credit
                    card transactions in the
                    U.S.</p>
            </div>
            <div class="pt-3" style="background: #b1d488;
            border-radius: 10px;
            width: fit-content;
            padding-right: 10px;">
                <h4 style="margin-top: -10px;margin-left: 10px;">Total Cost : {{totalCost}}</h4>
            </div>



            <div class="pt-3">
                <img *ngIf="imagePreview; else dealImage" [src]="imagePreview" alt="Image"
                    style="max-width: 300px; max-height: 300px;" />

               
                <ng-template #dealImage>
                    <img [src]="brandimage" alt="Image" style="max-width: 300px; max-height: 300px;" />

                </ng-template>
            </div>
        </div>

        <div class="col-6 p-3">
            <app-paymentcheckout [amount]="billAmount" [currency]="billCurrency" [description]="billDescription"
                [brandId]="brandId" [dealId]="dealId" [postOfficeId]="postOfficeId" [deal_name]="deal_name" 
                [type]="type" [deal_uuid]="deal_uuid" [deal_budget]="deal_budget">
            </app-paymentcheckout>
        </div>
    </div>
    <span class="notePay">Note: This amount will be on hold and will be charged later</span>
</mat-card> -->