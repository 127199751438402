import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TermsAndConditionsComponent } from '../register/terms-and-conditions/terms-and-conditions.component';
import { botswanaConfig } from 'src/config/botswana-config';
import { defaultConfig } from 'src/config/default-config';

@Component({
  selector: 'app-landingscreen',
  templateUrl: './landingscreen.component.html',
  styleUrl: './landingscreen.component.scss'
})
export class LandingscreenComponent implements OnInit {
  constructor(private renderer: Renderer2, private router: Router, public dialog: MatDialog,
    @Inject('COUNTRY_CONFIG') private countryConfig: any) {
    this.countryConfig = defaultConfig
  }

  ngOnInit(): void {
    if (history.state) {
      history.replaceState({ countryConfig: defaultConfig }, '');
    }
    localStorage.clear();

  }

  navigateToLogin(event: Event, param?: any) {

    event.preventDefault(); // Prevent default anchor behavior

    let config = null
    if (param === 'B') {
      config = botswanaConfig
      const link: HTMLLinkElement =
        document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'icon';
      link.href = config.favicon

      // Remove old favicon and append the new one
      document.head.appendChild(link);

    } else {
      config = defaultConfig
      const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = config.favicon

    // Remove old favicon and append the new one
    document.head.appendChild(link);
    }
    this.router.navigate(['/login'], { state: { countryConfig: config } });
  }

  termsAndCondition() {
    this.dialog.open(TermsAndConditionsComponent, {
      height: '500px',
      width: '600px',
      position: {
        top: '5vh',
        // left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
  }

  navigateFaqs() {
    const url = `#${this.router.serializeUrl(this.router.createUrlTree(['/faqs']))}`;
    window.open(url, '_blank');
  }

  navigateTerms() {
    const url = `#${this.router.serializeUrl(this.router.createUrlTree(['/termsconditions']))}`;
    window.open(url, '_blank');
  }


}
