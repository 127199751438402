import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { navItems } from './_nav';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { INavData } from '@coreui/angular';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { Router } from '@angular/router';
import { botswanaConfig } from 'src/config/botswana-config';
import { zimConfig } from 'src/config/zim-config';
import { defaultConfig } from 'src/config/default-config';
import { coteivoryConfig } from '../../../config/Cote-ivory-config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  placeHolders: any;
  loginPlaceHolders: any;
  placeholders = environment.placeholders as { [key: string]: string };
  public navItems: INavData[] | undefined;
  brandimage: any = null;
  fullName: any;
  roleType: any;
  merchantName: any;
  logoUrl: any;

  constructor(private dialog: MatDialog, private service: CommonUtilService, private cStorage: ClientSideStorageService, private router: Router, @Inject('COUNTRY_CONFIG') private countryConfig: any) {

    const postValue = this.cStorage.get('postcountry') ?? history.state.countryConfig.country
    this.countryConfig = this.getConfigByCountry(postValue) ?? defaultConfig;
    this.logoUrl = this.countryConfig.logoUrl;
    this.placeHolders = this.placeholders['Side_Menu_bar']
    this.loginPlaceHolders = this.placeholders['login_page']
    this.service.getUserProfileDetails().subscribe((res: any) => {
      let data = res?.data;
      // let fullName: any;
      if (data?.lastName !== null) {
        this.fullName = data?.firstName.concat(" " + data?.lastName);
        this.cStorage.set('userFullName', JSON.stringify(this.fullName))
        this.updateFontSize()
      } else {
        this.fullName = data?.firstName;
        this.cStorage.set('userFullName', JSON.stringify(this.fullName))
      }
      let roleID = data?.roleId;

      this.service.getAllRoles().subscribe((response: any) => {
        let allRoles = response?.data?.roleList;
        // this.service.getRegisterCompanyDetails().subscribe((res: any) => {
        //   let marketplaceData;
        //   if (res?.data) {
        //     marketplaceData = res?.data;
        //     this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
        //   }
        // });

        let marketplaceData = JSON.parse(this.cStorage.get("marketplaceData"));
        this.merchantName = marketplaceData.marketplace_name
        if (marketplaceData !== null && marketplaceData !== undefined) {
          let image;
          if (marketplaceData?.marketplace_image) {
            image = environment.assetsUrl + '/assets/' + marketplaceData?.marketplace_image;
            this.brandimage = image;
          } else {
            image = null;
          }
        }
        let roleRes = allRoles.find((el: any) => el.roleId === roleID);
        this.roleType = roleRes?.roleType.toString();
        // if (marketplaceData && marketplaceData.categories===null && marketplaceData.marketplace_image ===null && marketplaceData.marketplace_type===null) {
        //   this.router.navigate(["/companyProfile"]);
        //  }else{
        //   this.router.navigate(["/dashboard"]);
        //  }
        // const brandlogo = new URLSearchParams(this.brandimage);
        // let brandlogo = JSON.parse(image);

        if (data.email_id !== 'mayurpatil.bvcoek@gmail.com') {
          this.navItems = [
            // {
            //   title: true,
            //   iconComponent: { name: this.brandimage},
            // },
            // {
            //   title: true,
            //   name: fullName,
            //   // iconComponent: { name: 'cil-user' },
            //   badge: {
            //     color: 'info',
            //     text: roleType
            //   }
            // },
            // {
            //   title: true,
            //   name: roleType,
            //   // badge: {
            //   //   color: 'info',
            //   //   text: roleType
            //   // }

            // },
            {
              name: this.placeHolders['LBL_MENU_DASHBOARD'],
              url: '/dashboard',
              iconComponent: { name: 'cil-speedometer' },
              // badge: {
              //   color: 'info',
              //   text: 'NEW'
              // }
            },
            // {
            //   name: 'Campaigns',
            //   url: '/add-campaign',
            //   iconComponent: { name: 'cil-volume-low' }
            // },
            {
              name: this.placeHolders['LBL_MENU_MERCHANT_PROFILE'],
              url: '/companyProfile',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-building' }
            },
            {
              name: this.placeHolders['LBL_MENU_USER_MGMT'],
              url: '/usermanagement',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-people' }
            },
            {
              name: this.placeHolders['LBL_MENU_PAYMENTS'],
              url: '/payments',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-dollar' }
            },
            // {
            //   name: 'Billing',
            //   url: '/billing',
            //   // linkProps: { fragment: 'someAnchor' },
            //   iconComponent: { name: 'cil-description' }
            // },
            {
              name: this.placeHolders['LBL_MENU_MESSAGING'],
              url: '/messaging',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-share' }
            },
            {
              name: this.placeHolders['LBL_MENU_REPORTS'],
              url: '/reportsdashboard',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-chart' }
            },
            {
              name: this.placeHolders['LBL_MENU_HELP'],
              url: '/help',
              // linkProps: {  fragment: 'someAnchor' },
              iconComponent: { name: 'cil-chat-bubble' }
            },
            {
              name: this.placeHolders['LBL_MENU_LOGOUT'],
              url: '/logout',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-account-logout' }
            }
            // {
            //   name: 'Pages',
            //   url: '/login',
            //   iconComponent: { name: 'cil-star' },
            //   children: [
            //     {
            //       name: 'Login',
            //       url: '/login'
            //     },
            //     {
            //       name: 'Register',
            //       url: '/register'
            //     },
            //     {
            //       name: 'Error 404',
            //       url: '/404'
            //     },
            //     {
            //       name: 'Error 500',
            //       url: '/500'
            //     }
            //   ]
            // },

          ];

        }
        else {
          //Approval Flow to be replaced with super user
          this.brandimage = null;
          this.navItems = [

            {
              name: 'Dashboard',
              url: '/approval',
              iconComponent: { name: 'cil-speedometer' },
              // badge: {
              //   color: 'info',
              //   text: 'NEW'
              // }
            },


            {
              name: 'Logout',
              url: '/logout',
              // linkProps: { fragment: 'someAnchor' },
              iconComponent: { name: 'cil-account-logout' }
            }

          ];

        }



      });
    });

    this.cStorage.updateFullName(this.fullName);
    this.dialog.closeAll();
  }

  ngOnInit(){
    this.cStorage.fullName$.subscribe(name => {
      this.fullName = name; // Update in real-time
    });

    // Load from session if available
    this.cStorage.loadFullNameFromSession();
  }
  fontSize: number = 16;
  updateFontSize(): void {
    const length = this.fullName.length;
    this.fontSize = Math.max(10, 32 - length); // Example logic to decrease font size as length increases
  }

  getConfigByCountry(country: string): any {
    switch (country) {
      case 'Botswana':
        return botswanaConfig;
      case 'Zimbabwe':
        return zimConfig;
      case 'Cote De Ivory':
        return coteivoryConfig;
      default:
        return defaultConfig;
    }
  }
}
