
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from '../../services/payment.service';
import { Router } from '@angular/router';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

declare var Stripe: any;

@Component({
  selector: 'app-paymentcheckout',
  templateUrl: './paymentcheckout.component.html',
  styleUrl: './paymentcheckout.component.scss'
})
export class PaymentcheckoutComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  @ViewChild('cardElement') cardElement!: ElementRef;
  @Input() amount!: number;
  @Input() currency: string = 'usd';
  @Input() description: string = 'Payment';
  @Input() brandId: any;
  @Input() dealId: any;
  @Input() postOfficeId: any;
  @Input() type: any;
  @Input() deal_name: any;
  @Input() deal_uuid: any;
  @Input() deal_budget: any;
  @Input() useremail!: string;
  @Input() brandname!: string;
  @Input() userFullName!:string;
  @Input() msgdata!:any;


  captureForm: FormGroup;
  @Output() showCheckoutChanged = new EventEmitter<boolean>();
  showCheckout: boolean = true;


  payUrl: any;
  paymentShow: boolean = false;
  stripe: any;
  card: any;
  paymentForm: FormGroup;
  checkoutForm: FormGroup;
  loading = false;
  errorMessage: string | null = null;
  paymentStatus: string | null = null;
  personID: any;
  paymentMethod: any;
  paymentIntetResponse: any;
  btnLabel: any;
  apiPlaceHolders: any;
  paymsg:any;

  constructor(private fb: FormBuilder, private router: Router, private notifyService: NotificationAlertService, private dialog: MatDialog
    , private http: HttpClient, private paymentService: PaymentService, private cStorage: ClientSideStorageService) {

    this.apiPlaceHolders = this.placeholders['API_Messages']
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.paymentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
    this.captureForm = this.fb.group({
      amount: ['', Validators.required],
    });
    this.checkoutForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      cardNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{16}$/)]],
      expiry: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/[0-9]{2}$/)]],
      cvc: ['', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]],
      cardholderName: ['', Validators.required],
      country: ['US', Validators.required],
      zip: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]]
    });
  }







  ngOnInit() {
    if (this.useremail) {
      this.paymentForm.get('email')?.setValue(this.useremail);
      // this.checkoutForm.get('email')?.setValue(this.email);
    }
    if (this.brandname) {
      this.paymentForm.get('name')?.setValue(this.userFullName);
      // this.checkoutForm.get('cardholderName')?.setValue(this.name);
    }
    this.btnLabel = this.type === 'campaign' ? 'Authorize Payment' : 'Pay'
    this.initializeStripe();
  }

  pay() {
    this.initializeStripe();
  }
  //pk_test_51Pur3cRspVPo0MKWKaG7vUeURAvlNSEv2OiKFWMZwATBMwFu6Vvc7l1vb3mmcCWjQ0ZYenHBLxnGkKb2nxuhKKsT00IYOzJEnT
  //pk_test_51PyWIARsXoEstc81dXXD1IxdLsNLJiJPWzHjEYwsW9EXIkON6P1hmtpiCZrkAKKDkdZePV9uJDRaWYjxeVb5QCyJ00HWElayXR

  // pk_test_51QG4UlLzjH9c5pL5IrWTC45XXgzRjHxUumnnDH0CrZXyv8Ei51kW13f4aFZhU380viDIb7tkQEft59lgPEmv9DIj00XuCD4LyT

  //pk_live_51QG4UlLzjH9c5pL5pAGleI2smbVWdjteuB0pzbZyME44uoLBASAhIYiPjJI7KApI11bn55CnrpE9lvKBlVXvta53005KN9L92y
  initializeStripe() {
    this.paymentShow = true;
    this.stripe = Stripe('pk_test_51QG4UlLzjH9c5pL5IrWTC45XXgzRjHxUumnnDH0CrZXyv8Ei51kW13f4aFZhU380viDIb7tkQEft59lgPEmv9DIj00XuCD4LyT');
    const elements = this.stripe.elements();
    this.card = elements.create('card');

    // Wait for the component to render completely
    setTimeout(() => {
      this.card.mount(this.cardElement.nativeElement);
    });
  }

  async onSubmit() {
    if (this.paymentForm.valid) {
      this.loading = true;
      this.errorMessage = null;
      this.paymentStatus = null;

      // const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      //   type: 'card',
      //   card: this.card,
      //   billing_details: {
      //     name: this.paymentForm.get('name')?.value,
      //     email: this.paymentForm.get('email')?.value,
      //   }
      // });

      // if (paymentMethod) {
      //   // Store paymentMethod in a class variable for later use
      //   this.paymentMethod = paymentMethod;
      //   console.log('Payment method created:', paymentMethod);
      // }

      // if (error) {
      //   this.errorMessage = error.message;
      //   this.loading = false;
      // } 
      // else {

        if (this.type === 'campaign') {
          // this.payUrl = 'orchestration-service/admin/payment/authorize'
          // this.http.post(environment.authUrl + this.payUrl + '?userId=' + this.personID, {
          //   paymentMethodId: paymentMethod.id,
          //   amount: this.amount,
          //   currency: this.currency,
          //   description: this.description,
          //   brandId: this.brandId,
          //   dealId: this.dealId,
          //   postOfficeId: this.postOfficeId,
          //   deal_name: this.deal_name,
          //   deal_budget: this.deal_budget,
          //   deal_uuid: this.deal_uuid,
          //   country: paymentMethod.card.country,
          //   display_brand: paymentMethod.card.display_brand,
          //   exp_month: paymentMethod.card.exp_month,
          //   exp_year: paymentMethod.card.exp_year,
          //   // funding: "credit",
          //   type: this.type,
          //   last4: paymentMethod.card.last4,
          //   billing_details: {
          //     name: this.paymentForm.get('name')?.value,
          //     email: this.paymentForm.get('email')?.value,
          //     postal_code: paymentMethod.billing_details.address.postal_code
          //   }
          // }).subscribe(
          //   (response: any) => {
          //     if (response.statusCode === '200') {
          //       this.notifyService.showSuccess(
          //         "Payment Authorization Successful!",
          //         'Success',{
          //           timeOut: 3000,
          //           enableHtml: true,
          //         }
          //       );
          //       this.paymsg = 'Authorization'
          //       this.paymentIntetResponse = response;
          //       this.showCheckout = false
          //       this.showCheckoutChanged.emit(this.showCheckout);
          //       this.captureForm.get('amount')?.setValue(response.data.amount);
          //       this.paymentService.updateApiResponse(response);
          //       this.loading = false;
          //     }
          //     else {
          //       this.notifyService.showError(
          //          this.apiPlaceHolders[response.message],
          //         'Failed'
          //       );
          //       this.closePopup();
          //       this.ngOnInit();
          //     }
          //     // this.paymentStatus = 'Payment Successful!';

          //     // this.router.navigate(["/dashboard"]);
          //   },
          //   (error) => {
          //     this.notifyService.showError(
          //       "Payment failed!",
          //       'ERROR'
          //     );
          //     this.errorMessage = 'Payment failed: ' + error.error.message;
          //     this.loading = false;
          //   }
          // );
        }
        else {
debugger
          console.log(this.msgdata)
          this.payUrl= 'orchestration-service/admin/deal/message/pay-and-send?gender='+this.msgdata.gender+'&ageRange='+this.msgdata.ageRange+'&city='+this.msgdata.city+'&state='+this.msgdata.state+'&country='+this.msgdata.country
          // this.payUrl = 'orchestration-service/admin/payment'
          this.http.post(environment.authUrl + this.payUrl ,
        
        
          {
            favoritedDealId:this.msgdata.favoritedDealId,
              occasionDeal: {
                  dealType: 3,
                  //"amountSpent": 1000,
                  //"budget": 1000,
                  composeMessage: this.msgdata.occasionDeal.composeMessage,
                  discountCode: this.msgdata.occasionDeal.discountCode,
                  marketplace_id: this.msgdata.occasionDeal.marketplace_id,
                  status: 1,
               
              },
              paymentDetails: {
                

                  // paymentMethodId: paymentMethod.id,
                  amount: this.amount,
                  currency: this.currency,
                  description: this.description,
                  brandId: this.brandId,
                  // country: paymentMethod.card.country,
                  // display_brand: paymentMethod.card.display_brand,
                  // exp_month: paymentMethod.card.exp_month,
                  // exp_year: paymentMethod.card.exp_year,
                  // // funding: "credit",
                  // type: this.type,
                  // last4: paymentMethod.card.last4,
                  billing_details: {
                    name: this.paymentForm.get('name')?.value,
                    email: this.paymentForm.get('email')?.value,
                    // postal_code: paymentMethod.billing_details.address.postal_code
                  }
              }
          }
        
        
        ).subscribe(
            (response: any) => {
              if (response.statusCode === '200') {
                this.notifyService.showSuccess(
                  "Payment Successful!",
                  'Success'
                );
                // this.paymentIntetResponse = response;
                this.showCheckout = false
                this.showCheckoutChanged.emit(this.showCheckout);
                this.paymsg = 'Captured'
                // this.captureForm.get('amount')?.setValue(response.data.amount);
              }
              // this.paymentStatus = 'Payment Successful!';
              this.paymentService.updateApiResponse(response);
              this.loading = false;
              // this.router.navigate(["/dashboard"]);
            },
            (error) => {
              this.notifyService.showError(
                "Payment failed!",
                'ERROR'
              );
              this.errorMessage = 'Payment failed: ' + error.error.message;
              this.loading = false;
            }
          );
        }
      // }
    }
    else {
      this.notifyService.showError(
        "Payment failed!",
        'ERROR'
      );
    }
  }

  onCapturePayment() {
    this.http.post(environment.authUrl + 'orchestration-service/admin/payment/capture' + '?userId=' + this.personID, {
      paymentIntentId: this.paymentIntetResponse.data.paymentIntentId,
      amount: this.captureForm.get('amount')?.value,
      currency: this.currency,
      brandId: this.brandId,
      dealId: this.dealId,
      postOfficeId: this.postOfficeId,
      deal_name: this.deal_name,
      deal_budget: this.deal_budget,
      deal_uuid: this.deal_uuid,
      description: 'Payment captured for deal',
    }).subscribe(
      (response: any) => {
        if (response.status === 'Success') {
          this.notifyService.showSuccess(
            "Payment Captured Successful!",
            'Success'
          );
        }

        // this.paymentStatus = 'Payment Successful!';
        this.paymentService.updateApiResponse(response);
        this.loading = false;
        // this.router.navigate(["/dashboard"]);
      },
      (error) => {
        this.notifyService.showError(
          "Payment failed!",
          'ERROR'
        );
        // this.errorMessage = 'Payment failed: ' + error.error.message;
        // this.loading = false;
      }
    );
  }

  closePopup() {
    this.dialog.closeAll()
  }
}