<div style="margin-left: 15px;" class="div-27">Payment History</div>
<p style="margin-left: 15px;" class="pt-2">(Transaction history can be seen here)
</p>

<!-- <div class="filter-container col-3">
  <label style="width: 75%; " for="marketPlaceFilter">{{placeHolders['LBL_FILTER_BY_STATUS']}}:</label>
  <select id="marketPlaceFilter" class="custom-select" (change)="applyFilter($event)">
      <option value="A">{{placeHolders['LBL_ACTIVE']}}</option>
      <option value="E">{{placeHolders['LBL_EXPIRED']}}</option>
      <option value="">{{placeHolders['LBL_ALL']}}</option>
  </select>
</div> -->

<form class="date-form mb-2">
  <div class="form-group">
    <label for="fromDate">From Date:</label>
    <input id="fromDate" [max]="today" type="date" [(ngModel)]="fromDate" name="fromDate" class="date-input" />
  </div>

  <div class="form-group">
    <label for="toDate">To Date:</label>
    <input id="toDate" [max]="today" type="date" [(ngModel)]="toDate" name="toDate" class="date-input" />
  </div>

  <!-- <div class="form-group">
    <label for="selectedDate">Selected Date:</label>
    <input id="selectedDate" type="date" [(ngModel)]="selectedDate" name="selectedDate" class="date-input" />
  </div> -->

  <button type="button" (click)="getPaymentData()" class="submit-btn">Get Records</button>
</form>



<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Current High Prospects Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Transaction Date</th>
      <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd-MM-yyyy'}} </td>
    </ng-container>

    <!-- Deal Title Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Deal UUID</th>
      <td mat-cell *matCellDef="let element"> {{element.email ? element.email:'NA'}} </td>
    </ng-container>

    <!-- Deal Id Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Deal Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name ? element.name : 'NA'}} </td>
    </ng-container>



    <!-- Valid To Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef style="width: 12%;">Amount </th>
      <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_STATUS']}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-chip [ngClass]="getStatusClass(element.status)">
          {{getStatusText(element.status)}}
        </mat-chip>
      </td>
    </ng-container>



    <!-- Messages Sent Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element">  {{ element.description ? placeHolders[element.description] : element.type }}  </td>
    </ng-container>

    <!-- Messages Sent Column -->
    <ng-container matColumnDef="card">
      <th mat-header-cell *matHeaderCellDef> Card </th>
      <td mat-cell *matCellDef="let element">  {{ element.card ? element.card: 'NA'}}  </td>
    </ng-container>


    <!-- Current Pending Prospects Column -->
    <ng-container matColumnDef="display_brand">
      <th mat-header-cell *matHeaderCellDef> Card Type</th>
      <td mat-cell *matCellDef="let element"><img width="50px" [src]="getCardBrandImage(element.display_brand)">{{element.display_brand}} </td>
    </ng-container>

    <!-- Total Spent Column -->
    <!-- <ng-container matColumnDef="totalSpent">
      <th mat-header-cell *matHeaderCellDef>{{placeHolders['LBL_TBL_TOTAL_SPENT']}}</th>
      <td mat-cell *matCellDef="let element"> {{element.totalSpent}} </td>
    </ng-container> -->

    <!-- Row definition -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;" (click)="onRowClicked(row)">
    </tr>
  </table>
  <!--   
    <mat-paginator [length]="recordTotalCount"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator> -->

</div>
<div class="pt-2">
  <app-pagination [totalRecords]="totalCount" [recordsPerPage]="pageSize" [activePage]="activePage"
    (onPageChange)="onPageChange($event)"></app-pagination>
</div>