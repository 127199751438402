<div class="div-2 dialog-header">
    <div class="div-3 mb-3" style="
    text-align: center;
">Terms & Conditions</div>
    <mat-icon (click)="closeDialog()">close</mat-icon>
</div>
<div class="dialog-content">
    <h6>TERMS AND CONDITIONS FOR USE OF SHIP2MYID-POST OFFICE DEALS APP (“PLATFORM”) FOR MERCHANTS, BRANDS AND COMPANIES
    </h6>
    <p>WELCOME TO THE SHIP2MYID-POST OFFICE DEALS APP. THE FOLLOWING ARE THE TERMS AND CONDITIONS OF YOUR ACCESS TO AND
        USAGE OF SHIP2MYID-POST OFFICE DEALS APP ECOSYSTEM WHICH HENCE FORTH WILL BE REFERRED TO AS THE “AGREEMENT”.
        MERCHANT OR BRAND COMPANY (“YOU” OR YOUR”) ACKNOWLEDGE THAT YOU HAVE READ, REVIEWED, UNDERSTOOD, AND AGREE TO BE
        BOUND BY THESE TERMS, AND TO COMPLY WITH ALL APPLICABLE LAWS AND REGULATIONS. BY CLICKING THE ‘AGREE’ BUTTON AT
        THE END OF THIS DOCUMENT YOU INDICATE YOUR ACCEPTANCE OF THESE TERMS AND CONDITIONS AND WILL HENCEFORTH BE IN A
        LEGALLY BINDING AGREEMENT BETWEEN YOU AND MAPMYID INC.,(WHICH ALSO TRADES AS Ship2MyID), A DELAWARE USA
        CORPORATION HAVING ITS OFFICE AT 430 BRIGHTMORE DOWNS, ALPHARETTA, GA, 30005.</p>

    <h5>1. DEFINITIONS AND INTERPRETATION</h5>
    <p>These Definitions and interpretations are intended to help clarify terms and concepts used in this Agreement and
        ensure that Merchant fully understand its obligations and rights when using the Ship2MyID-Post Office Deals App.
    </p>
    <ul>
        <li><strong>Advertiser:</strong> Any Merchant, Brand or Company that creates and runs campaigns or deals on the
            Ship2MyID-Post Office Deals App.</li>
        <li><strong>Campaign:</strong> A marketing initiative by a Merchant to promote products or services through the
            Ship2MyID-Post Office Deals App, typically running for a specified period.</li>
        <li><strong>Consumer:</strong> An individual who uses the Ship2MyID-Post Office Deals App to browse, select, and
            redeem deals and offers provided by Merchants.</li>
        <li><strong>Consumer Data Protection & Privacy Policy:</strong> The policy governing how Ship2MyID and
            associated Merchant’s handle and protect consumers’ personal data, drafted in line with the local
            government’s regulation pertaining to data privacy and protection. It outlines the measures taken to ensure
            data privacy and compliance with relevant data privacy and protection laws in {{countryName}}.</li>
        <li><strong>Coupon:</strong> A digital voucher provided by Merchants through the Ship2MyID-Post Office Deals App
            that Consumers can use to claim discounts or special offers.</li>
        <li><strong>Coupon Procurement:</strong> The act of a Consumer downloading or getting a digital coupon code or
            discount link from the Ship2MyID-Post Office Deals App to further use it for claiming an offer or obtaining
            a discount from the merchant.</li>
        <li><strong>Coupon Redemption:</strong> The act of a Consumer using a digital coupon provided by a Merchant
            through the Ship2MyID-Post Office Deals App to claim an offer or discount.</li>
        <li><strong>Fulfilment:</strong> The process by which a Merchant honours and completes a consumer's redemption
            of a coupon or deal as per the terms of the campaign.</li>
        <li><strong>Indemnify:</strong> A legal obligation on one party to compensate for the harm, loss, or damage
            caused to another party.</li>
        <li><strong>Non-Personally Identifiable Information (Non-PII):</strong> Information that cannot be used to
            identify a specific individual, such as aggregated data about Consumers' interaction with the Ship2MyID-Post
            Office Deals App, or individual’s unique digital ID.</li>
        <li><strong>Merchant:</strong> Brand, Company, Shops, Merchants, Businesses, Advertisers or any individual or
            entity that places a deal on the Platform.</li>
        <li><strong>Offer Period:</strong> The duration during which a campaign or deal is active and available for
            Consumers to redeem.</li>
        <li><strong>Personally Identifiable Information (PII):</strong> Any data that could potentially identify a
            specific individual, such as name, address, email, phone number, etc.</li>
        <li><strong>Platform:</strong> Refers to the Ship2MyID-Post Office Deals App and all its associated digital
            interfaces and services.</li>
        <li><strong>Parties:</strong> Shall mean Ship2MyID, Post Office, Service Provider or Merchants, whom altogether
            may be referred as Parties.</li>
        <li><strong>Service Provider:</strong> Ship2MyID in partnership with the respective country’s Post Office,
            responsible for managing and operating the Ship2MyID-Post Office Deals App.</li>
        <li><strong>Ship2MyID-Post Office Deals App:</strong> The digital multi-patented platform developed and owned by
            Ship2MyID and used by the country’s Post Office as a white label service, for offering and managing deals
            and campaigns from Merchants and Brand Company.</li>
        <li><strong>Solicitation:</strong> The act of requesting or attempting to obtain something from someone. In this
            context, it refers to Merchants seeking to obtain PII from Consumers.</li>
        <li><strong>Suspension:</strong> The potentially temporary deactivation of a Merchant’s account or campaign on
            the Ship2MyID-Post Office Deals App due to non-compliance with terms and conditions.</li>
        <li><strong>Termination:</strong> The permanent deactivation or removal of a Merchant’s account or campaign from
            the Ship2MyID-Post Office Deals App due to violations of terms and conditions.</li>
        <li><strong>Transaction Declaration:</strong> The requirement for Merchants to report all coupon redemptions and
            transactions facilitated through the Ship2MyID-Post Office Deals App to Ship2MyID for tracking and
            compliance purposes.</li>
        <li><strong>Governing Law:</strong> The legal framework under which the terms and conditions are interpreted and
            enforced. This is based on the laws of the country where the service is provided.</li>
        <li><strong>Compliance:</strong> Adherence to laws, regulations, and policies relevant to the use of the
            Ship2MyID-Post Office Deals App by Merchants.</li>
        <li><strong>Notification of Changes:</strong> The process by which Ship2MyID informs Merchants of amendments to
            the terms and conditions via email or platform notifications.</li>
    </ul>

    <h5>2. INTRODUCTION</h5>
    <p>The terms & conditions of this Agreement governs the relationship between three primary entities within the
        Ship2MyID-Post Office Deals App ecosystem:</p>
    <ul>
        <li>Merchants or Brand Company</li>
        <li>Consumers</li>
        <li>Ship2MyID-Post Office Deals App (partnership between the Service Provider and Post Office)</li>
    </ul>

    <h5>3. OBLIGATIONS OF MERCHANTS: CONSUMER DATA PRIVACY AND PROTECTION</h5>
    <ol>
        <li>
            Merchants must always conform to and comply with the consumer data privacy and protection 
            policy of Ship2MyID and the respective country’s post office. 
            <!-- <a href="your-consumer-data-protection-policy-link">Click here to read our Consumer Data Protection & Privacy Policy.</a> -->
        </li>
        <li>
            Non-Solicitation of PII: Merchants or Brand Company shall abstain from directly or indirectly 
            soliciting any Personally Identifiable Information (PII) from Consumers within the Ship2MyID-Post 
            Office Deals App system. Violation of this policy may result in penalties and/or applicable legal 
            action by competent government authorities and You shall solely be responsible for the same and 
            shall indemnify Ship2MyID in the event of any claims by the Consumers or government authorities 
            against Ship2MyID.
        </li>
        <li>
            Ship2MyID does not guarantee the security of the services or transaction data, and Ship2MyID will 
            not be responsible in the event of any infiltration of its security systems, provided that Ship2MyID 
            has used commercially reasonable efforts to prevent any such infiltration. Merchant will only have 
            their unique digital ID and will not make any attempt to connect with those Consumers, without 
            their explicit permission. Ship2MyID will take the best efforts to validate the Consumer’s 
            demographic data but cannot vouch for some of the demographic information that is incorrectly 
            given by consumers. We use multiple validation process to check authenticity of mobile phone 
            numbers and email IDs.
        </li>
        <li>
            The Merchant acknowledges and agrees that the protection and security of Consumer data, 
            shipping data shall be solely responsibility of the Merchant.
        </li>
        <li>
            Standard of Care. Except as otherwise permitted in writing by Ship2MyID, the Merchant will:
            <ul>
                <li>Use the same degree of care that it uses to protect the confidentiality of its own Confidential Information of like kind (but in no event less than reasonable care).</li>
                <li>Not disclose or use any Confidential Information of Ship2MyID for any purpose outside the scope of this Agreement.</li>
                <li>Limit access to Confidential Information of Ship2MyID Consumers to those of its employees, contractors, advisors, and agents with a need to know or who need access for purposes consistent with this Agreement and who are bound by confidentiality obligations at least as stringent as those in this Agreement.</li>
            </ul>
        </li>
    </ol>

    <h6>A. Honouring Redemption Coupons & Sharing of Transaction Details</h6>
    <p>Merchants must adhere to the following requirements for deals and campaigns conducted on the Ship2MyID-Post Office Deals App platform:</p>
    <ul>
        <li><b>Best Discount Guarantee: </b>Merchants are required to provide the most favourable deals on the Ship2MyID-Post Office Deals App platform. Consumers must not find a better deal from the same Merchant posted in any other marketplace or platform.</li>
        <li>Merchants are expected to honour all redemption coupons and complete all fulfilment as per the campaign or deal offered, provided the claim is within the offer period.</li>
        <li>Merchants <u>must declare</u> each and every coupon redemption or transaction with the consumers, that is routed through the Ship2MyID-Post Office deals App. Any non-compliance or attempts to conceal this information from sharing with Ship2MyID will lead to penalties and/or temporary suspension of campaign or merchant account from the system.</li>
    </ul>
    <h5>4. ADVERTISER RESPONSIBILITIES AND RESTRICTIONS</h5>
    <h6>A. Compliance with Laws and Regulations</h6>
    <ol>
        <li>Merchants must ensure that their advertisements comply with all applicable laws, regulations, and guidelines, including those set forth by regulatory authorities in that country.</li>
        <li>Advertisers must not engage in deceptive, misleading, or unlawful advertising practices.</li>
    </ol>
    <h6>B. Prohibited Content</h6>
    <ol>
        <li>Advertisers may not promote products, services, or content that is illegal, unsafe, or otherwise violate our community standards. This includes but is not limited to:
            <ul>
                <li>Illegal products or services</li>
                <li>Discriminatory practices</li>
                <li>Infringement of intellectual property rights</li>
                <li>Content that is offensive, inappropriate, or harmful (nudity, drugs etc.)</li>
            </ul>
        </li>
    </ol>

    <h5>5. COMPLIANCE AND PENALTIES</h5>
    <ol>
        <li>Merchants must adhere to all applicable laws and regulations, including those related to Consumer protection, data privacy, and marketing practices.</li>
        <li>Failure to comply with these terms may result in the suspension or termination of the Merchant’s account, removal of offers, and legal actions and penalties where applicable.</li>
        <li>Any penalty levied on Ship2MyID-Post Office Deals App, arising due to default or non-compliance of local governing law from the Merchant, shall be borne by the respective Merchant.</li>
    </ol>

    <h5>6. LIABILITY AND INDEMNIFICATION</h5>
    <ol>
        <li>Ship2MyID and/or Post Office will not be liable for any indirect, incidental, special, or consequential damages arising from the use of the platform. IN NO EVENT WILL SHIP2MYID’S LIABILITY ARISING OUT OF THIS AGREEMENT SHALL EXCEED $100 (US DOLLAR ONE HUNDRED ONLY)</li>
        <li>Merchants agree to indemnify and hold harmless Ship2MyID and Post Office against any claims, damages, losses, liabilities, and expenses arising out of or in connection with their use of the platform. Further Merchant will indemnify, defend and hold harmless Ship2MyID and Post Office, its officers, directors, agents and employees, from and against any and all claims, losses, demands, liabilities, damages, costs and expenses (including reasonable attorneys’ fees), either arising out of or relating to:
            <ul>
                <li>The breach of any representation or warranty by Merchant</li>
                <li>The sale or use of any product or services sold by Merchant</li>
                <li>Claims brought or damages suffered by any Consumers</li>
                <li>The breach of this Agreement or any representation or warranty by Merchant</li>
            </ul>
        </li>
        <li>Any penalty levied on Ship2MyID-Post Office Deals App, arising due to default or non-compliance of local governing law from the Merchant, shall be borne by the respective Merchant.</li>
    </ol>

    <h5>7. WARRANTIES AND DISCLAIMERS</h5>
    <p>EXCEPT AS EXPRESSLY SET FORTH ABOVE AND TO THE EXTENT PERMITTED BY APPLICABLE LOCAL GOVERNING 
    LAW, SHIP2MYID MAKES NO WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE 
    SERVICES OR SOFTWARE, PLATFORM, AND ALL SUCH WARRANTIES, INCLUDING WITHOUT LIMITATION THE 
    IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR PARTICULAR PURPOSE HEREBY EXPRESSLY 
    DISCLAIMED BY SHIP2MYID. MERCHANT ACKNOWLEDGES THAT SHIP2MYID HAS NOT REPRESENTED OR 
    WARRANTED THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR WITHOUT DELAY OR WITHOUT 
    COMPROMISE OF THE SECURITY SYSTEMS RELATED TO THE SERVICES.</p>

    <h5>8. INTELLECTUAL PROPERTY RIGHTS</h5>
    <p>All rights not expressly granted to Merchant under this Agreement are reserved by Ship2MyID and its licensors. 
    This Agreement does not give you an interest in or to Ship2MyID’s intellectual property rights, SHIP2MYID-POST 
    OFFICE DEALS APP OR Platform or software. Nothing in this Agreement constitutes a waiver of Ship2MyID’s 
    intellectual property rights under any law. Merchant shall not copy, imitate, or use above mentioned intellectual 
    property rights without Ship2MyID’s prior written consent. In addition, all page headers, custom graphics, 
    button icons, and scripts are service marks, trademarks, and/or trade address of Ship2MyID and Merchant shall 
    not copy, imitate, or use them without our prior written consent. All rights, title and interests in and to the 
    Ship2MyID website, any content thereon, the Services, the technology related to the Services, and any and all 
    technology and any content created or derived from any of the foregoing, is the exclusive property of Ship2MyID 
    and its licensors.</p>

    <h5>9. NO ASSIGNMENT</h5>
    <p>Merchant may not assign this Agreement without the prior written consent of Ship2MyID.</p>

    <h5>10. GOVERNING LAW</h5>
    <p>These terms and conditions shall be governed by and construed in accordance with the laws of the respective country in which the services are provided.</p>

    <h5>11. TERMINATION</h5>
    <p>Ship2MyID reserves the right to terminate or suspend a Merchant's account at any time for violations of the terms and conditions of this Agreement or for any behaviour deemed detrimental to the platform's integrity and users.</p>

    <h5>12. CONTACT INFORMATION</h5>
    <p>For any questions or concerns regarding these terms, please contact our support team at
    <a href="mailto:merchant@ship2myid.com">merchant&#64;ship2myid.com</a></p>

    <h5>13. CHANGES TO PLATFORM FUNCTIONALITY</h5>
    <p>Ship2MyID may modify the services from time to time in Ship2MyID’s reasonable discretion and upon reasonable electronic or written notice to Merchant, provided that such modifications shall not materially diminish the functionality of the Platform.</p>

    <h5>14. RELATIONSHIP</h5>
    <p>Nothing in this Agreement will be deemed or is intended to be deemed, nor will it cause, any of the Parties to be treated as partners, joint ventures, or otherwise as joint associates for profit. Merchant do not have any authority of any kind to bind Ship2MyID.</p>

    <h5>15. SEVERABILITY</h5>
    <p>In the event that any provision of this Agreement is unenforceable or invalid such unenforceability or invalidity will not render this Agreement unenforceable or invalid as a whole, and in such event, such provision will be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.</p>

    <h5>16. FORCE MAJEURE (EVENTS BEYOND THE PARTIES’ CONTROL)</h5>
    <p>Neither party shall be liable for any failure or delay in performing any obligation under this Agreement that is due to the causes beyond its reasonable control.</p>

    <h5>17. ENTIRE AGREEMENT AND MODIFICATION</h5>
    <p>Unless otherwise required by law and unless otherwise agreed between the parties, Ship2MyID may change the terms and conditions of the Agreement from time to time, and such change will become effective upon the date on which it is posted on the Ship2MyID websites. Ship2MyID will do its best to keep all stakeholders notified. The Merchant is responsible for checking the website regularly for such changes. By continuing to access or use the Services, the Merchant agrees to be bound by the revised Agreement.</p>

    <p><b>Advertising Refunds:</b> MapMyId Inc also known as Ship2MyID does not guarantee that ads will achieve specific outcomes and typically does not offer refunds for poor ad performance or return on investment. Refunds are granted at Ship2MyId’s sole discretion and without admission of liability. Advertisers are usually notified about refunds through our platform or directly by Ship2MyId account representative. If no notification is received, it indicates that a refund is not being issued. Refunds may take at least one month to process and are issued to the original payment method when possible; otherwise, ad credits may be provided.</p>

    <p>BY AGREEING TO THESE TERMS AND CONDITIONS, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY ALL THE TERMS AND CONDITIONS STATED ABOVE. FURTHER, THE MERCHANT AGREES THAT BY USING THE PLATFORM AND CLICKING THE “AGREE” BUTTON IT SHALL CONSTITUTE A VALID ELECTRONIC SIGNATURE AND ALSO AGREES THAT ELECTRONIC CONSENT HAS AND WILL HAVE THE SAME LEGAL EFFECT AS A PHYSICAL SIGNATURE.</p>

    <!-- <p (click)="navigateFaqs()" style="cursor: pointer; color: blue;">Click here for more FAQs</p> -->
</div>