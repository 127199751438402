<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
  <!-- src="https://botswanapost.post/sites/default/files/logo%20%281%29.png" -->
  <!-- src: 'assets/img/brand/ship2myidLogo.svg', -->
  <c-sidebar-brand [brandFull]="{
      src: logoUrl,
      width: 200,
      height: 'auto',
      alt: 'CoreUI Logo'
    }" />

  <ng-scrollbar pointerEventsMethod="scrollbar" *ngIf="fullName">
    <img *ngIf="brandimage!==null" style="height: 15vh;display:block;
    width: 15vw;
    padding-left: 20px;
    padding-top: 10px;" [src]="brandimage" >
    <div>
      <div>
        <div class="profile-container">
          <!-- <img src="path_to_your_image.jpg" alt="User Profile Image" class="profile-image" onerror="this.onerror=null;this.src='../../../assets/images/user.png';"> -->
          <div class="profile-details">
            <h3 class="profile-name" [ngStyle]="{'font-size.px': fontSize}"  style="text-transform: capitalize; color: #36849d;margin-left: 28px;">{{merchantName}}</h3>
            <!-- <p class="profile-role" style="text-transform: capitalize;" >{{roleType}}</p> -->
          </div>
        </div>
      </div>
    </div>

    <c-sidebar-nav
      [navItems]="navItems"
      dropdownMode="close"
    />
  </ng-scrollbar>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">

  <app-default-header [userFullName]="fullName" [userRoleType]="roleType"  class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />

  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="xxl" class="h-auto">
      <router-outlet />
    </c-container>
  </div>
</div>
<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="{{placeHolders['LBL_PLEASE_WAIT']}}"></ngx-ui-loader>