import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportdashboard',
  templateUrl: './reportdashboard.component.html',
  styleUrl: './reportdashboard.component.scss'
})
export class ReportdashboardComponent implements OnInit {

  constructor(private router: Router){

  }

  ngOnInit (){

  }

  generateReport(reportId: number) {
    switch (reportId) {
      case 1:
        this.router.navigate(['/reports']);
        break;
      case 2:
        this.router.navigate(['/dealreports']);
        break;
      case 3:
        this.router.navigate(['/deal-specific-events']);
        break;
      default:
        console.log("Invalid Report Selection");
    }
  }
}
