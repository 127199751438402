<div class="page_container">
    <header class="header">
        <div class="left-logo">

            <img *ngIf="countryInitials === 'Z'" style="margin-left: 40px;"
                src="../../../assets/images/zim-head-reg.png"
                alt="logo" class="logo">
                <!-- https://cdn.builder.io/api/v1/image/assets/TEMP/a47714be-3217-4ffe-a745-a8e5e12fe2a1? -->
            <img *ngIf="countryInitials === 'C'"
                src="../../../assets/images/coteivory_head.png"
                alt="Right Logo" class="logo">
            <img *ngIf="countryInitials === 'B'" style="margin-left: 40px;" src="../../../assets/images/poso_click-removebg.png" alt="Left Logo"
                class="logo">
        </div>
        <div class="right-logo" *ngIf="countryInitials === 'B' || countryInitials === 'Z' || countryInitials === 'C'">
            <img src="../../../assets/images/Ship2myID_powerBY-removebg.png" style="margin-right: 40px;"
                alt="Right Logo" class="logo">
        </div>
    </header>


    <!-- app.component.html -->
    <div class="container">
        <div class="div-14">
            <div class="div-15" *ngIf="countryInitials === 'D'">{{placeHolders['LBL_REG_TITLE']}}</div>
            <div class="div-15" *ngIf="countryInitials === 'Z'">Registration</div>
            <div class="div-15" *ngIf="countryInitials === 'B'">Business Registration</div>
            <div class="div-15" *ngIf="countryInitials === 'C'">{{placeHolders['LBL_REG_TITLE']}}</div>

        </div>
        <div class="div-19">

            <!-- <div class="container mt-1"> -->
            <form [formGroup]="administartiveForm" class="wd-90">
                <!--Name Field Section -->
                <div class="row col-12 col-md-12">
                    <div class="col-4 form-group">
                        <label class="asterix" for="primarycontact">{{placeHolders['LBL_PRIMARY_CONTACT']}}</label>
                        <input type="text" formControlName="firstname" class="form-control"
                            placeholder="{{placeHolders['LBL_PH_FIRST_NAME']}}" [readonly]="isFormValid"
                            [ngClass]="{ 'is-invalid':  f['firstname'].errors && (f['firstname'].dirty || f['firstname'].touched )}"
                            pattern="^[A-Za-z ]+$" maxlength="30" (keydown.space)="$event.preventDefault();" required />
                        @if ( f['firstname'].errors ) {
                        <div class="invalid-feedback">
                            @if (f['firstname'].errors['required']) {
                            <div>{{placeHolders['MSG_ERR_FIRST_NAME_REQUIRED']}}</div>
                            }
                            @if (f['firstname'].errors['minlength']) {
                            <div>{{placeHolders['MSG_ERR_FIRST_NAME_MIN_2_CHARS']}}</div>
                            }
                            @if (f['firstname'].errors['maxlength']) {
                            <div>{{placeHolders['MSG_ERR_FIRST_NAME_MAX_30']}}</div>
                            }
                            @if (f['firstname'].errors['pattern']) {
                            <div>{{placeHolders['MSG_ERR_ONLY_CHARS_ALLOWED']}}</div>
                            }
                        </div>
                        }
                    </div>
                    <div class="col-4 form-group">
                        <label for="primarycontact"></label>
                        <input type="text" class="form-control" formControlName="lastname"
                            placeholder="{{placeHolders['LBL_PH_LAST_NAME']}}" [readonly]="isFormValid"
                            [ngClass]="{ 'is-invalid':  f['lastname'].errors && (f['lastname'].dirty || f['lastname'].touched )}"
                            pattern="^[A-Za-z ]+$" maxlength="30" required (keydown.space)="$event.preventDefault();" />
                        @if ( f['lastname'].errors ) {
                        <div class="invalid-feedback">
                            @if (f['lastname'].errors['required']) {
                            <div>{{placeHolders['MSG_ERR_LAST_NAME_REQUIRED']}}</div>
                            }
                            @if (f['lastname'].errors['minlength']) {
                            <div>{{placeHolders['MSG_ERR_LAST_NAME_MIN_2_CHARS']}}</div>
                            }
                            @if (f['lastname'].errors['maxlength']) {
                            <div>{{placeHolders['MSG_ERR_LAST_NAME_MAX_30_CHARS']}}</div>
                            }
                            @if (f['lastname'].errors['pattern']) {
                            <div>{{placeHolders['MSG_ERR_ONLY_CHARS_ALLOWED']}}</div>
                            }
                        </div>
                        }
                    </div>
                </div>

                <!--Email Section Field Section -->

                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-4 form-group">
                        <label class="asterix" for="email">{{placeHolders['LBL_REG_PRIMARY_EMAIL']}}</label>
                        <input type="email" class="form-control" formControlName="email"
                            placeholder="{{placeHolders['LBL_PH_EMAIL']}}" maxlength="75" (keydown)="onKeyDown($event)"
                            [ngClass]="{ 'is-invalid':  f['email'].errors && (f['email'].dirty || f['email'].touched ) }"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" [readonly]="readonlycumstomfield"
                            required />
                        <small style="font-size: small;">({{placeHolders['LBL_REG_PREFER_EMAIL']}})</small>
                        @if ( f['email'].errors) {
                        <div class="invalid-feedback">
                            @if (f['email'].errors['required']) {
                            <div> {{placeHolders['MSG_ERR_EMAIL_REQUIRED']}}</div>
                            }
                            @if (f['email'].errors['pattern']) {
                            <div> {{placeHolders['MSG_ERR_INVALID_EMAIL']}}</div>
                            }
                            @if (f['email'].errors['maxlength']) {
                            <div> {{placeHolders['MSG_ERR_EMAIL_MAX_50']}}</div>
                            }
                        </div>
                        }
                    </div>

                    <img *ngIf="emailVerified===true" loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e77607a-df82-4ba3-a07e-abde78fac171?"
                        class="img-succes-tick col-1" />
                    <svg (click)="resetEmail()" class="img-reset col-1" *ngIf="readonlycumstomfield"
                        xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                        <path fill="#417cc8"
                            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2v2a8 8 0 1 0 5.135 1.865L15 8V2h6l-2.447 2.447A9.98 9.98 0 0 1 22 12" />
                    </svg>
                    <div class="col-1">
                        <button *ngIf="emailOtpField===false" [disabled]="!isMailValid() " class="div-37"
                            (click)="sendEmailOtp()">{{placeHolders['LBL_VERIFYEMAIL']}}</button>
                    </div>
                </div>

                <div class="row col-12 col-md-12 pt-2" *ngIf="verifyEmailField===false && resendEmailOtp !== true">
                    <div class="col-6 form-group">
                        <label> {{placeHolders['LBL_ENTER_EMAIL_OTP']}}</label> (<span style="color: green; font-size: small;"> {{toolTips['TIP_5']}}</span>)
                        <ng-otp-input formControlName="emailotp" (onInputChange)="onOtpEmailChange($event)"
                            [config]="{length:6}" ngDefaultControl></ng-otp-input>
                    </div>
                    <div class="col-2">
                        <button class="div-37 submitOtp"
                            (click)="validateOtpEmail()">{{placeHolders['LBL_REG_BTN_SUBMIT']}} </button>
                    </div>
                    
                </div>

                <div class="row col-12 col-md-12">
                    <div *ngIf="verifyEmailField===false" class="px-2" style="text-align: left;color: gray;">
                        <p *ngIf="displayEmailTimer"> {{placeHolders['LBL_EXPIRING_IN']}} <span style="color: red;">{{
                                displayEmail
                                }}</span></p>
                        <a *ngIf="resendEmailOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
                            (click)="resendEmailOTP()"> {{placeHolders['LBL_RESEND_EMAIL_OTP']}}</a>
                    </div>
                </div>

                <!--Phone Section Field Section -->
                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-2 form-group ml-12">
                        <div class="custom-select">
                            <label class="asterix" for="countrySelect">{{placeHolders['LBL_REG_COUNTRY_CODE']}}</label>
                            <select [attr.disabled]="readonlyPhonecumstomfield ? true : null" class="form-control"
                                formControlName="code" id="code">
                                <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
                                    {{option.phonePrefixWithCountryName}}</option>
                            </select>
                        </div>
                        <div *ngIf="code?.touched && code?.invalid" style="margin-top: -15px;" class="notmet">
                            {{placeHolders['MSG_ERR_CODE_REQUIRED']}}
                        </div>
                    </div>


                    <div class="col-4 form-group ml-20">
                        <label class="asterix" for="phonenumber">{{placeHolders['LBL_REG_MOBILE_NUMBER']}}</label>
                        <input *ngIf="countryInitials === 'D'" type="number"
                            onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                            class="form-control" formControlName="phone"
                            placeholder="{{placeHolders['LBL_PH_MOBILE_NUMBER']}}"
                            [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                            required pattern="^[0-9]{8,10}$" [readonly]="readonlyPhonecumstomfield"
                            onKeyPress="if(this.value.length==10) return false;" />
                        <input *ngIf="countryInitials === 'Z'" type="number"
                            onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                            class="form-control" formControlName="phone" placeholder="Mobile number"
                            [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                            required pattern="^[0-9]{8,10}$" [readonly]="readonlyPhonecumstomfield"
                            onKeyPress="if(this.value.length==10) return false;" />
                        <input *ngIf="countryInitials === 'B'" type="number"
                            onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                            class="form-control" formControlName="phone" placeholder="Mobile number"
                            [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                            required pattern="^[0-9]{8,10}$" [readonly]="readonlyPhonecumstomfield"
                            onKeyPress="if(this.value.length==10) return false;" />
                        <input *ngIf="countryInitials === 'C'" type="text"
                            onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                            class="form-control" formControlName="phone"
                            placeholder="{{placeHolders['LBL_MOBILENUMBER']}}"
                            [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                            required pattern="^[0-9]{8,10}$" [readonly]="phoneVerified"
                            onKeyPress="if(this.value.length==10) return false;" />
                        @if ( f['phone'].errors) {
                        <div class="invalid-feedback">
                            @if (f['phone'].errors['required']) {
                            <div> {{placeHolders['MSG_ERR_MOBILE_REQUIRED']}}</div>
                            }
                            @if (f['phone'].errors['pattern']) {
                            <div> {{placeHolders['MSG_ERR_VALID_MOBILE_NO']}}</div>
                            }
                        </div>
                        }
                    </div>

                    <img *ngIf="phoneVerified===true" loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e77607a-df82-4ba3-a07e-abde78fac171?"
                        class="img-succes-tick col-1" />
                    <svg (click)="resetPhone()" class="img-reset col-1" *ngIf="readonlyPhonecumstomfield"
                        xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                        <path fill="#417cc8"
                            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2v2a8 8 0 1 0 5.135 1.865L15 8V2h6l-2.447 2.447A9.98 9.98 0 0 1 22 12" />
                    </svg>
                    <div class="col-1">
                        <button class="div-37" [disabled]="!areCodeAndPhoneValid()" *ngIf="phoneOtpField===false"
                            (click)="sendMobileOtp()">{{placeHolders['LBL_VERIFYMOBILE']}}</button>
                    </div>
                </div>

                <!----Phone -->
                <div class="row col-12 col-md-12" *ngIf="verifyPhoneField===false && resendMobileOtp !== true">
                    <div class="col-6 form-group">

                        <label> {{placeHolders['LBL_ENTER_MOBILE_OTP']}}</label> (<span style="color: green; font-size: small;">{{toolTips['TIP_PAYMENT_MSG_FOR_REVIEW']}}</span>)
                        <ng-otp-input formControlName="phoneotp" (onInputChange)="onOtpPhoneChange($event)"
                            [config]="{length:6}" ngDefaultControl></ng-otp-input>
                    </div>
                   
                    <div class="col-2">
                        <button class="div-37 submitOtp"
                            (click)="validateOtpPhone()">{{placeHolders['LBL_REG_BTN_SUBMIT']}} </button>
                    </div>
                    
                </div>
                <div class="row col-12 col-md-12">
                    <div *ngIf="verifyPhoneField===false" class="px-2" style="text-align: left;color: gray;">
                        <p *ngIf="displayMobileTimer"> {{placeHolders['LBL_EXPIRING_IN_MOBILE']}} <span
                                style="color: red;">{{ displayMobile
                                }}</span></p>
                        <a *ngIf="resendMobileOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
                            (click)="resendMobileOTP()"> {{placeHolders['LBL_RESEND_MOBILE_OTP']}}</a>
                    </div>
                </div>


                <!--Business Details Section -->
                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-4 form-group">
                        <label class="asterix" for="companyname">{{placeHolders['LBL_REG_BUSINESS_NAME']}}</label>
                        <input type="text" formControlName="companyName" class="form-control" [readonly]="isFormValid"
                            placeholder=" {{placeHolders['LBL_PH_BUSINESS_NAME']}}"
                            [ngClass]="{ 'is-invalid':  f['companyName'].errors && (f['companyName'].dirty || f['companyName'].touched )}"
                            required maxlength="50" />
                        @if ( f['companyName'].errors ) {
                        <div class="invalid-feedback">
                            @if (f['companyName'].errors['required']) {
                            <div> {{placeHolders['MSG_ERROR_BUSINESS_NAME_REQUIRED']}}</div>
                            }
                            @if (f['companyName'].errors['minlength']) {
                            <div> {{placeHolders['MSG_ERR_BUSINESS_NAME_MIN_2_CHAR']}}</div>
                            }
                            @if (f['companyName'].errors['maxlength']) {
                            <div> {{placeHolders['MSG_BUSINESS_NAME_MAX_50_CHAR']}}</div>
                            }
                            @if (f['companyName'].errors['pattern']) {
                            <div> {{placeHolders['MSG_ERR_ONLY_CHARS_ALLOWED']}}</div>
                            }
                        </div>
                        }
                    </div>
                    <div class="col-4 form-group">
                        <label for="companywebsite">{{placeHolders['LBL_REG_BUSINESS_WEBSITE']}}</label>
                        <input type="url" formControlName="companyWebsite" class="form-control"
                            (keydown)="onKeyDown($event)" placeholder=" {{placeHolders['LBL_PH_BUSINESS_WEBSITE']}}"
                            pattern="^(?:(http(s)?)?(sftp)?(ftp)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                            [ngClass]="{ 'is-invalid':  f['companyWebsite'].errors && (f['companyWebsite'].dirty || f['companyWebsite'].touched )}" />
                        <small>({{placeHolders['LBL_OPTIONAL']}})</small>
                        @if ( f['companyWebsite'].errors) {
                        <div class="invalid-feedback">
                            @if (f['companyWebsite'].errors['required']) {
                            <div> {{placeHolders['MSG_ERR_BUSINESS_WEBSITE_REQUIRED']}}</div>
                            }
                            @if (f['companyWebsite'].errors['minlength']) {
                            <div> {{placeHolders['MSG_ERR_WESITE_MIN_2_CHARS']}}</div>
                            }
                            @if (f['companyWebsite'].errors['pattern']) {
                            <div> {{placeHolders['MSG_ERR_VALID_WEBSITE']}}</div>
                            }
                        </div>
                        }
                    </div>
                    <div class="col-4 form-group">
                        <label *ngIf="countryInitials === 'D'"
                            for="regiid">{{placeHolders['LBL_REG_REGISTRATION_ID']}}</label>
                        <label *ngIf="countryInitials === 'B'" for="regiid">Registration ID</label>
                        <label *ngIf="countryInitials === 'Z'" for="regiid">Registration/Business partner No.</label>
                        <label *ngIf="countryInitials === 'C'"
                            for="regiid">{{placeHolders['LBL_REG_REGISTRATION_ID']}}</label>
                        <input type="text" maxlength="50" formControlName="registrationId" class="form-control"
                            placeholder=" {{placeHolders['LBL_PH_ENTER_REG_ID']}}"
                            [ngClass]="{ 'is-invalid':  f['registrationId'].errors && (f['registrationId'].dirty || f['registrationId'].touched )}" />
                        <small>({{placeHolders['LBL_OPTIONAL']}})</small>
                        @if ( f['registrationId'].errors) {
                        <div class="invalid-feedback">
                            @if (f['registrationId'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                            @if (f['registrationId'].errors['required']) {
                            <div>Registration Id is required</div>
                            }
                        </div>
                        }
                    </div>
                </div>
                <!-- Compnay phone number -->

                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-2 form-group">
                        <div class="custom-select">
                            <label for="countrySelect">{{placeHolders['LBL_REG_COUNTRY_CODE']}}</label>
                            <select [attr.disabled]="readonlyPhonecumstomfield ? true : null" class="form-control"
                                formControlName="businesscountrycode" id="businesscountrycode">
                                <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
                                    {{option.phonePrefixWithCountryName}}</option>
                            </select>
                        </div>
                        <div *ngIf="businesscountrycode?.touched && businesscountrycode?.invalid" style="margin-top: -15px;" class="notmet">
                            {{placeHolders['MSG_ERR_CODE_REQUIRED']}}
                        </div>
                    </div>
                    <div class="col-4 form-group ml-20">
                        <label class="" for="primarycontact">{{placeHolders['LBL_REG_BUSINESS_PHONE']}}</label>
                        <input type="text" formControlName="buisinessPhone" class="form-control"
                            placeholder=" {{placeHolders['LBL_PH_BUSINESS_PHONE_NO']}}"
                            [ngClass]="{ 'is-invalid': f['buisinessPhone'].errors && (f['buisinessPhone'].dirty || f['buisinessPhone'].touched ) }" />
                        <small>({{placeHolders['LBL_OPTIONAL']}})</small>
                        @if ( f['buisinessPhone'].errors) {
                        <div class="invalid-feedback">
                            @if (f['buisinessPhone'].errors['pattern']) {
                            <div>Invalid number</div>
                            }
                        </div>
                        }
                    </div>
                </div>

                <div class="row col-12 col-md-12 pt-5">
                    <!-- <div class="col-1 form-group">
                  <img loading="lazy" style="margin-top: -50px;
                    margin-left: -18px;"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8f4f44b-f8c1-44b2-bffd-f3f58fdbb53f?"
                    class="img-5" />
                </div> -->
                    <div class="col-4 form-group">
                        <div class="div-25">{{placeHolders['LBL_REG_BUSINESS_ADDRESS']}}</div>
                    </div>
                </div>


                <!-- Address section -->
                <div class="row col-12 col-md-12 mt-1">
                    <div class="col-5 form-group ml-12">
                        <label class="asterix" for="CompanyName">{{placeHolders['LBL_REG_ADDRESS_LINE']}}</label>
                        <textarea class="form-control" formControlName="addressLine" minlength="5" maxlength="250"
                            placeholder=" {{placeHolders['LBL_PH_ENTER_BUSINESS_ADDRESS']}}" required
                            [readonly]="isFormValid"
                            [ngClass]="{ 'is-invalid':  f['addressLine'].errors && (f['addressLine'].dirty || f['addressLine'].touched )}">
                            </textarea>
                        <small style="font-size: small;">(Provide registered company address and landmark)</small>
                        @if ( f['addressLine'].errors) {
                        <div class="invalid-feedback">
                            @if (f['addressLine'].errors['required']) {
                            <div>Address is required</div>
                            }
                            @if (f['addressLine'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                            @if (f['addressLine'].errors['minlength']) {
                            <div>must be at least 5 characters</div>
                            }
                        </div>
                        }
                    </div>
                    <div class="col-5 form-group ml-12">
                        <label for="additionalinfo">{{placeHolders['LBL_REG_ADDITIONAL_INFO']}}</label>
                        <textarea class="form-control" formControlName="additionalInfo"
                            placeholder=" {{placeHolders['LBL_PH_BUSINESS_ADDRESS_2']}}" maxlength="250"
                            [ngClass]="{ 'is-invalid':  f['additionalInfo'].errors && (f['additionalInfo'].dirty || f['additionalInfo'].touched )}">
                            </textarea>
                        <small>({{placeHolders['LBL_OPTIONAL']}})</small>
                        @if ( f['additionalInfo'].errors) {
                        <div class="invalid-feedback">
                            @if (f['additionalInfo'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                        </div>
                        }
                    </div>
                </div>

                <!-- <mat-label>Select Country</mat-label> -->
                <!-- <div class="row col-12 col-md-12 pt-3">
                        <label class="asterix" for="countrySelect">Country Code</label>
                        <mat-form-field>
                            <mat-label>Select your code</mat-label>
                            <select matNativeControl id="mySelectId">
                              <option value="" disabled selected></option>
                              <option value="volvo">Volvo</option>
                              <option value="saab">Saab</option>
                              <option value="mercedes">Mercedes</option>
                              <option value="audi">Audi</option>
                            </select>
                          </mat-form-field>

                    </div> -->
                <!--                     
                    <label class="asterix" for="countrySelect">Country Code</label>
                    <mat-form-field>
                        <mat-label>Select your code</mat-label>
                        <select matNativeControl id="mySelectId">
                          <option value="" disabled selected></option>
                          <option value="volvo">Volvo</option>
                          <option value="saab">Saab</option>
                          <option value="mercedes">Mercedes</option>
                          <option value="audi">Audi</option>
                        </select>
                      </mat-form-field> -->

                <!-- <div class="row">
                    <div class="col-sm-6">
                        <mat-form-field appearance="fill">
                            <mat-label>Select a country</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let country of countryData" [value]="country.country_id">{{ country.country_name
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->

                <!--Drop down section for country city  state-->
                <div class="row pt-3">
                    <div class="col-3 form-group">
                        <div class="custom-select">
                            <label class="asterix" for="countrySelect">{{placeHolders['LBL_REG_COUNTRY']}}</label>
                            <select [attr.disabled]="isFormValid ? true : null" matNativeControl required
                                class="form-control" formControlName="merchant_country" id="countrySelect"
                                (change)="countrySelectionForState()">
                                <option *ngFor="let option of countryData" [value]="option.country_id">{{
                                    option.country_name
                                    }}</option>
                            </select>
                        </div>
                        <!-- 
                            <mat-form-field >
                                <mat-select formControlName="country" title="Country Code" placeholder="Code" class="form-control"
                                  style="width: 77px;">
                                  @for (country of countryData; track country) {
                                  <mat-option (click)="countrySelectionForState(country.country_id)"
                                    [value]="country.country_id">{{country.country_name}}
                                  </mat-option>
                                  }
                                </mat-select>
                                <mat-error style="margin-top: -1px;
                                font-size: small;
                                margin-left: 78px;
                                width: 100%;"
                                  *ngIf="{ 'is-invalid':  f['country'].errors && (f['country'].dirty || f['country'].touched )}">
                                  Country code is required
                                </mat-error>
                              </mat-form-field> -->
                        <!-- <label class="asterix" for="countrySelect">Country Code</label>
                            <mat-form-field>
                                <mat-label>Select Country</mat-label>
                                <select matNativeControl class="form-control" formControlName="country" id="countrySelect"
                                    (selectionChange)="countrySelectionForState()">
                                    <option *ngFor="let option of countryData" [value]="option.country_id">{{
                                        option.country_name
                                        }}</option>
                                </select>
                            </mat-form-field> -->
                    </div>
                    <div class="col-3 form-group">
                        <div class="custom-select">
                            <label class="asterix" *ngIf="countryInitials === 'D'"
                                for="countrySelect">{{placeHolders['LBL_REG_STATE']}}</label>
                            <label class="asterix" *ngIf="countryInitials === 'Z'" for="countrySelect">State</label>
                            <label class="asterix" *ngIf="countryInitials === 'C'"
                                for="countrySelect">{{placeHolders['LBL_REG_STATE']}}</label>
                            <label class="asterix" *ngIf="countryInitials === 'B'" for="countrySelect">State/District</label>
                            <select [attr.disabled]="isFormValid ? true : null" class="form-control"
                                formControlName="state" id="stateSelect" (change)="stateSelectionForCity()">
                                <option *ngFor="let option of stateData" [value]="option.state_code">
                                    {{option.state_name}}</option>
                            </select>
                        </div>
                        <!-- <label class="asterix" for="countrySelect">State</label>
                            <mat-form-field>
                                <mat-label>Select Country</mat-label>
                                <mat-select class="form-control" formControlName="state" id="stateSelect"
                                    (selectionChange)="stateSelectionForCity()">
                                    <mat-option *ngFor="let option of stateData" [value]="option.state_code">{{
                                        option.state_name
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                    </div>

                    <div class="col-3 form-group ">
                        <div class="custom-select">
                            <label class="asterix" *ngIf="countryInitials === 'D'"
                                for="countrySelect">{{placeHolders['LBL_REG_CITY']}}</label>
                            <label class="asterix" *ngIf="countryInitials === 'Z'" for="countrySelect">City</label>
                            <label class="asterix" *ngIf="countryInitials === 'C'"
                                for="countrySelect">{{placeHolders['LBL_REG_CITY']}}</label>
                            <label class="asterix" *ngIf="countryInitials === 'B'"
                                for="countrySelect">City/Town/Village</label>
                            <select [attr.disabled]="isFormValid ? true : null" class="form-control"
                                formControlName="city" id="citySelect">
                                <option *ngFor="let option of cityData" [value]="option.zip_id">{{
                                    option.city_name
                                    }}</option>
                            </select>
                        </div>
                        <!-- <label class="asterix" for="countrySelect">City</label>
                            <mat-form-field>
                                <mat-label>Select Country</mat-label>
                                <mat-select class="form-control" formControlName="city" id="citySelect">
                                    <mat-option *ngFor="let option of cityData" [value]="option.zip_id">{{
                                        option.city_name
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field> -->

                    </div>
                    <div *ngIf="countryInitials === 'D'" class="col-3 form-group">
                        <label for="countrySelect">Zipcode</label>
                        <input type="text" pattern="^[^\s]+$" formControlName="zipcode" class="form-control"
                            placeholder="Zipcode"
                            [ngClass]="{ 'is-invalid':  f['zipcode'].errors && (f['zipcode'].dirty || f['zipcode'].touched )}">
                        @if ( f['zipcode'].errors) {
                        <div class="invalid-feedback">
                            @if (f['zipcode'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                        </div>
                        }
                    </div>
                    <div *ngIf="countryInitials === 'Z'" class="col-3 form-group">
                        <label for="countrySelect">{{placeHolders['LBL_ZIPCODE']}}</label>
                        <input type="text" pattern="^[^\s]+$" formControlName="zipcode" class="form-control"
                            placeholder="{{placeHolders['LBL_ZIPCODE']}}"
                            [ngClass]="{ 'is-invalid':  f['zipcode'].errors && (f['zipcode'].dirty || f['zipcode'].touched )}">
                        @if ( f['zipcode'].errors) {
                        <div class="invalid-feedback">
                            @if (f['zipcode'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                        </div>
                        }
                    </div>
                    <div *ngIf="countryInitials === 'C'" class="col-3 form-group">
                        <label for="countrySelect">{{placeHolders['LBL_ZIPCODE']}}</label>
                        <input type="text" pattern="^[^\s]+$" formControlName="zipcode" class="form-control"
                            placeholder="{{placeHolders['LBL_ZIPCODE']}}"
                            [ngClass]="{ 'is-invalid':  f['zipcode'].errors && (f['zipcode'].dirty || f['zipcode'].touched )}">
                        @if ( f['zipcode'].errors) {
                        <div class="invalid-feedback">
                            @if (f['zipcode'].errors['pattern']) {
                            <div>Invalid </div>
                            }
                        </div>
                        }
                    </div>
                    <div *ngIf="countryInitials === 'B'" class="col-3 form-group">
                        <label for="countrySelect">PO Box</label>
                        <input type="text" formControlName="postbox_number" class="form-control" minlength="3"
                            maxlength="8" placeholder="Postbox number" pattern="^[0-9]*$"
                            [ngClass]="{ 'is-invalid':  f['postbox_number'].errors && (f['postbox_number'].dirty || f['postbox_number'].touched )}">
                        <small>({{placeHolders['LBL_OPTIONAL']}})</small>
                        @if ( f['postbox_number'].errors) {
                        <div class="invalid-feedback">
                            @if (f['postbox_number'].errors['pattern']) {
                            <div>Numbers only </div>
                            }
                            @if (f['postbox_number'].errors['minlength']) {
                            <div>must be at least 3 characters</div>
                            }
                            @if (f['postbox_number'].errors['maxlength']) {
                            <div>must not exceed 8 characters</div>
                            }
                        </div>
                        }
                    </div>


                </div>

                <!--Set Password-->
                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-4 form-group">
                        <div class="div-25" style="margin-top: 22px;">{{placeHolders['LBL_REG_SET_PASSWORD']}}</div>
                    </div>
                </div>
                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-4 form-group">
                        <label for="password" class="asterix">{{placeHolders['LBL_REG_PASSWORD']}}</label>
                        <input matInput [type]="showCreatePass ? 'text' : 'password'" class="form-control"
                            [readonly]="isFormValid" placeholder="{{placeHolders['LBL_PH_CREATE_PASSWORD']}}"
                            formControlName="password"
                            pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                            [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched )  }"
                            required />
                        <!-- @if (f['password'].errors) {
                            <div class="invalid-feedback">
                                @if (f['password'].errors['required']) {
                                <div>Password is required</div>
                                }
                                @if (f['password'].errors['minlength']) {
                                <div>Password must be at least 8 characters</div>
                                }
                                @if (f['password'].errors['maxlength']) {
                                <div>Password must not exceed 30 characters</div>
                                }
                                @if (f['password'].errors['pattern']) {
                                <div>
                                    Enter correct pattern
                                </div>
                                }
                            </div>
                            } -->
                    </div>
                    <mat-icon matSuffix class="col-1"
                        style="cursor:pointer;overflow: visible;margin-top: 30px;margin-left: -85px"
                        (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>
                    <div class="col-1" style="margin-left: 25px;"></div>
                    <div class="col-8 mt-4 ml-18">
                        <ul>
                            <li *ngFor="let rule of validationRules"
                                [ngClass]="{ 'met': rule.isMet, 'notmet':!rule.isMet }">
                                {{ rule.rule }}
                                <img *ngIf="rule.isMet!==false" loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e46e582-82fb-4f16-a811-9abe7d12918b?"
                                    class="img-succes-ticks" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row col-12 col-md-12 pt-3">
                    <div class="col-4 form-group">
                        <label for="password" class="asterix">{{placeHolders['LBL_REG_CONFIRM_PWD']}}</label>
                        <input matInput [type]="showConfirmPass ? 'text' : 'password'" class="form-control"
                            placeholder="{{placeHolders['LBL_PH_REENTER_PASSWORD']}}" formControlName="confirmPass"
                            [readonly]="isFormValid"
                            [ngClass]="{ 'is-invalid': !f['password'].errors && f['confirmPass'].errors && (f['confirmPass'].dirty || f['confirmPass'].touched ) }"
                            required />
                        @if ( f['confirmPass'].errors) {
                        <div class="invalid-feedback">
                            <!-- @if (f['confirmPass'].errors['required']) {
                                <div>Confirm password is required</div>
                                } -->
                            @if (f['confirmPass'].errors['matching']) {
                            <div>{{placeHolders['MSG_ERR_CONFIRM_PWD_MATCH']}}</div>
                            }
                        </div>
                        }
                        <div *ngIf="administartiveForm.errors?.['matching'] && administartiveForm.touched">
                            <p class="text-danger">{{placeHolders['MSG_ERR_PWD_NOT_MATCH']}}</p>
                        </div>
                    </div>
                    <mat-icon matSuffix class="col-1 "
                        style="cursor:pointer;overflow: visible;margin-top: 30px;margin-left: -85px"
                        (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
                </div>
                <div class="div-80">
                    <input (click)="acceptTearms()" type="checkbox" formControlName="accept" />
                    <div class="div-81">
                        <span style="color: rgba(49, 67, 69, 1)">{{placeHolders['LBL_IACCEPT']}}</span>
                        <span style="color: rgba(54, 132, 157, 1);cursor: pointer;" (click)="termsAndCondition()">
                            {{placeHolders['LBL_TermsConditions']}}
                        </span>
                        <!-- <span style="color: rgba(49, 67, 69, 1)">& </span>
                        <span style="color: rgba(54, 132, 157, 1)">Privacy policy</span> -->
                    </div>
                </div>
                <div class="text-right">
                    <button type="submit" [disabled]="!isFormValid"
                        style="cursor: pointer;float: right;font-weight: 600;" class="btn div-63"
                        (click)="save()">{{placeHolders['LBL_REG_BTN_SUBMIT']}}</button>
                    <!-- <button (click)="reset()" class="btn btn-secondary btnResetStyle">{{placeHolders['LBL_REG_BTN_RESET']}}</button> -->
                </div>


            </form>
            <!-- </div> -->

        </div>
    </div>
</div>
<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="{{placeHolders['LBL_PLEASE_WAIT']}}..."></ngx-ui-loader>