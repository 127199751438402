<div class="div-27"> 📝 Merchant Reports
</div>
<div class="report-container pt-5">
    <div class="report-card" (click)="generateReport(1)">
        <div class="report-icon">📑</div>
        <div class="report-title">Consolidated Deals for User Events</div>
        <div class="report-description">Get insights based on user interactions with deals.</div>
    </div>
    <div class="report-card" (click)="generateReport(2)">
        <div class="report-icon">📊</div>
        <div class="report-title">All Deal Details</div>
        <div class="report-description">View a complete list of all available deals.</div>
    </div>
    <div class="report-card" (click)="generateReport(3)">
        <div class="report-icon">📄</div>
        <div class="report-title">Deal-Specific Event Details</div>
        <div class="report-description">Drill down into specific deal events and activities.</div>
    </div>
</div>