


<div class="div-27 mb-3">Deal Report</div>





<div class="merchant-details-card">
  <img *ngIf="brandlogo" [src]="brandlogo" alt="Preview" class="merchant-logo" style="max-width: 150px; height: auto;">
  <div class="merchant-info">
    <p><strong>Merchant:</strong> {{userData.marketplace_name}}</p>
    <p><strong>Address:</strong> {{userData.addressLine1}},{{userData.city_name}}, {{userData.country_name}}</p>
    <p><strong>Contact:</strong> +{{userData.business_contact_number}}</p>
  </div>

  <button class="export-btn" (click)="generatePDF()">Export PDF</button>
</div>

<form [formGroup]="dashboardForm">

  <div class="div-26">

  
    <!-- <div class="div-33">
      <mat-radio-group formControlName="dateFilter">
        <mat-radio-button value="2" (change)="lastWeekData()"> {{placeHolders['Filter_last_week']}}</mat-radio-button>
        <mat-radio-button value="3" (change)="last15DaysData()"> {{placeHolders['Filter_last_15']}}</mat-radio-button>
        <mat-radio-button value="4" (change)="lastMonthData()"> {{placeHolders['Filter_last_month']}}</mat-radio-button>
        <mat-radio-button value="5" (change)="last3MonthData()"> {{placeHolders['Filter_3_Month']}}</mat-radio-button>
        <mat-radio-button value="1" (change)="customClick()"> {{placeHolders['Filter_Custom']}}
      

        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="div-17" *ngIf="datePickerVisible===true">
      <div class="div-18">
    
        <div class="div-21">
          <label style="margin-left: -65px;font-weight: 500;">{{placeHolders['Date_From']}}:</label>
          <Input type="date" min="{{ minDate }}" max="{{ todaysDate }}" (change)="setFromDate($event)"
            onkeydown="return false" placeholder="Select date" style="border: 0px solid transparent;" />
        </div>
      </div>
      <div class="div-23">
        <div class="div-24">
        
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
            class="img-9" />
        </div>
       
        <div class="div-1">
          <label style="margin-left: -30px;font-weight: 500;">{{placeHolders['Date_To']}}:</label>
          <Input type="date" min="{{ minDate }}" max="{{ todaysDate }}" (change)="setToDate($event)"
            onkeydown="return false" placeholder="Select date" style="border: 0px solid transparent;" />
        </div>
      </div>
      <div>
        <button (click)="customOkClick()" class="div-82 mt-2">{{placeHolders['btn_GO']}}</button>
      </div>
    </div> -->




    <!-- <div class="div-44 pt-1" *ngIf="data">
      <div class="div-45">
       
        <div class="col-xl-4 col-md-6">
     
          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-tile text-muted text-truncate mb-0">
                      {{placeHolders['LBL_TOTAL_DEALS']}} :</p>
                  </div>
           
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 138px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><span
                        class="counter-value">{{this.campaignTotalData?.TOTAL_DEALS}}</span></h4>
               
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-info fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2M9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5z" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
    
        </div>
    
        <div class="col-xl-4 col-md-6">
  
          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-tile text-muted text-truncate mb-0">
                      {{placeHolders['LBL_TOTAL_BUDGET']}} :</p>
                  </div>
         
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 130px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><span class="counter-value">{{currency}}
                        {{this.campaignTotalData?.TOTAL_BUDGET}}</span></h4>
         
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-success fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2m0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8" />
                        <path fill="currentColor"
                          d="M12 11c-2 0-2-.63-2-1s.7-1 2-1s1.39.64 1.4 1h2A3 3 0 0 0 13 7.12V6h-2v1.09C9 7.42 8 8.71 8 10c0 1.12.52 3 4 3c2 0 2 .68 2 1s-.62 1-2 1c-1.84 0-2-.86-2-1H8c0 .92.66 2.55 3 2.92V18h2v-1.08c2-.34 3-1.63 3-2.92c0-1.12-.52-3-4-3" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
   
        </div>
    
        <div class="col-xl-4 col-md-6">

          <div>
            <div class="card card-animate">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase text-tile fw-medium text-muted text-truncate mb-0">
                      {{placeHolders['LBL_ACTUAL_SPENT']}} :</p>
                  </div>
           
                </div>
                <div class="d-flex align-items-end justify-content-between dealtileMargin">
                  <div style="margin-left: 160px;">
                    <h4 class="fs-22 fw-semibold ff-secondary mb-4"><span class="counter-value">{{currency}}
                        {{this.campaignTotalData?.TOTAL_ACTUAL_SPENT}}</span></h4>
             
                  </div>
                  <div class="avatar-sm flex-shrink-0"><span _ngcontent-ng-c2813046590=""
                      class="avatar-title bg-danger fs-3 rounded">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M16 12h2v4h-2z" />
                        <path fill="currentColor"
                          d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2M5 5h13v2H5a1.001 1.001 0 0 1 0-2m15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15z" />
                      </svg></span></div>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </div>
    </div> -->

    <!-- 📊 Cards for Charts -->
  <!-- <div class="cards-container">
    <div class="card">
      <canvas id="dealsChart"></canvas>
    </div>
    <div class="card">
      <canvas id="budgetChart"></canvas>
    </div>
    <div class="card">
      <canvas id="statusChart"></canvas>
    </div>
  </div> -->


    <div class="filter-container pt-4">
      <label for="statusFilter">Status:</label>
      <select style="width: 25%;" class="form-control" id="statusFilter" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedStatus">
        <option value="">All</option>
        <option value="P">Pending</option>
        <option value="D">Draft</option>
        <option value="C">Completed</option>
        <option value="A">Active</option>
        <option value="E">Expired</option>
      </select>

      <label style="width: 9%;" for="dateFilter">Date From:</label>
      <input class="form-control" style="width: 25%;" type="date" id="dateFilter" [ngModelOptions]="{standalone: true}" [(ngModel)]="dateFrom">

      <label style="width: 7%;" for="dateToFilter">Date To:</label>
      <input class="form-control" style="width: 25%;" type="date" id="dateToFilter" [ngModelOptions]="{standalone: true}" [(ngModel)]="dateTo">

      <button style="width: 12%;" (click)="customOkClick()">Apply Filters</button>
      <button (click)="resetFilters()">Reset</button>
    </div>

    

    <div class="div-56">
      <div class="div-57 pt-3">{{placeHolders['LBL_ALL_DEALS']}}</div>
      <!-- <div class="div-58">
       
        <div class="div-59">
          <div class="div-60">
            <input type="search" (search)="search()" formControlName="search" autocomplete="off" maxlength="100"
              class="div-61" style="border:none; background-color: transparent ;"
              placeholder="{{placeHolders['LBL_SEARCH_PLACEHOLDER']}}" />
            
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/960e3587b102d32ae26b055144ae7805805584175a9f8744d348d759c2ad8f5e?"
              class="img-22" (click)="search()" />
          </div>
        </div>
        <div class="div-62" (click)="filterCampaign()">

          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
            <path fill="none" stroke="#4f9806" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M22 3H2l8 9.46V19l4 2v-8.54z" />
          </svg>

        </div>

      </div> -->

    </div>
    <!-- <div class="row pb-1" *ngIf="filterDataForChip">
      <span>
        <label *ngIf="filterDataForChip.status!==''">
          <b> Applied filters: </b>
        </label>
        <button (click)="clearAll()" *ngIf="filterDataForChip.status!==''" class="div-filterbtn">
          Clear all x</button>
        <label *ngIf="filterDataForChip.status!==''" class="div-chip">
          <b>Status</b>: {{filterDataForChip.status}} </label>
        <label class="div-chip" *ngIf="filterDataForChip.budget!==''">
          <b>Budget</b>: {{filterDataForChip.budget}}</label>
        <label class="div-chip" *ngIf="filterDataForChip.actualSpent!==''">
          <b>Actual spent</b>: {{filterDataForChip.actualSpent}}</label>
        <span *ngIf="filterDataForChip.category!==[]">
          @for (data of filterDataForChip.category; track data) {
          <label class="div-chip">{{data}}</label>
          }
        </span>
      </span>
    </div> -->
    <div class="div-55" *ngIf="data.length!==0">

      <ag-grid-angular style="width: 100%; " class="ag-theme-quartz" [rowData]="data" domLayout='autoHeight'
        [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs"
        [animateRows]="true" [pagination]="false" (gridReady)="onGridReady($event)">
      </ag-grid-angular>



      <!-- <div class="row">
      <div class="col-lg-8 col-md-10 ml-auto mr-auto">
        <span><strong>Current Active Page = </strong> {{activePage}}</span>
      </div>
    </div> -->





      <!-- <c-pagination >
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">Previous</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">1</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">2</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">3</a>
      </li>
      <li cPageItem>
        <a cPageLink [routerLink] style="background-color: white;color: black;">Next</a>
      </li>
    </c-pagination> -->
    </div>



    <div *ngIf="isNewUser; else noRecords">

      <div class="div-Norecord" *ngIf="data.length===0">
        <div class="div-Norecord2">
          <div class="div-Norecord3">No Record Found !!</div>
        </div>
      </div>
    </div>
    <ng-template #noRecords>
      <div class="div-Norecord" *ngIf="data.length===0">
        <div>
          <img style="height: 120px; width: 120px;" src="../../../assets/images/createcampaign.png">
          <div class="div-Norecord3" [ngClass]="createrecordtext()">{{placeHolders['LBL_NO_DEALS_CREATED']}}</div>
          <div class="div-centerlabel firsttimeRecord">
            <div class="div-createdeal" (click)="createCampaign()">{{placeHolders['LBL_CREATE_DEAL']}}</div>
            <img loading="lazy" [ngClass]="getDynamicClass()"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a173d72-6ffb-4055-8028-ba2b294272a4?"
              class="img-13" />
          </div>
        </div>
      </div>

    </ng-template>

    <div class="row mt-2">
      <div class="col-lg-12 col-md-12 ml-auto mr-auto">
        <div class="float-right">
          <app-pagination *ngIf="recordTotalCount!==0" [totalRecords]="recordTotalCount" [recordsPerPage]="10"
            [activePage]="activePage" (onPageChange)="displayActivePage($event)"></app-pagination>
        </div>
      </div>
    </div>



  </div>
</form>