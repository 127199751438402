import { Component, HostListener, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from '../register/terms-and-conditions/terms-and-conditions.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import Validation from 'src/app/utils/validation';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/translation.service';




@Component({
  selector: 'app-brandregistrationpage',
  templateUrl: './brandregistrationpage.component.html',
  styleUrl: './brandregistrationpage.component.scss'
})
export class BrandregistrationpageComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  apiPlaceHolders: any;
  toolTips:any;
  validationRules: { rule: string; isMet: boolean }[];
  selectedCountryCode: string = '';
  phoneNumber: string = '';
  countries: any[] = [];
  countryInitials: any;

  countryData: any;
  stateData: any;
  cityData: any;
  showCreatePass = false;
  showConfirmPass = false;
  resendOtpClicked = 1;
  resendEmailOtp: boolean = false;
  displayEmailTimer: boolean = false;
  verifiedEmail = false;
  disableEmail = false;
  emailOtpField = false;
  verifyEmailField = true;
  emailVerified = false;
  readonlycumstomfield = false;
  displayEmail: any;
  country: any;
  macAddress: any;
  verifiedPhone = false;
  disablePhone = false;
  phoneOtpField = false;
  verifyPhoneField = true;
  phoneVerified = false;
  readonlyPhonecumstomfield = false;
  resendOtpClickedPhone = 1;
  resendMobileOtp: boolean = false;
  displayMobileTimer: boolean = false;
  displayMobile: any;
  phoneNumberCode: any;
  countryName: any;


  // selectedCountry: FormControl = new FormControl('');

  administartiveForm: FormGroup = new FormGroup({
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    country: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
    mobileotpid:new FormControl(),
    emailotpid: new FormControl(),

    // companyName: new FormControl(''),
    // companyWebsite: new FormControl(''),
    // // companyLogo: new FormControl(''),
    // registrationId: new FormControl(''),
    buisinessPhone: new FormControl(''),
    // // document: new FormControl(''),
    // businessType: new FormControl(''),
    // // productCategories: new FormControl(''),
    // addressLine: new FormControl(''),
    // additionalInfo: new FormControl(''),
    // state: new FormControl(''),
    // city: new FormControl(''),
    // zipCode: new FormControl(''),
  });

  constructor(private fb: FormBuilder, private route: ActivatedRoute, public dialog: MatDialog, private router: Router, private service: CommonUtilService,
    private notifyService: NotificationAlertService, private confirmationDialogService: ConfirmationDialogService, private cStorage: ClientSideStorageService,
    private translationService: TranslationService,
    @Inject('COUNTRY_CONFIG') private countryConfig: any) {
    this.placeHolders = this.placeholders['Registration_page']
    this.apiPlaceHolders = this.placeholders['API_Messages']
    this.toolTips = this.placeholders['tooltip']
    // this.macAddress = Math.random();
    // let payload = {
    //   'location': null,
    //   'macAddress': this.macAddress
    // }
    // this.cStorage.set('country', JSON.stringify([]));
    // this.cStorage.set('macAddress', JSON.stringify(this.macAddress));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.countryInitials = id !== undefined ? id : 'D';
    });

    this.countryName = this.countryConfig.country;
    if (this.countryName === 'Botswana') {
      this.countryInitials = 'B'
    }

    this.createForm();

    if (this.countryInitials === 'Z') {
      this.administartiveForm.get('code')?.setValue('263');
      this.administartiveForm.get('country')?.setValue('716');
      this.administartiveForm.get('merchant_country')?.setValue('716');
      this.administartiveForm.get('businesscountrycode')?.setValue('263')
      this.countrySelectionForState();
    } else if (this.countryInitials === 'B') {
      this.administartiveForm.get('code')?.setValue('267');
      this.administartiveForm.get('country')?.setValue('72');
      this.administartiveForm.get('merchant_country')?.setValue('72');
      this.administartiveForm.get('businesscountrycode')?.setValue('267')
      // this.administartiveForm.get('code')?.disable();
      // this.administartiveForm.get('country')?.disable();
      this.countrySelectionForState();
    }
    else if (this.countryInitials === 'C') {
      this.administartiveForm.get('code')?.setValue('225');
      this.administartiveForm.get('country')?.setValue('384');
      this.administartiveForm.get('merchant_country')?.setValue('384');
      this.administartiveForm.get('businesscountrycode')?.setValue('225')
      
      // this.administartiveForm.get('code')?.disable();
      // this.administartiveForm.get('country')?.disable();
      this.countrySelectionForState();
      if (this.placeholders['locale'] !== 'fr') {
        this.translationService.changeLocale('fr');
      }
    }
    else {
      this.administartiveForm.get('code')?.setValue('');
      this.administartiveForm.get('country')?.setValue('');
      this.administartiveForm.get('merchant_country')?.setValue('');
      this.administartiveForm.get('businesscountrycode')?.setValue('')
    }

    this.validationRules = [
      { rule: this.placeHolders['LBL_PWD_RULE_8_CHARS'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_UPPER_LOWER_CASE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_NUMERICAL_ONE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_SPECIAL_CHAR'], isMet: false }
    ];

    this.administartiveForm.get('password')?.valueChanges.subscribe(value => {
      this.validatePassword(value);
    });

    this.administartiveForm.get('password')?.valueChanges.subscribe(() => {
      this.administartiveForm.updateValueAndValidity();
    });
    
    this.administartiveForm.get('confirmPass')?.valueChanges.subscribe(() => {
      this.administartiveForm.updateValueAndValidity();
    });

    this.administartiveForm.get('accept')?.valueChanges.subscribe(value => {
      this.handleCheckboxChange(value);
    });
  }

  acceptTearms() {
    if (this.emailVerified && this.phoneVerified && !this.administartiveForm.errors?.['matching']) {
      const acceptControl = this.administartiveForm.get('accept');
      acceptControl?.setValue(!acceptControl?.value);
    } else {
      const msg = this.administartiveForm.errors?.['matching'] ? 'Please check your password' :this.placeHolders['MSG_PLEASE_SUBMIT_VERIFY_EMAIL'];
      this.notifyService.showError(msg, "Error")
      const acceptControl = this.administartiveForm.get('accept');
      acceptControl?.setValue(false);
    }
  }
  isFormValid: boolean = false
  handleCheckboxChange(isChecked: boolean): void {
    if (!isChecked) {
      if (this.administartiveForm.valid) {
        this.isFormValid = isChecked
        this.administartiveForm.updateValueAndValidity();
        this.administartiveForm.get('country')?.enable();
        this.administartiveForm.get('state')?.enable();
        this.administartiveForm.get('city')?.enable();
      }

    } else {
      if (this.administartiveForm.valid) {
        this.isFormValid = isChecked
        this.administartiveForm.updateValueAndValidity();
      }

    }
  }

  // spaceTrim(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const field = control.value;
  //     if (field && !field.startsWith('  ') && !field.startsWith('    ')) {
  //       let value;
  //       value = field.trimStart();
  //       control.setValue(value);
  //     } else if (field && !field.endsWith('  ') && !field.endsWith('    ')) {
  //       let value;
  //       value = field.trimend();
  //       control.setValue(value);
  //     }
  //     return null;
  //   };
  // }

  get f(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  get code() {
    return this.administartiveForm.get('code');
  }

  get businesscountrycode() {
    return this.administartiveForm.get('businesscountrycode');
  }
  
  get phone() {
    return this.administartiveForm.get('phone');
  }

  get mail() {
    return this.administartiveForm.get('email');
  }

  areCodeAndPhoneValid(): boolean {
    const phoneControl = this.administartiveForm.get('phone');
    const codeControl = this.administartiveForm.get('code');
    phoneControl?.updateValueAndValidity();
    codeControl?.updateValueAndValidity();
    return this.f['code'].valid && this.f['phone'].valid;
  }

  isMailValid(): boolean {
    const mailControl = this.administartiveForm.get('email');
    mailControl?.updateValueAndValidity();
    return this.f['email'].valid;
  }

  createForm() {
    this.administartiveForm = this.fb.group({
      firstname: ['', [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30)]],
      lastname: ['', [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30)]],
      country: [{ value: '', disabled: this.isFormValid }, Validators.required],
      merchant_country:[],
      email: ['', [Validators.required, Validators.email]],
      emailotp: ['', [Validators.required]],
      phone: ['', Validators.required],
      code: [{ value: '', disabled: this.phoneVerified }, Validators.required],
      phoneotp: ['', [Validators.required]],
      businesscountrycode:['',],
      buisinessPhone: ['', [Validators.pattern(/^[0-9!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-]+$/)]],
      accept: ['', Validators.required],
      companyName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]],
      companyWebsite: ['', [this.websiteUrlValidator()]],
      // companyLogo: ['', Validators.required],
      registrationId: ['',],
      // digitalId: [''],
      // document: [''],
      // businessType: ['', Validators.required],
      // productCategories: ['', Validators.required],
      addressLine: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(250), Validators.pattern('^(?![0-9]+$)(?!\s+$).+')]],//Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      additionalInfo: ['', [Validators.minLength(0),
      Validators.maxLength(250), Validators.pattern('^(?![0-9]+$)(?!\s+$).+')]],
      state: [{ value: '', disabled: this.isFormValid }, Validators.required],
      city: [{ value: '', disabled: this.isFormValid }, Validators.required],
      zipcode: [''],
      postbox_number: [''],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]],
      confirmPass: ['', Validators.required],
      mobileotpid:[ ],
      emailotpid: [ ],
    },
      {
        validators: [Validations.match('password', 'confirmPass')],
      }
    );
  }

  ngOnInit() {

    
    this.route.data.subscribe((data: any) => {
      this.countryData = data.resolvedData.data
        .sort((a: any, b: any) => {
          var typeA = a.country_name.toLowerCase(),
            typeB = b.country_name.toLowerCase();
          if (typeA < typeB)
            //sort string ascending
            return -1;
          if (typeA > typeB) return 1;
          return 0; //default return value (no sorting)
        });
      // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      //   let countryRes = res.body?.data;
      //this.countryData = countryRes.sort((a:any, b:any) => a.country_name-b.country_name);


      // this.countryData = countryRes.sort((a:any, b:any) => {
      //   var typeA = a.country_name.toLowerCase(),
      //     typeB = b.country_name.toLowerCase();
      //   if (typeA < typeB)
      //     //sort string ascending
      //     return -1;
      //   if (typeA > typeB) return 1;
      //   return 0; //default return value (no sorting)
      // });



    });
    // this.service.getCountry(this.country,this.macAddress).subscribe((res: any) => {
    //   this.countryData = res.body?.data;
    // });

    this.checkLastnameValidity();

    // Monitor validation state
    this.administartiveForm.get('lastname')?.statusChanges.subscribe(() => {
      this.checkLastnameValidity();
    });

  }

  checkLastnameValidity(): void {
    const lastnameControl = this.administartiveForm.get('lastname');
    lastnameControl?.markAsTouched();
  }

  save() {
    debugger
    let payload = {
      "password": this.administartiveForm.value.password,
      "firstName": this.administartiveForm.value.firstname,
      "lastName": this.administartiveForm.value.lastname,
      "email_id": this.administartiveForm.value.email.toLowerCase(),
      "mobile": this.administartiveForm.value.code + this.administartiveForm.value.phone.toString(),
      "marketplace_name": this.administartiveForm.value.companyName,
      "roleId": 1,
      "mobileOtpId":this.administartiveForm.value.mobileotpid,
		  "emailOtpId":this.administartiveForm.value.emailotpid
    }

    let adminData = {
      "Name": this.administartiveForm.value.firstName + ' ' + this.administartiveForm.value.lastName,
      "email_id": this.administartiveForm.value.email.toLowerCase(),
      "mobile": this.administartiveForm.value.code + this.administartiveForm.value.phone,//this.phoneNumberCode +
    }

    let data = {
      "marketplace_name": this.administartiveForm.value.companyName,
      "marketplace_link": this.administartiveForm.value.companyWebsite,
      "registration_id": this.administartiveForm.value.registrationId,
      "business_contact_number": this.administartiveForm.value.businesscountrycode + this.administartiveForm.value.buisinessPhone,
      "addressLine1": this.administartiveForm.value.addressLine,
      "addressLine2": this.administartiveForm.value.additionalInfo,
      "country": this.administartiveForm.value.country,
      "merchant_country": this.administartiveForm.value.merchant_country,
      "state": this.administartiveForm.value.state,
      "city": this.administartiveForm.value.city,
      "postbox_number": this.administartiveForm.value.postbox_number,
      "zipcode": this.administartiveForm.value.zipcode,
      "termsAndConditions": this.administartiveForm.value.accept
    }

    // let summaryData = {
    //   "firstName": this.administartiveForm.value.firstname,
    //   "lastName": this.administartiveForm.value.lastname,
    //   "email_id": this.administartiveForm.value.email,
    //   "mobile": this.administartiveForm.value.code + this.administartiveForm.value.phone.toString(),
    //   "marketplace_name": this.administartiveForm.value.companyName,
    //   "marketplace_link": this.administartiveForm.value.companyWebsite,
    //   "registration_id": this.administartiveForm.value.registrationId,
    //   "business_contact_number": this.administartiveForm.value.buisinessPhone,
    //   "addressLine1": this.administartiveForm.value.addressLine,
    //   "addressLine2": this.administartiveForm.value.additionalInfo,
    //   "country": this.administartiveForm.value.country,
    //   "state": this.administartiveForm.value.state,
    //   "city": this.administartiveForm.value.city,
    //   "postbox_number": this.administartiveForm.value.postbox_number
    // }

    this.confirmationDialogService
      .confirm(this.placeHolders['LBL_CONFIRMATION'], " ", payload)
      .then((confirmed) => {
        if (confirmed) {
          const userInput = payload
          let firstApiResponseData: any;
          this.service.adminSignup(userInput, this.country, this.macAddress).pipe(
            switchMap((firstApiResponse: any) => {
              const otherData = firstApiResponse.body.data.personId;
              firstApiResponseData = {
                firstName: firstApiResponse.body.data.firstName,
                lastName: firstApiResponse.body.data.lastName,
                email_id: firstApiResponse.body.data.email_id,
                mobile: firstApiResponse.body.data.mobile,
              };
              return this.service.registerCompany(otherData, data, this.country, this.macAddress);
            })
          ).subscribe((finalApiResponse: any) => {
            console.log('Final API response:', finalApiResponse);
            // Handle the final response here
            let summaryData = {
              "firstName": firstApiResponseData.firstName,
              "lastName": firstApiResponseData.lastName,
              "email_id": firstApiResponseData.email_id,
              "mobile": firstApiResponseData.mobile,
              "marketplace_name": finalApiResponse.body.data.marketplace_name,
              "marketplace_link": finalApiResponse.body.data.marketplace_link,
              "registration_id": finalApiResponse.body.data.registration_id,
              "business_contact_number": finalApiResponse.body.data.business_contact_number,
              "addressLine1": finalApiResponse.body.data.addressLine1,
              "addressLine2": finalApiResponse.body.data.addressLine2,
              "country": finalApiResponse.body.data.country,
              "merchant_country": finalApiResponse.body.data.country.merchant_country,
              "state": finalApiResponse.body.data.state,
              "city": finalApiResponse.body.data.city,
              "zipcode": finalApiResponse.body.data.zipcode,
              "postbox_number": finalApiResponse.body.data.postbox_number,
              "country_name": finalApiResponse.body.data.country_name,
              "state_name": finalApiResponse.body.data.state_name,
              "city_name": finalApiResponse.body.data.city_name
            }
            this.service.setRegistrationData(summaryData);
            this.router.navigate(["summary"], { queryParams: { id: this.countryInitials } });
          });




          // this.service.adminSignup(payload).subscribe((res: any) => {
          //   if (res.body?.status === 'Error') {
          //     this.notifyService.showError(
          //       res.body?.message,
          //       res.body?.status
          //     );
          //   } else {
          //     const data = {
          //       emailId: this.administartiveForm.value.email,
          //       password: this.administartiveForm.value.password
          //     }
          //     this.notifyService.showSuccess(
          //       "User Registered Successfully",
          //       res.body?.status
          //     );
          //     this.service.setAdminData(adminData);
          //     this.service.setLoginData(data);
          //     this.cStorage.set('userId', JSON.stringify(res.body?.data.personId));
          //     this.cStorage.set('userName', JSON.stringify(res.body?.data.email_id));
          //     this.cStorage.set('roleId', JSON.stringify(res.body?.data.roleId));
          //     // this.router.navigate(["/register"]);
          //   }

          // });
        }
      });


    // this.router.navigate(["register/summary"]);  


  }

  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url = control.value;
      if (url && !url.startsWith(' ') && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        url = '';
        control.setValue('https://' + url);
      }
      return null;
    };
  }

  termsAndCondition() {
    this.dialog.open(TermsAndConditionsComponent, {
      height: '500px',
      width: '600px',
      position: {
        top: '5vh',
        // left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
  }


  countrySelectionForState() {
    this.administartiveForm.controls["state"].reset();
    this.administartiveForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    const selectedCountryCode = this.administartiveForm.get('merchant_country')?.value;
    this.stateData = [];
    this.cityData = [];
    this.service.getState(selectedCountryCode, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res.body?.data;
    });
  }

  stateSelectionForCity() {
    this.administartiveForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.cityData = [];

    const selectedStateCode = this.administartiveForm.get('state')?.value;
    const selectedCountryCode = this.administartiveForm.get('merchant_country')?.value;
    this.service.getCity(selectedCountryCode, selectedStateCode, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.body?.data;
    });
  }

  citySelectionForZip(zip_code: any, zip_id: any) {
    // this.registerForm.controls["zipCode"].reset();
    // this.zipCode = zip_code;
    // this.zipID = zip_id;
  }

  validatePassword(value: string): void {
    // Reset all validation rules to false initially
    this.validationRules.forEach(rule => {
      rule.isMet = false;
    });

    // Check each validation rule and update its status
    if (value?.length >= 8 && value?.length <= 30) {
      this.validationRules[0].isMet = true;
    }
    if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
      this.validationRules[1].isMet = true;
    }
    if (/[#?!@$%^&*-]/.test(value)) {
      this.validationRules[3].isMet = true;
    }
    if (/\d/.test(value)) {
      this.validationRules[2].isMet = true;
    }
  }


  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  sendEmailOtp() {
    let status: any = this.administartiveForm.controls['email'].status;
    if (status !== 'INVALID') {
      let payload = {
        email: this.administartiveForm.value.email
      }
      this.service.sendEmailOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res.body?.status === 'ERROR' || res.body?.status === 'Error') {
          this.notifyService.showError(
            this.apiPlaceHolders[res.body?.message],
            res.body?.status
          );
        } else {
          this.emailOtpField = true;
          this.verifyEmailField = false;
          this.startEmailTimer(3);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Email!!",
        "Error"
      );
    }
  }

  resendEmailOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 3) {
      this.sendEmailOtp();
    } else {
      this.notifyService.showError(this.placeHolders['MSG_RESEND_LIMIT_EXCEED'], "Error")
    }
  }

  timerInterval: any;
  startEmailTimer(minute: any) {
    this.displayEmailTimer = true;
    this.resendEmailOtp = false;
    this.readonlycumstomfield = true;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayEmail = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(this.timerInterval);
        this.resendEmailOtp = true;
        this.displayEmailTimer = false;
      }
    }, 1000);
  }

  validateOtpEmail() {
    let status: any = this.administartiveForm.controls['emailotp'].status;
    if (status !== 'INVALID') {
      let payload = {
        "email": this.administartiveForm.value.email,
        "type": "email",
        "validationCode": this.administartiveForm.value.emailotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'ERROR' || res.body?.status === 'Error') {
          this.notifyService.showError(
            this.placeHolders['MSG_ERR_EMAIL_OTP_NOT_MATCH'],
            res.body?.status
          );
        } else {
          debugger


          this.verifiedEmail = true;
          this.notifyService.showSuccess(
            this.placeHolders['MSG_ERR_EMAIL_VERFIED'],
            "Success"
          );
          this.verifyEmailField = true;
          this.disableEmail = true;
          this.emailVerified = true;
          this.readonlycumstomfield = true;
          this.administartiveForm.controls['emailotpid'].setValue(res.body.data.otp_id);
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }


  onOtpEmailChange(event: any) {
    // let res = event;
    // this.administartiveForm.value.emailotp = res;
    let res = event;
    this.administartiveForm.get('emailotp')?.setValue(res);
  }

  // countrySelectionFromCode(){

  //   const selectedCountryCode = this.administartiveForm.get('code')?.value;
  //   const selectedCountry = this.countryData.find((country:any) => country.phone_country_prefix === selectedCountryCode);
  //   this.selectedCountry.setValue(selectedCountry.country_id);
  //   this.administartiveForm = this.fb.group({
  //     country: this.selectedCountry,
  //   });

  //   this.service.getState(selectedCountry.country_id).subscribe((res: any) => {
  //     this.stateData = res.data;
  //   });

  // }


  sendMobileOtp() {
    let status: any = this.administartiveForm.controls['phone'].status;
    if (status !== 'INVALID') {
      // let countryCode = this.phoneNumberCode;
      let payload = {

        mobileNumber: this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.phone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res && (res.body?.status === 'ERROR' || res.body?.status === 'Error')) {
          this.notifyService.showError(
            res?.body?.message,
            res?.body?.status
          );
        } else {
          this.phoneOtpField = true;
          this.verifyPhoneField = false;
          this.startMobileTimer(3);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Phone Number !!",
        "Error"
      );
    }
  }

  resendMobileOTP() {
    let clickedCOunter = this.resendOtpClickedPhone++;
    if (clickedCOunter <= 3) {
      this.sendMobileOtp();
    } else {
      this.notifyService.showError(this.placeHolders['MSG_RESEND_LIMIT_EXCEED'], "Error")
    }
  }

  MobiletimerInterval: any;
  startMobileTimer(minute: any) {
    this.displayMobileTimer = true;
    this.resendMobileOtp = false;
    this.readonlyPhonecumstomfield = true;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    this.MobiletimerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayMobile = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(this.MobiletimerInterval);
        this.resendMobileOtp = true;
        this.displayMobileTimer = false;
      }
    }, 1000);
  }

  onOtpPhoneChange(event: any) {
    // let res = event;
    // this.administartiveForm.value.phoneotp = res;
    let res = event;
    this.administartiveForm.get('phoneotp')?.setValue(res);
  }

  validateOtpPhone() {
    let status: any = this.administartiveForm.controls['phoneotp'].status;
    if (status !== 'INVALID') {

      let mobileNumber = this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.phone.toString()
      let payload = {
        "phoneNumber": mobileNumber,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.phoneotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'ERROR' || res.body?.status === 'Error') {
          this.notifyService.showError(
            this.placeHolders['MSG_ERR_PHONE_OTP_NOT_MATCH'],
            res.body?.status
          );
        } else {
          this.verifiedPhone = true;
          this.notifyService.showSuccess(
            this.placeHolders['MSG_ERR_PHONE_VERIFIED'],
            "Success"
          );
          this.verifyPhoneField = true;
          this.disablePhone = true;
          this.phoneVerified = true;
          this.readonlyPhonecumstomfield = true;
          this.administartiveForm.controls['mobileotpid'].setValue(res.body.data.otp_id);
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }
  textInput: string = '';

  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is the spacebar (keyCode 32 or code "Space")
    if (event.keyCode === 32 || event.code === 'Space' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior (spacebar input)
    }
  }

  reset() {
    this.verifiedPhone = false;
    this.disablePhone = false;
    this.phoneOtpField = false;
    this.verifyPhoneField = true;
    this.phoneVerified = false;
    this.readonlyPhonecumstomfield = false;
    this.resendOtpClickedPhone = 1;
    this.resendMobileOtp = false;
    this.displayMobileTimer = false;

    this.showCreatePass = false;
    this.showConfirmPass = false;
    this.resendOtpClicked = 1;
    this.resendEmailOtp = false;
    this.displayEmailTimer = false;
    this.verifiedEmail = false;
    this.disableEmail = false;
    this.emailOtpField = false;
    this.verifyEmailField = true;
    this.emailVerified = false;
    this.readonlycumstomfield = false;

    this.isFormValid = false;

    this.ngOnDestroy();
    this.displayEmailTimer = false;
    this.displayMobileTimer = false;

    this.administartiveForm.controls["firstname"].reset();
    this.administartiveForm.controls["lastname"].reset();
    this.administartiveForm.controls["emailotp"].reset();
    this.administartiveForm.controls["phoneotp"].reset();
    this.administartiveForm.controls["buisinessPhone"].reset();
    this.administartiveForm.controls["accept"].reset();
    this.administartiveForm.controls["companyName"].reset();

    this.administartiveForm.controls["companyWebsite"].reset();
    this.administartiveForm.controls["registrationId"].reset();
    this.administartiveForm.controls["addressLine"].reset();
    this.administartiveForm.controls["additionalInfo"].reset();
    this.administartiveForm.controls["zipcode"].reset();
    this.administartiveForm.controls["password"].reset();
    this.administartiveForm.controls["confirmPass"].reset();



    this.administartiveForm.get('phone')?.reset();
    this.administartiveForm.get('email')?.reset();

    this.administartiveForm.controls["state"].reset();
    this.administartiveForm.controls["city"].reset();
    this.stateData = [];
    this.cityData = [];

    this.countrySelectionForState();

  }

  resetEmail() {
    clearInterval(this.timerInterval);
    this.resendOtpClicked = 1;
    this.resendEmailOtp = false;
    this.displayEmailTimer = false;
    this.verifiedEmail = false;
    this.disableEmail = false;
    this.emailOtpField = false;
    this.verifyEmailField = true;
    this.emailVerified = false;
    this.readonlycumstomfield = false;
    this.administartiveForm.controls["emailotp"].reset();
    this.administartiveForm.get('email')?.reset();
  }

  resetPhone() {
    clearInterval(this.MobiletimerInterval);
    this.verifiedPhone = false;
    this.disablePhone = false;
    this.phoneOtpField = false;
    this.verifyPhoneField = true;
    this.phoneVerified = false;
    this.readonlyPhonecumstomfield = false;
    this.resendOtpClickedPhone = 1;
    this.resendMobileOtp = false;
    this.displayMobileTimer = false;
    this.administartiveForm.controls["phoneotp"].reset();
    this.administartiveForm.get('phone')?.reset();
  }



  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    clearInterval(this.MobiletimerInterval);
  }
}

 class Validations {
  static match(...controlNames: string[]): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (!formGroup || !formGroup.get) {
        return null; // Return null if the form group is invalid
      }

      const controls = controlNames.map(name => formGroup.get(name));
      const values = controls.map(control => control?.value);

      // Check if all controls exist
      if (controls.includes(null)) {
        return null;
      }

      const allMatch = values.every(value => value === values[0]);

      // Set errors for mismatched controls
      controls.forEach(control => {
        if (control) {
          if (!allMatch) {
            control.setErrors({ matching: true });
          } else if (control.hasError('matching')) {
            control.setErrors(null); // Clear only 'mismatch' error
          }
        }
      });

      return allMatch ? null : { matching: true };
    };
  }
}
