<div class="div">
  <div class="div-2">
    <!-- <div class="div-3">
      <img loading="lazy" style="cursor: pointer;" routerLink="/login" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a47714be-3217-4ffe-a745-a8e5e12fe2a1?"
        class="img-2" />
    </div> -->
    <div class="div-4">
      <div class="div-5">
      </div>
    </div>
  </div>
  <form [formGroup]="registerForm">
    <div class="div-11">
      <!-- <div class="div-12" style="color: #498208;">Congratulations !</div> -->
      <div class="div-13 mt-2" style="color: #498208;font-size: x-large; line-height: 1.2em;">
        <b>{{placeHolders['LBL_CONGRATULATION_ON_REGISTRATION']}} {{ companyName}} !</b>
        <br />
      </div>
      <div class="div-13 mb-4">{{placeHolders['LBL_VERIFICATION_PROCESS']}}
      </div>
      <!-- <div class="div-14">
        <div class="div-15">Company Details</div>
        <div class="div-16">
          Brand/ Retailer/ Businesses/ Government/ Others
        </div>
        <div class="div-17">
          <div class="div-18"></div>
        </div>
      </div> -->
      <div class="div-19 ">

        <!-- 
        <div class="container card mt-1 p-5 pt-3">
          

            <div class="row col-12">
              <div class="col-6 form-group">
                <label class="label-Fonts" for="CompanyName">Company name :</label>
                <input class="col-12" type="text" readonly="true" formControlName="companyName" />
              </div>
              <div class="col-6 form-group">
                <label for="companyWebsite" class="label-Fonts" style="margin-left: 6px;">Company website :</label>
                <input class="col-12" type="text" readonly="true" formControlName="companyWebsite" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label for="businessType" class="label-Fonts" style=""> Business type :</label>
                <input class="col-12" type="text" readonly="true" formControlName="businessType" />
              </div>

              <div class="col-6 form-group">
                <label for="registrationId" class="label-Fonts" style="margin-left: 6px;">Registration
                  id :</label>
                <input class="col-12" type="text" readonly="true" formControlName="registrationId" />
              </div>

            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label class="label-Fonts" for="Productcategories">Product categories :</label>
                <input class="col-12" type="text" readonly="true" formControlName="productCategories" />
              </div>
            </div>
           
            </div> 
            <div class="row col-12 pt-3">
              <div class="col-12 form-group">
                <label class="label-Fonts" for="CompanyName">Address line :</label>
                <input class="col-12" type="text" readonly="true" formControlName="addressLine" />
              </div>
           
            </div>
            <div class="row col-12 pt-3">              
              <div class="col-12 form-group">
                <label class="label-Fonts" for="additionalInfo">Additional information :</label>
                <input class="col-12" type="text" readonly="true" formControlName="additionalInfo" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label class="label-Fonts" for="CompanyName">Country :</label>
                <input class="col-12" type="text" readonly="true" formControlName="country" />
              </div>
              <div class="col-6 form-group">
                <label class="label-Fonts" for="CompanyName">State :</label>
                <input class="col-12" type="text" readonly="true" formControlName="state" />
              </div>
            </div>

            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label class="label-Fonts" for="additionalInfo">City :</label>
                <input class="col-12" type="text" readonly="true" formControlName="city" />
              </div>
            </div>

         
        </div>
 -->

        <!--CSS Applied Code Starts Below-->

        <ng-container *ngIf="isMobile; else desktop">
          <div class="container mt-1">
            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="adminFirstName">{{placeHolders['LBL_FIRST_NAME']}}:</label>
                <input class="form-control" type="text" readonly formControlName="adminFirstName" />
              </div>
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="adminLastName">{{placeHolders['LBL_LAST_NAME']}}:</label>
                <input class="form-control" type="text" readonly formControlName="adminLastName" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="email">{{placeHolders['LBL_REG_PRIMARY_EMAIL']}}:</label>
                <input class="form-control" type="text" readonly formControlName="email" />
              </div>
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="phoneNo">{{placeHolders['LBL_REG_MOBILE_NUMBER']}}:</label>
                <input class="form-control" type="text" readonly formControlName="phoneNo" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="companyName">{{placeHolders['LBL_REG_BUSINESS_NAME']}}:</label>
                <input class="form-control" type="text" readonly formControlName="companyName" />
              </div>
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="companyWebsite">{{placeHolders['LBL_REG_BUSINESS_WEBSITE']}}:</label>
                <input class="form-control" type="text" readonly formControlName="companyWebsite" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="registrationId">{{placeHolders['LBL_REG_REGISTRATION_ID']}}:</label>
                <input class="form-control" type="text" readonly formControlName="registrationId" />
              </div>
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="businesscontactNo">{{placeHolders['LBL_REG_BUSINESS_PHONE']}}:</label>
                <input class="form-control" type="text" readonly formControlName="businesscontactNo" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 form-group">
                <label class="label-Fonts" for="addressLine">{{placeHolders['LBL_REG_ADDRESS_LINE']}}:</label>
                <input class="form-control" type="text" readonly formControlName="addressLine" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 form-group">
                <label class="label-Fonts" for="additionalInfo">{{placeHolders['LBL_REG_ADDITIONAL_INFO']}}:</label>
                <input class="form-control" type="text" readonly formControlName="additionalInfo" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" for="country">{{placeHolders['LBL_REG_COUNTRY']}}:</label>
                <input class="form-control" type="text" readonly formControlName="country" />
              </div>
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" *ngIf="countryInitials === 'D'" for="state">{{placeHolders['LBL_REG_STATE']}}:</label>
                <label  class="label-Fonts"*ngIf="countryInitials === 'B'" for="state">District:</label>
                <input class="form-control" type="text" readonly formControlName="state" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6 form-group">
                <label class="label-Fonts" *ngIf="countryInitials === 'D'" for="city">{{placeHolders['LBL_REG_CITY']}}:</label>
                <label class="label-Fonts" *ngIf="countryInitials === 'B'"
                                for="city">City/Town/Village:</label>
                <input class="form-control" type="text" readonly formControlName="city" />
              </div>
            </div>
          </div>

        </ng-container>

        <ng-template #desktop>
          <div class="container  mt-1 p-2 pt-1">

            <div class="row col-12">
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_FIRST_NAME']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="adminFirstName" />
              </div>
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_LAST_NAME']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="adminLastName" />
              </div>
            </div>

            <div class="row col-12 pt-3">
              <div class="col-6 form-group d-flex align-items-center">
                <label for="companyWebsite" class="label-Fonts">{{placeHolders['LBL_REG_PRIMARY_EMAIL']}}:</label>
                <input class="col-7 ml-2" type="text" readonly="true" formControlName="email" />
              </div>
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_REG_MOBILE_NUMBER']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="phoneNo" />
              </div>
            </div>


            <div class="row col-12 pt-3">
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_REG_BUSINESS_NAME']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="companyName" />
              </div>
              <div class="col-6 form-group d-flex align-items-center">
                <label for="companyWebsite" class="label-Fonts">{{placeHolders['LBL_REG_BUSINESS_WEBSITE']}}:</label>
                <input class="col-7 ml-2" type="text" readonly="true" formControlName="companyWebsite" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group d-flex align-items-center">
                <label for="registrationId" class="label-Fonts">{{placeHolders['LBL_REG_REGISTRATION_ID']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="registrationId" />
              </div>

              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_REG_BUSINESS_PHONE']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="businesscontactNo" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-12 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_REG_ADDRESS_LINE']}}:</label>
                <input class="col-10 ml-2" type="text" readonly="true" formControlName="addressLine" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-12 form-group d-flex align-items-center">
                <label class="label-Fonts" for="additionalInfo">{{placeHolders['LBL_REG_ADDITIONAL_INFO']}}:</label>
                <input class="col-10 ml-2" type="text" readonly="true" formControlName="additionalInfo" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" for="CompanyName">{{placeHolders['LBL_REG_COUNTRY']}}:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="country" />
              </div>
              <div class="col-6 form-group d-flex align-items-center">
                <label class="label-Fonts" *ngIf="countryInitials === 'D'" for="CompanyName">{{placeHolders['LBL_REG_STATE']}} :</label>
                <label  class="label-Fonts" *ngIf="countryInitials === 'B'" for="state">District:</label>
                <input class="col-6" type="text" readonly="true" formControlName="state" />
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group d-flex align-items-center">
                <label *ngIf="countryInitials === 'D'" class="label-Fonts" for="additionalInfo">{{placeHolders['LBL_REG_CITY']}}:</label>
                <label *ngIf="countryInitials === 'B'" class="label-Fonts" 
                                for="city">City/Town/Village:</label>
                <input class="col-6 ml-2" type="text" readonly="true" formControlName="city" />
              </div>
            </div>

          </div>
        </ng-template>


        <!-- <div class="circle-progress-bar ">
          <progress value="progressPercentage" min="0" max="100" style="visibility:hidden;height:0;width:0;">75%</progress>
        </div> -->

        <!-- <div class="progress-bar-container">
          <div class="circle-progress-bar html">
            
          </div>
        </div> -->

      </div>
    </div>
    <div style="padding: 5px;">
      <button type="submit" (click)="login()" style="cursor: pointer;float: right;font-weight: 600;margin-right: 85px;margin-bottom: 5px;"
        class="btn div-63">{{placeHolders['BTN_EXIT']}}</button>
        <!-- To be Changed Continue To Login -->
    </div>
  </form>

  <!-- To be Changed-->
  <!-- <div class="progress">

    <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
      [style.width.%]="progressPercentage" [attr.aria-valuenow]="progressPercentage" aria-valuemin="0"
      aria-valuemax="100">Redirecting to Login in
      {{ timerValue }}Sec
    </div>
  </div> -->
  <div style="display: flex; justify-content: center;padding:5px">Confirmation email has been sent. (Please check your spam folder also)</div>
</div>
<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="Please Wait..."></ngx-ui-loader>