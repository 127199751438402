
export const botswanaConfig = {
    logoUrl: '../assets/images/poso_click.png',
    postlogo:'../../../assets/images/BPLOGO.png',
    language: 'en',
    currency: 'P',
    shortLogo:'assets/images/botswana.png',
    country:'Botswana',
    favicon:'assets/botswana_favicon.png',
    isPayment:false,
    isMsgCharge:false,
    loginPostlogo:'assets/images/posoClik.jpeg',
    loginCountrylogo:'assets/images/botswanalogo.png',
    timezone:'Africa/Gaborone'
  };