import { Component } from '@angular/core';

interface Question {
  question: string;
  answer: string;
  show?: boolean;
}

interface FAQ {
  category: string;
  questions: Question[];
}
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FaqsComponent {
  searchQuery: string = '';
  showFAQAccordion = true;
  faqs: FAQ[] = [
    {
      category: 'General Information',
      questions: [
        {
          question: "What is Ship2MyID?",
          answer: "Ship2MyID is a digital platform connecting brands, retailers, and consumers through a web-based portal. It allows merchants to showcase their products and services, offering a seamless shopping experience for consumers."
        },
        {
          question: "What types of products or services can I use Ship2MyID for?",
          answer: "Ship2MyID is a versatile platform that supports a wide array of products and services across multiple industries. Whether your business operates in retail, hospitality, or another sector, Ship2MyID is designed to help you efficiently manage and optimize your conversion process. The platform's flexibility allows you to customize your offerings to meet the specific needs of your customers, ensuring a seamless and effective user experience."
        },
        {
          question: "Is Ship2MyID available internationally?",
          answer: "Yes, Ship2MyID operates in multiple countries, providing a global platform for businesses to showcase their products and connect with a worldwide audience."
        },
        {
          question: "How does Ship2MyID ensure data privacy and security?",
          answer: "Ship2MyID uses robust encryption, strict access controls, and regular security audits to ensure data privacy and security. All transactions are protected through advanced security measures."
        },
        {
          question: "Are there any fees associated with using Ship2MyID?",
          answer: "Ship2MyID offers flexible pricing plans, including subscription-based models or transaction-based fees. The exact fees vary based on the selected features and services."
        },
        {
          question: "Can I integrate my existing e-commerce platform with Ship2MyID?",
          answer: "Yes, Ship2MyID seamlessly integrates with existing e-commerce platforms, enabling smooth synchronization of inventory, orders, and customer data across multiple channels."
        }
      ]
    },
    {
      category: 'Getting Started',
      questions: [
        {
          question: "How can my business sign up for Ship2MyID?",
          answer: "Businesses can sign up by visiting the official website https://appadmin.ship2myid.com/#/ or contacting the support team. The sign-up process involves providing basic information about the business and agreeing to the platform's terms and conditions."
        },
        {
          question: "What are the eligibility criteria for joining Ship2MyID?",
          answer: "Businesses must adhere to the legal and regulatory requirements specific to their country or region, comply with our platform policies, and validate their business credentials. Our partners are expected to rigorously follow these regulations to join and operate on Ship2MyID."
        },
        {
          question: "What business categories can't register with Ship2MyID?",
          answer: "Registration with Ship2MyID is subject to both our internal policies and the governmental regulations of the county. Specifically, businesses that fall under restricted categories, as defined by our platform's guidelines and local government policies, are not eligible for registration. These restrictions are in place to ensure compliance with legal standards and to maintain the integrity and security of the platform. For a detailed list of restricted business categories, please refer to our policy documentation or contact our support team for further clarification. https://pdf.ac/3oy6PP"
        },
        {
          question: "Is there a verification process for businesses before they can start using the platform?",
          answer: "Yes, Ship2MyID employs a verification process to authenticate the identity and legitimacy of businesses. This may involve submitting relevant documents or undergoing background checks."
        }
      ]
    },
    {
      category: 'Registration Process',
      questions: [
        {
          question: "What information is required during the registration process?",
          answer: "Businesses must provide business entity details and contact details. Supporting documentation such as licenses and certificates may also be required."
        },
        {
          question: "How long does the registration process take?",
          answer: "The registration process typically takes up to three working days following the submission of all necessary information by the business entity."
        },
        {
          question: "Can I customize my business profile on Ship2MyID?",
          answer: "Yes, businesses can customize their profiles with logos, product images, and descriptive text to create a unique storefront."
        }
      ]
    },
    {
      "category": "Platform Features and Functionality",
      "questions": [
        {
          question: "What support does Ship2MyID offer to businesses?",
          answer: "Ship2MyID offers businesses the capability of creating deals, running campaigns, a list of ready-to-buy consumers in real time, supportive customer support, training material, initial support for creating campaigns, and insights."
        },
        {
          question: "Can businesses run promotional campaigns through the platform?",
          answer: "Yes, businesses can launch promotional campaigns, offer discounts, implement re-targeting strategies, and create special offers on Ship2MyID. The platform includes robust tools for designing and managing these promotions, along with capabilities for tracking performance and analyzing their effectiveness."
        },
        {
          question: "How can businesses track the performance of their products on the platform?",
          answer: "The platform provides comprehensive insights and reporting tools to monitor sales, website traffic, conversion rates, and customer engagement. These analytics empower businesses to refine their marketing strategies and make informed decisions for enhanced performance."
        },
        {
          question: "Can businesses manage inventory and orders through the platform?",
          answer: "No, Ship2MyID does not offer those features."
        },
        {
          question: "Is there a customer feedback and review system on the platform?",
          answer: "No, the platform does not currently include a customer feedback and review system. We are constantly exploring new features to enhance our services and will consider adding review capabilities in the future."
        }
      ]
    },
    {
      "category": "Payments and Transactions",
      "questions": [
        {
          question: "What payment options are available for customers?",
          answer: "The platform supports multiple payment options, including credit and debit cards, digital wallets, bank transfers, and UPI (where available)."
        }
      ]
    },
    {
      "category": "Support and Assistance",
      "questions": [
        {
          "question": "What support options are available for businesses using the platform?",
          "answer": "The platform offers online help resources, knowledge base articles, email support, and dedicated customer service representatives. Training materials, webinars, and community forums are also available."
        },
        {
          "question": "How can businesses report technical issues or request assistance?",
          "answer": "Businesses can report technical issues or request assistance by contacting the support team through email, live chat, or submitting a support ticket."
        },
        {
          "question": "Is there a dedicated account manager for businesses using the platform?",
          "answer": "Yes, each business is assigned a dedicated account manager for personalized support and assistance."
        }
      ]
    },
    {
      "category": "Security and Compliance",
      "questions": [
        {
          "question": "How does the platform ensure data security and compliance?",
          "answer": "The platform adheres to strict data security protocols and compliance standards, including encryption, access controls, and regular security audits."
        },
        {
          "question": "Are businesses required to comply with specific regulations when using the platform?",
          "answer": "Yes, businesses must comply with regulations governing e-commerce, data protection, consumer rights, and advertising standards. The platform provides guidance to help businesses adhere to these requirements."
        }
      ]
    },
    {
      "category": "Account Management and Termination",
      "questions": [
        {
          question: "Can businesses deactivate or terminate their accounts on the platform?",
          answer: "Yes, businesses can deactivate or terminate their accounts by contacting the support team and following the account closure process. Certain terms and conditions may apply."
        },
        {
          question: "Can a business join back after deactivation?",
          answer: "Once an account is deactivated, the business must complete the registration process from the beginning to reactivate their account. This involves providing the necessary information and going through the initial setup steps as if registering for the first time. This ensures that all details are up-to-date and that the account is properly configured for continued use."
        },
        {
          question: "What happens to data and content upon account termination?",
          answer: "Upon termination, the platform securely deletes or anonymizes all associated data and content in accordance with data retention policies and regulatory requirements. Businesses can request data export or deletion as needed."
        }
      ]
    },
    {
      "category": "Marketing and Promotion",
      "questions": [
        {
          question: "How can businesses utilize the platform for marketing and promotion?",
          answer: "Businesses can leverage the platform's robust promotional tools to craft highly targeted marketing campaigns, design customized discounts, and develop exclusive offers. These features are designed to help you effectively attract and engage customers, driving increased visibility and growth for your business."
        },
        {
          question: "Does the platform provide insights into marketing campaign performance?",
          answer: "Yes, the platform offers insights and reporting tools to track the performance of marketing campaigns, helping businesses optimize their strategies."
        }
      ]
    },
    {
      "category": "Customer Engagement",
      "questions": [
        {
          question: "How can businesses engage with consumers through the Ship2MyID platform?",
          answer: "Yes, you can engage with customers even if you don’t have their personally identifiable information (PII). Businesses can use the Ship2MyID platform to create personalized promotional offers. The platform provides tools for designing targeted marketing campaigns, customized discounts, and special offers based on consumer preferences and behaviors. This allows businesses to connect with their audience more effectively, enhance customer experiences, and foster greater engagement and loyalty."
        },
        {
          question: "Is there a loyalty program feature on the platform?",
          answer: "Currently, we do not offer a loyalty program feature. However, we plan to introduce this in the near future to help our merchants reward customers and encourage repeat business."
        }
      ]
    },
    {
      "category": "Reporting and Analytics",
      "questions": [
        {
          question: "What types of reports are available through the platform?",
          answer: "The platform offers various reports, including sales reports, traffic analytics, conversion rates, and customer engagement metrics, to help businesses analyze their performance."
        },
        {
          question: "Can businesses export their data for further analysis?",
          answer: "Yes, the platform allows businesses to export their data for further analysis and integration with other business tools."
        }
      ]
    },
    {
      "category": "Business Model and Solutions",
      "questions": [
        {
          question: "How does Ship2MyID ensure consumer privacy?",
          answer: "Ship2MyID employs patented technology to ensure the highest level of consumer privacy. As the only platform in the world with this innovative technology, we facilitate transactions and interactions between individual consumers and brands without disclosing personally identifiable information (PII). Our system replaces PII with demographic information, eliminating privacy risks. Additionally, Ship2MyID adheres to stringent data privacy standards, ensuring 100% compliance with prevailing data regulations and safeguarding all consumer data with the utmost security and integrity."
        },
        {
          question: "Can Ship2MyID help my business reach international markets?",
          answer: "Yes, Ship2MyID is designed to connect global brands and retailers with consumers worldwide who have a direct intent to purchase. Currently, we are in the pilot stage, and while the export option for international markets is anticipated to be available in the coming days, our services are presently limited to within county boundaries. We are actively working to expand our capabilities to support international business growth."
        },
        {
          question: "What is the Digital ID offered by Ship2MyID?",
          answer: "The Digital ID is a unique lifetime identifier for every consumer, replacing traditional identity and address systems. It allows consumers to manage their own privacy and provides a secure means of conducting transactions."
        },
        {
          question: "How does Ship2MyID's consumer-led marketing approach work?",
          answer: "Unlike conventional seller-driven campaigns, Ship2MyID empowers consumers to choose what they want, when they want it, and from whom they want it in real time. This consumer-centric approach not only enhances conversion rates but also guarantees full compliance with data privacy regulations."
        },
        {
          question: "How does Ship2MyID support local and regional merchants?",
          answer: "Ship2MyID supports local and regional merchants by facilitating direct-to-consumer marketing, allowing them to connect more effectively with their target audience. Once brands are registered and onboarded on the platform, they will have access to tutorial videos for do-it-yourself (DIY) guidance. Additionally, our support team is available to provide initial assistance with running campaigns and offer ongoing support to address any concerns and ensure a smooth experience. This comprehensive support structure helps local businesses thrive and achieve their marketing goals while maintaining data privacy."
        },
        {
          question: "What types of solutions does Ship2MyID offer to businesses?",
          answer: "Ship2MyID provides secure communication channels for personalized marketing campaigns, connecting businesses with verified consumers who have a clear intent to purchase, thus maximizing marketing ROI."
        }
      ]
    },
    {
      "category": "Implementation and Integration",
      "questions": [
        {
          question: "What training and support does Ship2MyID offer to new merchants?",
          answer: "Ship2MyID provides comprehensive onboarding, training materials, and ongoing support to help new merchants get started and make the most of the platform’s features."
        },
        {
          question: "What kind of reporting and analytics are available for merchants on Ship2MyID?",
          answer: "Ship2MyID provides detailed reporting and analytics, including insights into sales performance, customer engagement, and campaign effectiveness, helping merchants optimize their strategies and improve businesses."
        }
      ]
    },
    {
      "category": "Partnership Opportunities",
      "questions": [
        {
          question: "How can my business become a partner with Ship2MyID?",
          answer: "Businesses can become partners by reaching out to Ship2MyID through the provided contact support channels. The partnership process involves discussing mutual goals and how Ship2MyID’s solutions can benefit your business."
        }
      ]
    },
  ];

  filteredFaqs: FAQ[] = this.faqs;
  // filterFAQs() {
  //   const lowerCaseSearchTerm = this.searchQuery.toLowerCase();

  //   this.filteredFaqs = this.faqs.map(faq => ({
  //     category: faq.category,
  //     questions: faq.questions.filter(q =>
  //       q.question.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       q.answer.toLowerCase().includes(lowerCaseSearchTerm) ||
  //       faq.category.toLowerCase().includes(lowerCaseSearchTerm)
  //     )
  //   })).filter(faq => faq.questions.length > 0);
  // }
  expandedIndexes: number[] = []; 
  filterFAQs() {
    const lowerCaseSearchTerm = this.searchQuery.toLowerCase();
  
    // Filter the FAQs
    this.filteredFaqs = this.faqs.map(faq => ({
      category: faq.category,
      questions: faq.questions.filter(q =>
        q.question.toLowerCase().includes(lowerCaseSearchTerm) ||
        q.answer.toLowerCase().includes(lowerCaseSearchTerm) ||
        faq.category.toLowerCase().includes(lowerCaseSearchTerm)
      )
    })).filter(faq => faq.questions.length > 0);
  
    // Expand all matching panels
    this.expandedIndexes = this.filteredFaqs.map((faq, index) => index);
  }

  toggleFAQAccordion() {
    this.showFAQAccordion = !this.showFAQAccordion;
  }
  
}

