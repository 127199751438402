import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientSideStorageService {

  constructor() { }

  checkLocalStorageSupport():boolean{
    if(typeof(Storage)!=="undefined"){
      return true;
    }
    return false;
  }

  get(key:string):any{
    return localStorage.getItem(key);
  }
  set(key :string,value :any):void{
    localStorage.setItem(key,value);
  }

  delete(key :string):void{
    localStorage.removeItem(key);
  }
  private storageKey = 'chatbotData';

  // Save chatbot data to sessionStorage
  setChatbotData(data: any): void {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  // Retrieve chatbot data from sessionStorage
  getChatbotData(): any {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : null;
  }

  // Check if chatbot data exists in sessionStorage
  hasChatbotData(): boolean {
    return sessionStorage.getItem(this.storageKey) !== null;
  }

  // Clear chatbot data from sessionStorage
  clearChatbotData(): void {
    sessionStorage.removeItem(this.storageKey);
  }

  private fullNameSubject = new BehaviorSubject<string>(''); // Initial value
  fullName$ = this.fullNameSubject.asObservable(); // Observable for real-time updates

  private merchantStatusSubject = new BehaviorSubject<string>(''); // Initial value
  status$ = this.merchantStatusSubject.asObservable(); // Observable for real-time updates

  // Update fullName and store in session storage
  updateFullName(name: string) {
    sessionStorage.setItem('fullName', name);
    this.fullNameSubject.next(name); // Emit new value to all subscribers
  }

  // Retrieve fullName from session storage on page reload
  loadFullNameFromSession() {
    const storedName = sessionStorage.getItem('fullName');
    if (storedName) {
      this.fullNameSubject.next(storedName);
    }
  }

  // Update fullName and store in session storage
  updateStatus(name: string) {
    sessionStorage.setItem('status', name);
    this.merchantStatusSubject.next(name); // Emit new value to all subscribers
  }

  // Retrieve fullName from session storage on page reload
  loadStatusFromSession() {
    const storedName = sessionStorage.getItem('status');
    if (storedName) {
      this.merchantStatusSubject.next(storedName);
    }
  }
}

