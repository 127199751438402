import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { HardcodedData } from '../hardcoded-data';
import { DashboardService } from 'src/app/services/dashboard.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import Validation from 'src/app/utils/validation';
import { environment } from 'src/environments/environment';
import { Observable, tap, timer } from 'rxjs';
declare var Stripe: any;
@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrl: './company-profile.component.scss'
})
export class CompanyProfileComponent {

  selectedCardId: string | null = null;
  selectedCard: any = null;

  paymentMethods = [
    {
      id: '1',
      name: 'Mayur Patil',
      email: "email id",
      last4: '4242',
      exp_month: '04',
      exp_year: '2029',
      country: 'US',
      display_brand: 'Visa',
      postal_code: null,
      phone_number:null,
      brandImage: 'assets/images/visa.png',
      default_payment_method: false, // Replace with actual logo URL
      created_date: ''
    },
    {
      id: '2',
      name: 'John Doe',
      email: "email id",
      last4: '1234',
      exp_month: '08',
      exp_year: '2025',
      country: 'US',
      postal_code: null,
      phone_number:null,
      display_brand: 'Mastercard',
      brandImage: 'assets/images/master-card.png',
      default_payment_method: false, // Replace with actual logo URL
      created_date:''
    }
  ];

  @ViewChild('cardElement') cardElement!: ElementRef;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  reportPlaceHolders:any;
  popUpMsg: any;
  apiPlaceHolders: any;
  activeStep = 1;
  companyData: any;
  allCategory: any;
  registerForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    companyWebsite: new FormControl(''),
    companyLogo: new FormControl(''),
    registrationId: new FormControl(''),
    document: new FormControl(''),
    // businessType: new FormControl(''),
    // productCategories: new FormControl(''),
    addressLine: new FormControl(''),
    additionalInfo: new FormControl(''),
    country: new FormControl(''),
    merchant_country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
    postbox_number: new FormControl('')
  });
  countryData: any;
  stateData: any;
  stateSelectedData: any;
  cityData: any;
  zipCode: any;
  zipID: any;
  country: any;
  merchantCountry: any;
  macAddress: any;
  personID: any;
  allBusinessTypes: any;
  businessType: any;
  dropdownSettings: IDropdownSettings = {};
  docName: any = null;
  fData = new FormData();
  docData = new FormData();
  documents3Path: any = null;
  isFileUploaded = false;
  documentName: any = null;
  country_id: any;
  companylogo: any;
  logoName: any = null;
  imageData = new FormData();
  images3Path = null;
  isImageUploaded = false

  cardInfo = {
    id: '2',
    name: 'John Doe',
    email: "email id",
    last4: '1234',
    exp_month: '08',
    exp_year: '2025',
    country: 'US',
    postal_code: null,
    phone_number:null,
    display_brand: 'Mastercard',
    brandImage: 'assets/images/master-card.png',
    default_payment_method: false,
    created_date: ''
  };
  showCard: boolean = false;


  // Administrator Declarations

  showCreatePass = false;
  showConfirmPass = false;
  administartiveForm: FormGroup = new FormGroup({
    primaryContact: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
    code: new FormControl('')
  });
  phoneEditable = true;
  updateVisibility: any = false;
  otpVisibility = false;
  // country: any;
  // macAddress: any;
  otpFieldVisible: any = false;
  updateSubmitVisible: any = true;
  data: any;
  displayTimer: boolean = false;
  resendOtp: boolean = false;
  resendOtpClicked = 1;
  display: any;
  // countryData: any;
  updateButtonVisibility: any = true;

  isAlertActive: any;
  countryInitials: any;
  isCharge: any;

  //Changepassword Declarations
  isChangePassword: boolean = false;
  // companyData = new HardcodedData();
  // showCreatePass = false;
  // showConfirmPass = false;
  showOldPass = false;
  // personID: any;
  changePasswordForm: FormGroup = new FormGroup({
    // primaryContact: new FormControl(''),
    // email: new FormControl(''),
    // phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });




  constructor(
    private router: Router, private fb: FormBuilder,private service: CommonUtilService, private cStorage: ClientSideStorageService,
    private confirmationDialogService: ConfirmationDialogService, private cdr: ChangeDetectorRef, private dashboardService: DashboardService, private notifyService: NotificationAlertService,
    private route: ActivatedRoute, @Inject('COUNTRY_CONFIG') private countryConfig: any) {
    this.placeHolders = this.placeholders['Merchant_profile']
    this.reportPlaceHolders = this.placeholders['Reports_page']
    this.popUpMsg = this.placeholders['Pop_Up_Msg']
    this.apiPlaceHolders = this.placeholders['API_Messages']
    this.createRegisterForm();
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.dashboardService.getCategory().subscribe((res: any) => {
      this.allCategory = res?.body.data;
    });

    this.isAlertActive = sessionStorage.getItem('isDashboardActive');
    this.isCharge = this.countryConfig.isPayment

    // this.allCategory = this.dashboardService.getData();
    // this.allCategory = this.allCategory.source._value.data;

    // Administrator Section Call
    this.getUserData();

    this.createChangePasswordForm();
    this.changePasswordForm.get('password')?.valueChanges.subscribe(value => {
      this.service.setPasswordRulesDataData(value);
    });

    const userData = JSON.parse(this.cStorage.get("marketplaceData"));

    const billingDetails = userData.billingDetails
    this.paymentMethods = billingDetails
    this.updatePaymentStatus()
    if (billingDetails) {
      this.cardInfo = billingDetails.find((card: any) => card.default_payment_method === true);
      // this.cardInfo = billingDetails;  // Parse stored object
      this.showCard = true;
    }



    if (userData) {
      this.companyData = userData;
      this.uuid = this.companyData.uuid
      if (parseInt(this.companyData.country) === 72) {
        this.countryInitials = "B"
      }
      else if (parseInt(this.companyData.country) === 716) {
        this.countryInitials = "Z"
      }
      else {
        this.countryInitials = "D"
      }



      this.route.data.subscribe((data: any) => {
        this.countryData = data.resolvedData.data
        if (this.countryData) {
          this.countryData.forEach((element: any) => {
            if ((element.country_id).toString() === this.companyData.country) {
              this.country = element.country_name;

              this.country = element.country_name === `Côte d'Ivoire` ? 'CD' : element.country_name

              this.service.getBusinessTypes(this.country, this.macAddress).subscribe((res: any) => {
                this.allBusinessTypes = res.body?.data;
                if (this.allBusinessTypes) {
                  this.allBusinessTypes.forEach((element: any) => {
                    let type = this.companyData.marketplace_type * 1;
                    if (type === element.business_type_id) {
                      this.businessType = element.business_type_id;
                    }
                  });
                }
              });
              this.countryData.forEach((element: any) => {
                let merchantcountry = this.companyData.merchant_country !== null ? this.companyData.merchant_country : this.companyData.country;
                if ((element.country_id).toString() === merchantcountry) {
                  this.merchantCountry = element.country_name;
                }
              });
              let countryId = this.companyData.merchant_country !== null ? parseInt(this.companyData.merchant_country) : parseInt(this.companyData.country)
              this.countrySelectionForState(countryId);
            }
          });
        }
      });

      // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      //   this.countryData = res.body?.data;
      //   if (this.countryData) {
      //     this.countryData.forEach((element: any) => {
      //       if ((element.country_id).toString() === this.companyData.country) {
      //         this.country = element.country_name;
      //         this.countrySelectionForState(element.country_id);
      //       }
      //     });
      //   }
      // });
      //     });
      //   }
      // });
    }
    // });
  }

  updatedPayments:any
  updatePaymentStatus() {
    if (!this.paymentMethods || !Array.isArray(this.paymentMethods)) {
      console.error("paymentMethods is null or not an array");
      return;
    }
    
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    this.updatedPayments = this.paymentMethods.map(payment => {
      return {
        ...payment,
        status: (parseInt(payment.exp_year) < currentYear || 
                (parseInt(payment.exp_year) == currentYear && parseInt(payment.exp_month) < currentMonth)) 
                ? 'Expired' 
                : 'Active'
      };
    });
  }





  toggleChangePassword() {
    this.isChangePassword = !this.isChangePassword;
  }

  toggleOldPasswordVisibility() {
    this.showOldPass = !this.showOldPass
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      oldpasswd: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  get c(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  resetChangePasword() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to Reset? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
    this.changePasswordForm.reset();
    //   }
    // });
  }

  saveChangePassword() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          let value = {
            "personId": this.personID,
            "password": this.changePasswordForm.value.password,
            "oldPassword": this.changePasswordForm.value.oldpasswd
          }
          this.service.adminPasswdUpdate(value).subscribe((res: any) => {
            if (res.status === "ERROR") {
              this.notifyService.showError(
                this.apiPlaceHolders['EXISTING_PASSWORD_INCORRECT'],
                res.status
              );
            } else {
              this.notifyService.showSuccess(
                this.popUpMsg['SAVED_SUCCESFULLY'],
                "Success"
              );
              setTimeout(() => {
                this.router.navigate(["/logout"]);
              }, 2000);
            }
          });
        }
      });
  }

  fullName:any;
  // Administrator Section Call
  getUserData() {
    this.service.getUserProfileDetails().subscribe((res: any) => {
      this.data = res?.data;
      this.fullName = this.data?.firstName.concat(" " + this.data?.lastName);
      this.cStorage.set('userFullName', JSON.stringify(this.fullName))
      this.cStorage.updateFullName(this.fullName);
      this.createForm(this.data);
    });
  }

  isValidityFormValid(): boolean {
    const mailControl = this.administartiveForm.get('primaryContact');
    if (mailControl) {
      // Only check validity if the control is dirty or touched
      if (mailControl.dirty || mailControl.touched) {
        mailControl.updateValueAndValidity();
        return mailControl.valid;
      }
    }
    return false;
  }
  uuid: any;
  createForm(data: any) {
    let fullName = data.firstName.concat(" " + data.lastName);
    this.administartiveForm = this.fb.group({
      primaryContact: [fullName, [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30)]],
      email: [data.email_id, [Validators.required, Validators.email]],
      phone: [data.mobile, Validators.required],
      personId: [data.personId],
      code: ['',],
      updatedPhone: [''],
      // password: ['', [
      //   Validators.required,
      //   Validators.minLength(6),
      //   Validators.maxLength(40)
      // ]],
      // confirmPass: ['', Validators.required],
      otp: ['']
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );

    if (this.a['primaryContact'].value) {
      this.a['email'].disable();
      this.a['phone'].disable();
    }
  }

  get a(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  reset() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to Reset? ")
      .then((confirmed) => {
        if (confirmed) {
          this.administartiveForm.reset();
        }
      });
  }

  saveAdminData() {
    this.confirmationDialogService
      .confirm("Confirmation ", this.popUpMsg['MSG_ARE_YOU_SURE_UPDATE_CHANGES'])
      .then((confirmed) => {
        if (confirmed) {
          this.proceed();
        }
      });
  }

  proceed() {
    const fullName = this.administartiveForm.value.primaryContact.split(' ');
    const lastName = fullName.pop(); // 'Panakkal'
    const firstName = fullName.join(' ')
    let payload = {
      "firstName": firstName,
      "lastName": lastName,
      "personId": this.administartiveForm.value.personId,
      "mobile": this.administartiveForm.controls['phone'].value.toString()
    }
    this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
      this.notifyService.showSuccess(
        this.popUpMsg['SAVED_SUCCESFULLY'],
        "Success"
      );
      this.updateVisibility = false;
      this.updateButtonVisibility = true;
      // this.router.navigate(["/dashboard"]);
      this.getUserData()
    });
    
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  editCompanyClick() {
    this.router.navigate(["/companyProfile"]);
  }

  changePass() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to save this operation? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.proceed();
    //       this.router.navigate(["/changePassword"]);
    //     } else {
    this.router.navigate(["/changePassword"]);
    //   }
    // });
  }

  updatePhone() {
    this.phoneEditable = false;
    // this.administartiveForm.controls["phone"].reset();
    this.updateVisibility = true;
    this.updateSubmitVisible = false;
    this.updateButtonVisibility = false;
  }

  verifyPhone() {
    if (this.administartiveForm.controls["updatedPhone"].value !== "" && this.administartiveForm.controls["code"].value !== "") {
      let payload = {
        mobileNumber: this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res && res?.body?.status === "ERROR" || res?.body?.status === "Error") {
          this.notifyService.showError(
            res?.body?.message,
            res?.body?.status
          );
        } else {
          this.phoneEditable = true;
          this.otpFieldVisible = true;
          this.updateVisibility = true;
          this.start(3);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Phone Number!!",
        "Invalid"
      );
    }
  }

  start(minute: any) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendOtp = true;
        this.displayTimer = false;
      }
    }, 1000);
  }

  submitOtp() {
    let status: any = this.administartiveForm.controls['otp'].status;
    if (status !== 'INVALID') {

      let mobileNumber = this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      let payload = {
        "personId": this.personID,
        "phoneNumber": mobileNumber,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.otp
      }
      this.service.updateUserPhone(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.updateSubmitVisible = true;
          this.otpFieldVisible = false;
          this.notifyService.showSuccess(
            "Mobile Number Verified and Updated !!",
            "Success"
          );

          // const fullName = this.administartiveForm.value.primaryContact.split(' ');
          // const lastName = fullName.pop(); // 'Panakkal'
          // const firstName = fullName.join(' ')
          // let payload = {
          //   "firstName": firstName,
          //   "lastName": lastName,
          //   "personId": this.administartiveForm.value.personId,
          //   "mobile": mobileNumber
          // }
          // this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
          //   this.notifyService.showSuccess(
          //     this.popUpMsg['SAVED_SUCCESFULLY'],
          //     "Success"
          //   );
          this.phoneEditable = true;
          this.administartiveForm.controls["updatedPhone"].reset();
          this.administartiveForm.controls["code"].reset();
          this.updateVisibility = false;
          this.getUserData()
          this.updateButtonVisibility = true;
          // });

        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }

  onOtpChange(event: any) {
    let res = event;
    this.administartiveForm.value.otp = res;
  }

  resendOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 2) {
      this.verifyPhone();
    } else {
      this.notifyService.showError("Maximum OTP sending limit reached !", "Error")
    }
  }

  cancelUpdatePhone() {
    this.phoneEditable = true;
    this.administartiveForm.controls["updatedPhone"].reset();
    this.administartiveForm.controls["code"].reset();
    this.updateVisibility = false;
    this.updateButtonVisibility = true;
    this.otpFieldVisible = false;
    // this.updateSubmitVisible = false;
  }

  ngOnInit() {

    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
  }

  brandlogo: any;
  uploadedDoc: any;
  createRegisterForm() {
    if (this.companyData !== null && this.companyData !== undefined) {
      const city = (this.companyData.city) * 1;
      this.documentName = this.companyData.marketplace_document;
      this.companylogo = this.companyData?.marketplace_image !== null ? this.companyData?.marketplace_image : null;
      if (this.companyData?.marketplace_image != null) {
        this.brandlogo = environment.assetsUrl + '/assets/' + this.companyData?.marketplace_image
      }
      this.images3Path = this.companyData?.marketplace_image !== null ? this.companyData?.marketplace_image : null;
      this.documents3Path = this.companyData?.marketplace_document !== null ? this.companyData?.marketplace_document : null;
      if (this.companyData?.marketplace_document != null) {
        this.uploadedDoc = environment.assetsUrl + '/assets/' + this.companyData?.marketplace_document
      }
      this.registerForm = this.fb.group({
        companyId: [this.companyData.marketplace_id],
        companyName: [this.companyData.marketplace_name, Validators.required],
        companyWebsite: [this.companyData.marketplace_link, [this.websiteUrlValidator()]],
        companyLogo: [this.companyData.marketplace_image, Validators.required],
        registrationId: [this.companyData.registration_id,],
        document: [this.companyData.marketplace_document],
        // businessType: [this.businessType, Validators.required],
        addressLine: [this.companyData.addressLine1, Validators.required],
        additionalInfo: [this.companyData.addressLine2],
        country: [this.country,],
        merchant_country: [this.merchantCountry, Validators.required],
        state: [this.companyData.state, Validators.required],
        city: [city, Validators.required],
        zipCode: [this.companyData.zipcode],
        // productCategories: [this.companyData.categories, Validators.required],
        postbox_number: [this.companyData.postbox_number]
      });
      if (this.f['companyName'].value) {
        this.f['companyName'].disable();
        this.f['country'].disable();
        this.f['merchant_country'].disable();
      }
      if (this.f['registrationId'].value) {
        this.f['registrationId'].disable();
      }
    } else {
      this.registerForm = this.fb.group({
        companyId: [''],
        companyName: ['', Validators.required],
        companyWebsite: ['', Validators.required],
        companyLogo: ['', Validators.required],
        registrationId: ['',],
        document: ['', Validators.required],
        // businessType: ['', Validators.required],
        addressLine: ['', Validators.required],
        additionalInfo: ['', Validators.required],
        merchant_country: [],
        country: [''],
        state: ['', Validators.required],
        city: ['', Validators.required],
        zipCode: [''],
        // productCategories: ['', Validators.required],
        postbox_number: ['']
      });
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  save() {

    if(this.isImageUploaded){
      let res = this.registerForm.value;
      let data = {
        // "categories": res.productCategories,
        "marketplace_id": res.companyId,
        "marketplace_image": this.images3Path,
        "marketplace_document": this.documents3Path,
        "marketplace_link": res.companyWebsite,
        "marketplace_name": res.companyName,
        // "marketplace_type": res.businessType,
        "registration_id": res.registrationId,
        "addressLine1": res.addressLine,
        "addressLine2": res.additionalInfo,
        "country": res.country,
        "state": res.state,
        "city": res.city,
        "zipcode": res.zip_code
      }
      this.confirmationDialogService
        .confirm("Confirmation ", this.popUpMsg['MSG_ARE_YOU_SURE_UPDATE_CHANGES'])
        .then((confirmed) => {
          if (confirmed) {
            this.service.editCompanyProfile(data, this.country, this.macAddress).subscribe((res: any) => {
              this.notifyService.showSuccess(
                this.popUpMsg['MSG_DEATILS_ARE_UPDATED_SUCCESS'],
                "Success"
              );
              this.refreshMarketPlace().subscribe();
  
              let marketplaceData = res?.body?.data;
              this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
              if (marketplaceData.status !== "R") {
                sessionStorage.setItem('isDashboardActive', 'true');
                this.isAlertActive = sessionStorage.getItem('isDashboardActive');
              }
  
              // this.router.navigate(["/companyProfile/details"]);
            });
          }
        });
    }else{
      this.notifyService.showError(
       "Please upload the image",
        "Error"
      );
    }
   
  }

  // refreshMarketPlace() {
  //   this.service.getRegisterCompanyDetails().subscribe((res: any) => {
  //     let marketplaceData;
  //     if (res?.data) {
  //       marketplaceData = res?.data;
  //       this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
  //     }
  //   });
  // }

  refreshMarketPlace(): Observable<any> {
    return this.service.getRegisterCompanyDetails().pipe(
      tap((res: any) => {
        if (res?.data) {
          this.cStorage.set('marketplaceData', JSON.stringify(res.data));
          this.cStorage.updateStatus(res?.data.status);
        }
      })
    );
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  adminClick(step: number) {
    // this.router.navigate(["/companyProfile/details"]);
    if (step === 3) {
      this.initializeStripe()
    }
    this.activeStep = step;
    this.cdr.markForCheck();

  }

  countrySelectionForState(id: number) {
    this.registerForm.controls["state"].reset();
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.stateData = [];
    this.cityData = [];
    this.country_id = id;
    this.service.getState(id, this.country, this.macAddress).subscribe((res: any) => {
      // this.stateData = res?.body?.data;
      this.stateData = res.body?.data;
      // this.stateSelectedData = this.stateData?.find((element: any) => element.state_name === this.companyData.state)
      this.stateSelectedData = this.companyData.state;
      this.stateSelectionForCity(this.companyData.state);
    });
  }

  stateSelectionForCity(state: any) {
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id;
    this.service.getCity(selectedCountryCode, state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.body?.data
      this.createRegisterForm();
    });
  }

  citySelectionForZip(zip_code: any, zip_id: any) {
    this.registerForm.controls["zipCode"].reset();
    this.zipCode = zip_code;
    this.zipID = zip_id;
  }

  stateSelectForCity(state: any) {
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id
    this.service.getCity(selectedCountryCode, state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.body?.data;
      // this.createRegisterForm();
    });
  }

  uploadDoc(event: any) {
    let files = event.target.files[0];
    // files.type = "";
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (files.size <= 2097152) {
      this.docName = files.name;
      this.docData.append("file", files);
    } else {
      this.notifyService.showInfo("Only less than 2 MB docs are allowed", "Invalid File")
    }
    event.target.value = '';
  }

  removeDoc() {
    this.registerForm.value.document = null;
    this.docName = null;
    this.docData = new FormData();
    this.documents3Path = null;
    this.isFileUploaded = false;
  }

  documentUpload() {
    this.service.uploadDocRegister(this.docData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.documents3Path = res?.data?.s3Path;
        this.isFileUploaded = true;
        this.notifyService.showSuccess(
          "Document Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  uploadImage(event: any) {
    let files = event.target.files[0];
    // files.type = "";
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png") &&
      files.size <= 300000) {
      this.logoName = files.name;
      this.imageData.append("file", files);
      this.notifyService.showInfo("Image selected please upload", "")
    } else {
      this.notifyService.showError("The image must be in JPG, PNG, or JPEG format and should not exceed a size of 300 KB.", "Invalid File")
    }
    // if (files.size <= 2097152) {
    //   this.logoName = files.name;
    //   this.imageData.append("file", files);
    // } else {
    //   this.notifyService.showInfo("Only less than 2 MB logo are allowed", "Invalid File")
    // }
    event.target.value = '';
  }

  removeImage() {
    this.registerForm.value.companyLogo = null;
    this.logoName = null;
    this.imageData = new FormData();
    this.images3Path = null;
    this.isImageUploaded = false;
  }

  imageUpload() {
    this.service.uploadImageRegister(this.imageData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.isImageUploaded = true;
        this.notifyService.showSuccess(
          "Logo Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url = control.value;
      if (url && !url.startsWith(' ') && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        url = '';
        control.setValue('https://' + url);
      }
      return null;
    };
  }

  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is the spacebar (keyCode 32 or code "Space")
    if (event.keyCode === 32 || event.code === 'Space' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior (spacebar input)
    }
  }

  openLink() {
    window.open(this.uploadedDoc)
  }

  isFieldMandatory(fieldName: string): boolean {
    const control = this.registerForm.get(fieldName);
    return control?.validator ? control.validator({} as AbstractControl)?.['required'] : false;
  }


  // Stripe Implementation

  stripe: any;
  card: any;

  customerId: string = '';
  paymentMethodId: string = '';
  amount: number = 0;
  currency: string = 'USD';
  customerName: string = '';
  customerEmail: string = '';
  customerCountry: string = '';
  customerState: string = '';
  customerCity: string = '';
  customerPincode : any = '';
  customerPhone : number = 0;
  errorMessage: string = '';
  customerLine1: string = '';
  customerLine2: string = '';

  paymentMethod: any;
  paymentCustID: any;

  initializeStripe() {
    let fullName = this.data.firstName.concat(" " + this.data.lastName);
    let Email = this.data.email_id
    this.customerEmail = Email
    this.customerName = fullName
    this.customerCountry = this.merchantCountry
    this.stripe = Stripe('pk_test_51QG4UlLzjH9c5pL5IrWTC45XXgzRjHxUumnnDH0CrZXyv8Ei51kW13f4aFZhU380viDIb7tkQEft59lgPEmv9DIj00XuCD4LyT');
    const options = {
      // mode: 'setup', // Change to 'payment' for actual payments
      // currency: 'usd',
      appearance: {}, // Customize as needed
    };
    this.card = this.stripe.elements().create('card', {
      style: {
        base: {
          fontSize: "16px",
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
      },
      hidePostalCode: true, // Set to true if you don’t want ZIP code
      placeholder: "1234 5678 9012 3456", // Set default placeholder for card
      // disableLink :true
    });
    const elements = this.stripe.elements(options);
    // this.card = elements.create('card');

    // Wait for the component to render completely
    setTimeout(() => {
      this.card.mount(this.cardElement.nativeElement);
    });
  }


  createCustomer() {
    let name = this.customerName//this.data.firstName.concat(" " + this.data.lastName);
    let email = this.customerEmail //this.data.email_id
    const payload = { billing_details: { name, email } };
    this.dashboardService.createCustomer(payload).subscribe((res: any) => {
      this.paymentCustID = res?.body.data.id
      this.notifyService.showSuccess(
        "Customer Created Successfully !",
        res?.status
      );
    });
  }

  async createAndAttachPaymentMethod() {
    try {
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.customerName,
          email: this.customerEmail,
        }
      });

      if (error) {
        this.notifyService.showError(
          "Missing card details !",
          "Invalid"
        );
        console.error('Error creating payment method:', error);
        return;
      }

      if (paymentMethod) {
        this.paymentMethodId = paymentMethod;
        console.log('Payment method created:', this.paymentMethodId);
        // Automatically call attachPaymentMethod once paymentMethodId is available
        if ((this.paymentMethods?.length ?? 0) < 15|| this.paymentMethods === null) {
          this.attachPaymentMethod();
        } else {
          this.notifyService.showError(
            "Payment methods limit exceeded please delete old and try again !",
            'Error'
          );
        }

      }
    } catch (err) {

      console.error('Unexpected error:', err);
    }
  }

  payment: any;
  attachPaymentMethod() {
    let name = this.customerName//this.data.firstName.concat(" " + this.data.lastName);
    let email = this.customerEmail//this.data.email_id
    let country = this.customerCountry
    let state = this.customerState
    let city = this.customerCity
    let postal_code = this.customerPincode
    let phone_number = this.customerPhone
    let line1 = this.customerLine1
    let line2 = this.customerLine2
    const payload = { billing_details: { name,email,country,state,city,postal_code,phone_number,line1,line2 } };
    if (!this.paymentMethodId) {
      this.notifyService.showError(
        "Missing customer ID or payment method ID. !",
        "Invalid"
      );
      console.warn('Missing customer ID or payment method ID.');
      return;
    }

    this.dashboardService.createPaymentCustomer(payload, this.paymentMethodId).subscribe(
      (res: any) => {
        debugger
        if (res?.body?.status === 'ERROR') {
        

          this.notifyService.showError(
            res?.body.data.reason,
            'Error'
          );

          // const match = res?.body?.message.match(/: (.+?);/);
          // if (match && match[1]) {
          //   let errorMessage = match[1]; // Extracted message
          //   this.notifyService.showError(
          //     errorMessage,
          //     'Error'
          //   );
          // }

        } else {
          this.payment = res?.body?.data;
          console.log('Payment method attached:', this.payment);
          this.notifyService.showSuccess(
            "Payment method Created Successfully !",
            'Success'
          );
          this.isLoading = true
          // this.refreshMarketPlace().subscribe(() => {
          //   this.setCard(); // Ensure it's called after refresh completes
          // });
          // Delay refreshMarketPlace by 3 seconds
          timer(3000).subscribe(() => {
           
            this.refreshMarketPlace().subscribe(() => {
              this.setCard(); // Ensure it's called after refresh completes
              this.isLoading = false// Ensure it's called after refresh completes
            },() =>{
              this.isLoading = false
            });
          });
        }
      },
      (error) => {
        console.error('Error attaching payment method:', error);
      }
    );
  }

  setCard() {

    const userData = JSON.parse(this.cStorage.get("marketplaceData"));
    const billingDetails = userData.billingDetails
    this.companyData = userData
    
    if (billingDetails) {
      this.cardInfo = billingDetails.find((card: any) => card.default_payment_method === true);
      // this.cardInfo = billingDetails;  // Parse stored object
      this.showCard = true;
      this.paymentMethods = billingDetails
      this.updatedPayments = billingDetails
      this.customerState = ''
      this.customerCity = ''
      this.customerPincode = ''
      this.customerPhone = 0
      this.customerLine1 = ''
      this.customerLine2 = ''
      this.initializeStripe()
    }
    this.updatePaymentStatus()
  }

  get cardBrandImage() {
    const brandLogos: { [key: string]: string } = {
      visa: 'assets/images/visa.png',
      mastercard: 'assets/images/master-card.png',
      american_express: 'assets/images/amexcard.png',
      discover: 'assets/images/discover.png',
      cartes_bancaires:'assets/images/carnet.jpg',
      unionpay: 'assets/images/UnionPay.png',
      jcb:'assets/images/jcb.jpg'
    };
    return brandLogos[this.cardInfo?.display_brand?.toLowerCase()] || 'assets/images/credit-card.png';
  }

  getCardBrandImage(brand: string){
    const brandLogos: { [key: string]: string } = {
      visa: 'assets/images/visa.png',
      mastercard: 'assets/images/master-card.png',
      american_express: 'assets/images/amexcard.png',
      discover: 'assets/images/discover.jpg',
      cartes_bancaires:'assets/images/carnet.jpg',
      unionpay: 'assets/images/UnionPay.png',
      jcb:'assets/images/jcb.jpg'
    };
    return brandLogos[brand] || 'assets/images/credit-card.png';
  }

  updateSelectedCard() {
    this.selectedCard = this.paymentMethods.find(card => card.id === this.selectedCardId);
  }
  isLoading: boolean = false
  viewCard(card: any) {
    this.selectedCard = card;
    this.cardInfo = card

    this.service.setCardAsDefault(this.selectedCard.payment_method_id).subscribe(
      (res: any) => {
        if (res?.status === 'ERROR') {
          this.notifyService.showError(
            'Error Setting it Default',
            'Error'
          );
        }
        else{
          this.isLoading = true;
          timer(3000).subscribe(() => {
            this.refreshMarketPlace().subscribe(() => {
              this.setCard(); 
              this.isLoading = false// Ensure it's called after refresh completes
              this.notifyService.showSuccess(
                'Card Set to default successfully',
                'Success'
              );
            },() =>{
              this.isLoading = false
            });
          });
        }
      });



  }

  deleteCard(cardId: any) {
    this.selectedCard = cardId;
    if (confirm('Are you sure you want to delete this card?')) {
      this.service.deleteCard(this.selectedCard.payment_method_id).subscribe(
        (res: any) => {
          if (res?.status === 'ERROR') {
            this.notifyService.showError(
              'Error Deleting the card',
              'Error'
            );
          }
          else{
            this.isLoading = true;
            timer(3000).subscribe(() => {
              this.refreshMarketPlace().subscribe(() => {
                this.setCard(); 
                this.isLoading = false//
                //  Ensure it's called after refresh completes
                this.notifyService.showSuccess(
                  'Card deleted successfully',
                  'Success'
                );
              },() =>{
                this.isLoading = false
              });
            });
          }
        });

      this.updatedPayments = this.updatedPayments.filter((card:any) => card.id !== cardId.id);
      if (this.selectedCard && this.selectedCard.id === cardId.id) {
        this.selectedCard = null; // Clear selected card if deleted
      }

    }
  }

  getStatusClass(status: string): any {
    switch(status) {
      case 'A': return 'active-chip';
      case 'P': return 'pending-chip';
      case 'S': return 'paused-chip'
      case 'E': return 'expired-chip';
      case 'IA': return 'inactive-chip';
      case 'D': return 'draft-chip';
      case 'U': return 'upcoming-chip';
      case 'R': return 'rejected-chip';
      case 'N': return 'new-chip';
      case 'C': return 'completed-chip';
      default: return '';
    }
  }
  
  getStatusText(status: string): any {
    switch(status) {
      case 'A': return this.reportPlaceHolders['LBL_ACTIVE'];
      case 'P': return this.reportPlaceHolders['LBL_PENDING'];
      case 'E': return this.reportPlaceHolders['LBL_EXPIRED'];
      case 'IA': return this.reportPlaceHolders['LBL_INACTIVE'];
      case 'D': return this.reportPlaceHolders['LBL_DRAFT'];
      case 'U': return this.reportPlaceHolders['LBL_UPCOMING'];
      case 'S': return this.reportPlaceHolders['LBL_PAUSED'];
      case 'R': return this.reportPlaceHolders['LBL_REJECTED'];
      case 'N': return this.reportPlaceHolders['LBL_NEW'];
      case 'C': return this.reportPlaceHolders['LBL_COMPLETED'];
      default: return '';
    }
  }

}
