export const defaultConfig = {
    logoUrl: 'assets/img/brand/ship2myidLogo.svg',
    // logoUrl:'../assets/images/poso_click.png',
    language: 'en',
    currency: '$', //'₹',
    shortLogo:'https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?',
    country:'India',
    favicon:'assets/cropped-ship2myid-icon-32x32.png',
    isPayment:false,
    isMsgCharge:false,
    loginPostlogo:'assets/images/ship2myidlogo_Sq.png',
    loginCountrylogo:'assets/img/brand/ship2myidLogo.svg',
    timezone:'Africa/Gaborone'
  };