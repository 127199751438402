<div class="div">
  <div class="div-2">
    <div class="div-3">
      <div class="column"> </div>
      <div class="column-2">
        <div class="div-13 form-group">
          <form [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()" [hidden]="resetLink">
            <div class="div-17">
              <img style="cursor: pointer;" routerLink="/login" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?"
                class="img-2" />
              <div class="div-18">Forgot Password</div>
              <div style="
              text-align: left;
          ">
                <!-- <div class="div-19 asterix">Enter Your Email Address</div>
                <div class="">
                  <input class="col-12 div-22" type="text" placeholder="Enter Your Email" autocomplete="email_id"
                    formControlName="email_id"
                    [ngClass]="{ 'is-invalid': f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched ) }"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                    <div class="invalid-feedback">
                      @if ( f['email_id'].errors) {
                        <div >
                          @if (f['email_id'].errors['required']) {
                          <div>Email is required</div>
                          }
                          @if (f['email_id'].errors['email']) {
                            <div>Email is invalid</div>
                            } 
                          @if (f['email_id'].errors['pattern']) {
                          <div>Invalid Email!!</div>
                          }
                        </div>
                        }
                      </div>
                </div> -->

                <div class="col-12 form-group mt-3">
                  <label for="email_id" class="asterix">Enter your email address</label>
                  <input class="form-control" type="text" placeholder="Enter your email" autocomplete="email_id"
                    formControlName="email_id"
                    [ngClass]="{ 'is-invalid': f['email_id'].errors && (f['email_id'].dirty || f['email_id'].touched ) }"
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$" required />
                    <div class="invalid-feedback">
                      @if ( f['email_id'].errors) {
                        <div >
                          @if (f['email_id'].errors['required']) {
                          <div>Email is required</div>
                          }
                          <!-- @if (f['email_id'].errors['email']) {
                            <div>Email is invalid</div>
                            } -->
                          @if (f['email_id'].errors['pattern']) {
                          <div>Invalid Email!!</div>
                          }
                        </div>
                        }
                      </div>
                </div>
                
                <!-- <div *ngIf="submitted && f['email_id'].errors" class="invalid-feedback">
                  <div *ngIf="f['email_id'].errors['required']">
                    Email Address is required
                  </div>
                  <div *ngIf="f['email_id'].errors['email']">
                    {{ formErrors.email.email }}
                  </div>
                </div> -->
              </div>
              <nav>
                <button class="div-24" type="submit">Send OTP</button>
              </nav>

              <a routerLink="/login" class="div-23"> Back to login</a>
            </div>
            <div></div>
          </form>


          <form [formGroup]="otpForm" [hidden]="!resetLink">
            <div class="div-17">
              <img style="cursor: pointer;" routerLink="/login" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?"
                class="img-2" />
              <h4>
                <p class="text-muted" style="margin-bottom: 0px !important">
                  OTP Sent
                </p>
              </h4>
              <div style="color: gray; padding-bottom: 10px;text-align: center;">
                <small>We have sent you a OTP to your email
                  address <b>{{this.f['email_id'].value}}</b></small>
              </div>
              <div *ngIf="resendOtp !== true">
                <div class="div-19 ">Enter OTP</div>

                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>

                <!-- <input type="text" class="div-20 form-control" placeholder="Enter OTP" autocomplete="otp"
                  formControlName="otp"
                  [ngClass]="{ 'is-invalid': o['otp'].errors && (o['otp'].dirty || o['otp'].touched ) }" required />
                @if ( o['otp'].errors) {
                <div class="px-2 invalid-feedback" style="
                    text-align: left;
                ">
                  @if (o['otp'].errors['required']) {
                  <div>OTP is required</div>
                  }
                </div>
                } -->
              </div>

              <div class="pt-1 px-4" style="color: gray;justify-content: center;
              display: flex;">

                <p *ngIf="displayTimer">Expiring in <span style="color: red;">{{ display }}</span></p>
                <a *ngIf="resendOtp" style="color:rgb(0, 0, 238);text-decoration: underline;cursor: pointer;" (click)="resendOTP()">Resend
                  OTP</a>

                <!-- <div>Expiring in <span id="timer">
                  </span>
                </div> -->
              </div>
              <nav *ngIf="resendOtp !== true">
                <button class="div-24" type="submit" (click)="validateOtp()">Submit</button>
              </nav>
              <a routerLink="/login" class="div-23"> Back to login</a>
            </div>
            <div></div>
          </form>




        </div>

      </div>
    </div>
  </div>
</div>

<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="Please Wait..."></ngx-ui-loader>