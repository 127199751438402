import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Validation from '../../../utils/validation';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { LoginService } from 'src/app/services/login.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrl: './create-password.component.scss'
})
export class CreatePasswordComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  apiPlaceHolders: any;
  validationRules: { rule: string; isMet: boolean }[];
  createPassForm: FormGroup = new FormGroup({
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });
  submitted = false;
  resetLink = false;
  showCreatePass = false;
  showConfirmPass = false;

  constructor(private fb: FormBuilder,
    private router: Router, private cStorage: ClientSideStorageService,
    private notifyService: NotificationAlertService, private loginService: LoginService) {
    this.createPasswordForm();
    this.placeHolders = this.placeholders['Registration_page']

    this.validationRules = [
      { rule: this.placeHolders['LBL_PWD_RULE_8_CHARS'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_UPPER_LOWER_CASE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_NUMERICAL_ONE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_SPECIAL_CHAR'], isMet: false }
    ];

    this.createPassForm.get('password')?.valueChanges.subscribe(value => {
      this.validatePassword(value);
    });
  }

  ngOnInit() {
    this.submitted = false;

   
  }

  createPasswordForm() {
    this.createPassForm = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  get f() {
    return this.createPassForm.controls;
  }

  onSubmit() {
    // this.submitted = true;
    if (this.createPassForm.invalid) {
      if (this.createPassForm.controls['password'].touched !== true && this.createPassForm.controls['confirmPass'].touched !== true) {
        this.notifyService.showError("Fields cannot be Empty", "Invalid");
      } else if (this.createPassForm.controls['password'].touched !== true) {
        this.notifyService.showError("Password cannot be Empty", "Invalid");
      } else if (this.createPassForm.controls['confirmPass'].touched !== true) {
        this.notifyService.showError("Re-Enter  cannot be Empty", "Invalid");
      } else {
        this.notifyService.showError("Invalid Fields", "Invalid");
      }
    } else {
      const country = JSON.parse(this.cStorage.get("country"));
      const macAddress = JSON.parse(this.cStorage.get("macAddress"));
      const token = JSON.parse(this.cStorage.get("passwordToken"));
      const email_id = JSON.parse(this.cStorage.get("userName"));
      let data = {
        email_id: email_id,
        token: token?.body.data,
        password: this.createPassForm.value.password
      }
      this.loginService.saveUpdatePassword(data, country, macAddress).subscribe((res:any) => {
        if(res.body?.status !== "Error") {
          this.notifyService.showSuccess(            
            "Password updated successfully !",
            res.body?.status
          );
          this.router.navigate(["./login"]);        
        } else if (res.body?.status !== "Error") {
          this.notifyService.showSuccess(            
            res.body?.message,
            res.body?.status
          );  
        }
      });
    }
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  validatePassword(value: string): void {
    // Reset all validation rules to false initially
    this.validationRules.forEach(rule => {
      rule.isMet = false;
    });

    // Check each validation rule and update its status
    if (value?.length >= 8 && value?.length <= 30) {
      this.validationRules[0].isMet = true;
    }
    if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
      this.validationRules[1].isMet = true;
    }
    if (/[#?!@$%^&*-]/.test(value)) {
      this.validationRules[3].isMet = true;
    }
    if (/\d/.test(value)) {
      this.validationRules[2].isMet = true;
    }
  }

}
