<ng-container *ngIf="showCheckout; else captureFormTemplate">
    <div class="container">
        <h2>Stripe Payment</h2>

        <form [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" formControlName="name" required>
            </div>

            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" formControlName="email" required>
            </div>

            <!-- <div class="form-group">
                <label for="card-element">Credit card</label>
                <div #cardElement class="stripe-card"></div>
            </div>

            <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div> -->

            <button type="submit" [disabled]="!paymentForm.valid || loading">
                {{btnLabel}}
            </button>
        </form>

        <div *ngIf="paymentStatus">
            <h3>{{ paymentStatus }}</h3>
        </div>
    </div>
</ng-container>

<!-- ng-template for the form if showCheckout is false -->
<ng-template #captureFormTemplate>
    <!-- <div class="container">
        <h2>Capture Payment</h2>

        <form [formGroup]="captureForm" (ngSubmit)="onCapturePayment()">
            <div class="form-group">
                <label for="amount">Amount</label>
                <input type="number" id="amount" formControlName="amount" required>
            </div>

            <button type="submit" [disabled]="!captureForm.valid">
                Capture Payment
            </button>
        </form>
    </div> -->
    <div class="payment-success-popup">
        <div class="success-icon">✔️</div>
        <div class="success-message">Payment {{paymsg}} Successful!</div>
        <div class="message-details">Thank you</div>
        <button class="close-btn" (click)="closePopup()">Close</button>
    </div>
</ng-template>