import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { FilterCampaignComponent } from './filter-campaign/filter-campaign.component';
import * as moment from "moment";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { CommonUtilService } from '../../services/common-util.service';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { EditComponent } from '../sharedComponent/edit/edit.component';
import { SwitchToggleComponent } from '../sharedComponent/switch-toggle/switch-toggle.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignService } from '../../services/campaign.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HardcodedData } from '../hardcoded-data';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { ViewComponent } from '../sharedComponent/view/view.component';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { CreateflashdealComponent } from '../sharedComponent/createflashdeal/createflashdeal.component';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-dealcreation',
  templateUrl: './dealcreation.component.html',
  styleUrl: './dealcreation.component.scss'
})
export class DealcreationComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  isComingFromSource = false;
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  alldata: any = [];
  loanFilter: any;
  presentDate: any;
  past15Days: any;
  pastMonthDay: any;
  pastWeek: any;
  past3Month: any;
  currentDate: any;
  datePickerInput: any;
  fromDate: any;
  toDate: any;
  datePickerVisible = false;
  activePage: number = 1;
  dropdownSettings: IDropdownSettings = {};
  dashboardForm: FormGroup = new FormGroup({
    dateFilter: new FormControl('3'),
    totalCampaign: new FormControl(''),
    totalBudget: new FormControl(''),
    actualSpent: new FormControl(''),
    search: new FormControl(''),
  });
  allCategory: any;
  categoryValue: any;
  dashboardData = new HardcodedData();
  campaignTotalData: any;
  searchValue: any;
  dateFilter: any;
  userData: any;
  currencyData: any;
  filterDataForChip: any;
  todaysDate: any;
  minDate: any;
  recordTotalCount: any = 0;
  placeHolders: any;
  currency: any;
  isNewUser: any;
  isCharge: any;
  brandlogo: any;
  constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private dashboardService: DashboardService, private service: CommonUtilService, private notifyService: NotificationAlertService,
    private commonUtilService: CommonUtilService, private fb: FormBuilder, private campaignService: CampaignService, private clientStorage: ClientSideStorageService, @Inject('COUNTRY_CONFIG') private countryConfig: any) {

    if (environment.authUrl.toLowerCase().includes('app.ship2myid.com')) {
      this.isProd = false;
    }
    this.placeHolders = this.placeholders['dashboard_page']
    this.currency = this.countryConfig.currency;
    this.isCharge = this.countryConfig.isPayment

    this.isNewUser = sessionStorage.getItem('isNewUser') === 'true' ? true : false;
    this.campaignService.setData(null);
    const curDate = new Date();
    this.todaysDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    this.minDate = minDate.toISOString().split('T')[0];

    // Below call to get from State for Company data 

    // this.service.getRegisterCompanyDetails().subscribe((res: any) => {

    //   if (res?.data) {
    //     this.userData = res?.data;
    //     this.clientStorage.set('marketplaceData', JSON.stringify(this.userData));
    //   }
    // });
    this.route.data.subscribe((data: any) => {
      this.allCategory = data?.resolvedData.data;
      this.dashboardService.setData(data?.resolvedData);
    });

    this.userData = JSON.parse(this.clientStorage.get("marketplaceData"));
    this.brandlogo = environment.assetsUrl + '/assets/' + this.userData?.marketplace_image
    // if (this.userData) {
    //   this.campaignService.eventCostByCountry(this.userData['country']).subscribe((response: any) => {
    //     if (response?.body?.data?.countryEventsCostList) {
    //       this.currencyData = response?.body?.data?.countryEventsCostList[0];
    //     }
    //   });
    // }
    this.dateFilter = '5';
    this.createDashboardForm();
    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      itemsShowLimit: 3
      // defaultOpen: false
    };
    // this.getBrandSpecificCategory();
    this.getCategory();
    this.currentDate = new Date();
    // this.presentDate =
    // this.currentDate.getFullYear() +
    //   "-" +
    //   ("0" + (this.currentDate.getMonth() + 1)).slice(-2) +
    //   "-" +
    //   ("0" + this.currentDate.getDate()).slice(-2);

    let parsedDate = moment(this.currentDate, "YYYY-MM-DD");
    this.presentDate = parsedDate.format("MM/DD/YYYY");
    this.toDate = this.presentDate;
    // this.createDashboardForm();

    // this.dateFilter = '3';
    this.last3MonthData();
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 51;


  }
  isProd: boolean = true;
  ngOnInit(): void {


    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,

    };
    // this.getTableData(this.past15Days, this.presentDate, '1');
    // this.route.params.subscribe(params => {
    //   this.isComingFromSource = params['isComingFromSource'] === 'true';
    // });

    const routeSnapshot: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
    this.isComingFromSource = true //this.checkIsComingFromSource(routeSnapshot);
  }

  private checkIsComingFromSource(routeSnapshot: ActivatedRouteSnapshot): boolean {
    // Add your logic to check if the flag is set in route parameters or query parameters
    const isComingFromSource = routeSnapshot.firstChild?.params['isComingFromSource'] === 'true';
    return isComingFromSource;
  }

  createDashboardForm() {
    // if (this.campaignTotalData !== null && this.campaignTotalData !== undefined) {
    //   let res = this.campaignTotalData;
    //   this.dashboardForm = this.fb.group({
    //     dateFilter: [this.dateFilter],
    //     totalCampaign: [res?.TOTAL_DEALS],
    //     totalBudget: [res?.TOTAL_BUDGET],
    //     actualSpent: [res?.TOTAL_ACTUAL_SPENT],
    //     pagination: [this.activePage],
    //     search: [''],
    //     toDate: [this.toDate],
    //     fromDate: [this.fromDate],
    //     filterStatus: [''],
    //     filterbudget: [''],
    //     filterSpent: [''],
    //     categories: ['']
    //   });
    // } else {
    this.dashboardForm = this.fb.group({
      dateFilter: [this.dateFilter],
      totalCampaign: new FormControl(),
      totalBudget: new FormControl(),
      actualSpent: new FormControl(),
      pagination: [this.activePage],
      search: [''],
      toDate: [this.toDate],
      fromDate: [this.fromDate],
      filterStatus: [''],
      filterbudget: [''],
      filterSpent: [''],
      categories: ['']
    }
    );
    // }
  }

  getCampaignTotalData(fromDate: any, toDate: any) {
    this.campaignTotalData = null;
    // this.dashboardService.campaignData(fromDate, toDate, this.userData.marketplace_id).subscribe((res: any) => {
    // this.campaignTotalData = res;
    // this.createDashboardForm();
    this.dashboardForm.value.fromDate = fromDate;
    this.dashboardForm.value.toDate = toDate;
    // this.activePage = 0;
    // this.dashboardForm.value.pagination = '1';
    setTimeout(() => {
      // Code to execute after 2 seconds
      this.getTableData();
      this.getAllDealsData();
    }, 0);

    // });
  }

  // onResetFilter() {
  //   this.dashboardForm.controls["category"].reset();
  //   this.dashboardForm.controls["status"].reset();
  // }

  // onSubmitFilter() {//method not used any more
  //   // this.fromDate, this.toDate
  //   let res = this.dashboardForm.value;
  //   if (res.dateFilter === 2) {
  //     this.getTableData(this.pastWeek, this.presentDate, '1', res.status);
  //   } else if (res.dateFilter === 3) {
  //     this.getTableData(this.past15Days, this.presentDate, '1', res.status);
  //   } else if (res.dateFilter === 4) {
  //     this.getTableData(this.pastMonthDay, this.presentDate, '1', res.status);
  //   } else if (res.dateFilter === 5) {
  //     this.getTableData(this.past3Month, this.presentDate, '1', res.status);
  //   } else if (res.dateFilter === 1) {
  //     if ((this.fromDate !== null && this.fromDate !== undefined) && (this.toDate !== null && this.toDate !== undefined) && (res.status !== null && res.status !== undefined)) {
  //       this.getTableData(this.fromDate, this.toDate, '1', res.status);
  //     }
  //   }
  //   // this.getTableData(filterData.fromDate, filterData.toDate, '1', filterData.status)
  // }

  getCategory() {
    this.dashboardService.getCategory().subscribe((response: any) => {
      // this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      this.dashboardService.setData(response?.body);
    });
  }

  getBrandSpecificCategory() {
    this.dashboardService.getBrandCategory(this.userData.marketplace_id).subscribe((response: any) => {
      // this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      this.dashboardService.setData(response?.body);
    });
  }

  getTableData() {
    // let fromDate = this.dashboardForm.value.fromDate;
    // let toDate = this.dashboardForm.value.toDate;
    // let pageNumber = this.dashboardForm.value.pagination;
    // let status = this.dashboardForm.value.filterStatus;

    this.data = [];
    // if (status === "All") {
    //   status = "All";
    // } else if (status === '') {
    //   status = "A";
    // }
    this.activePage = 1;
    this.dashboardService.getBrandSpecificDeals(this.dashboardForm.value, this.userData.marketplace_id).subscribe((res: any) => {
      this.commonUtilService.tokenSet(res);
      this.recordTotalCount = 0;
      this.campaignTotalData = res?.body?.data;
      //this.createDashboardForm();
      this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
      if (res && res?.body.data && res?.body.data.DEALS && res?.body.data.DEALS.length !== 0) {
        res?.body.data.DEALS.forEach((item: any) => {
          let tableData = item;
          // if (item && item.categories[0]) {
          //   // let data = this.allCategory?.find((i: any) => i.category_id === item.categories[0]);
          //   // tableData.category_type = data?.category_type;
          //   let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
          //   tableData.created_date = parsedDate.format("DD/MM/YYYY");
          //   this.data.push(tableData);
          // }
          if (item && item.categories) {
            let categoryTypes: string[] = [];
            item.categories.forEach((categoryId: number) => {
              let data = this.allCategory?.find((i: any) => i.category_id === categoryId);
              if (data) {
                categoryTypes.push(data.category_type);
              }
            });
            let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
            tableData.created_date = parsedDate.format("DD/MM/YYYY");
            tableData.category_type = categoryTypes.join(', ');
            this.data.push(tableData);
          }
        });
        this.createColumnDefs();
        this.dashboardForm.get('totalCampaign')?.setValue(this.campaignTotalData?.TOTAL_DEALS);
        this.dashboardForm.get('totalBudget')?.setValue(this.campaignTotalData?.TOTAL_BUDGET);
        this.dashboardForm.get('actualSpent')?.setValue(this.campaignTotalData?.TOTAL_ACTUAL_SPENT);
      } else {
        this.createColumnDefs();
        this.dashboardForm.get('totalCampaign')?.setValue(this.campaignTotalData?.TOTAL_DEALS);
        this.dashboardForm.get('totalBudget')?.setValue(this.campaignTotalData?.TOTAL_BUDGET);
        this.dashboardForm.get('actualSpent')?.setValue(this.campaignTotalData?.TOTAL_ACTUAL_SPENT);
      }
    }, (error) => {

      //this.data = this.dashboardData.AllCampaignTestData;
    });
  }

reportTotalDeals:any
reportTotalBudget:any
reportTotalSpent:any
reportSpendCharged:any
  getAllDealsData() {

    this.dashboardService.getAllDeals(this.userData.marketplace_id).subscribe((res: any) => {


      // this.campaignTotalData = res?.body?.data;
      //this.createDashboardForm();
      this.reportTotalDeals = res?.body?.data?.TOTAL_DEALS;
      this.reportTotalSpent = res?.body?.data?.TOTAL_ACTUAL_SPENT;
      this.reportTotalBudget = res?.body?.data?.TOTAL_BUDGET;
      this.reportSpendCharged = res?.body?.data?.TOTAL_SPENT_CHARGED;
      if (res && res?.body.data && res?.body.data.DEALS && res?.body.data.DEALS.length !== 0) {
        res?.body.data.DEALS.forEach((item: any) => {
          let tableData = item;
          // if (item && item.categories[0]) {
          //   // let data = this.allCategory?.find((i: any) => i.category_id === item.categories[0]);
          //   // tableData.category_type = data?.category_type;
          //   let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
          //   tableData.created_date = parsedDate.format("DD/MM/YYYY");
          //   this.data.push(tableData);
          // }
          if (item && item.categories) {
            let categoryTypes: string[] = [];
            item.categories.forEach((categoryId: number) => {
              let data = this.allCategory?.find((i: any) => i.category_id === categoryId);
              if (data) {
                categoryTypes.push(data.category_type);
              }
            });
            let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
            tableData.created_date = parsedDate.format("DD/MM/YYYY");
            tableData.category_type = categoryTypes.join(', ');
            this.alldata.push(tableData);
          }
        }
        )
      }
    });


  }

  setCategoryValue(e: any) {//method not used any more
    this.categoryValue = e;
  }

  lastWeekData() {
    this.filterDataForChip = null;
    this.datePickerVisible = false;
    const pastWeek = new Date(
      new Date().getTime() - 6 * 24 * 60 * 60 * 1000
    );
    this.dateFilter = '2';
    let parsedDate = moment(pastWeek, "YYYY-MM-DD");
    this.pastWeek = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.fromDate = this.pastWeek;
    this.dashboardForm.value.toDate = this.presentDate;
    this.toDate = this.presentDate;
    this.fromDate = this.pastWeek;
    this.dashboardForm.get('toDate')?.setValue(this.toDate);
    this.dashboardForm.get('fromDate')?.setValue(this.fromDate);
    this.dashboardForm.get('pagination')?.setValue('1');
    this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
    // this.dashboardForm.controls["pagination"].reset();
    this.getCampaignTotalData(this.pastWeek, this.presentDate);
  }

  last15DaysData() {
    this.filterDataForChip = null;
    this.datePickerVisible = false;
    const past15Days = new Date(
      new Date().getTime() - 14 * 24 * 60 * 60 * 1000
    );
    this.dateFilter = '3';
    let parsedDate = moment(past15Days, "YYYY-MM-DD");
    this.past15Days = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.fromDate = this.past15Days;
    this.dashboardForm.value.toDate = this.presentDate;
    this.toDate = this.presentDate;
    this.fromDate = this.past15Days;
    this.dashboardForm.get('toDate')?.setValue(this.toDate);
    this.dashboardForm.get('fromDate')?.setValue(this.fromDate);
    this.dashboardForm.get('pagination')?.setValue('1');
    this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
    this.dashboardForm.get('search')?.setValue('');
    // this.dashboardForm.controls["pagination"].reset();
    this.getCampaignTotalData(this.past15Days, this.presentDate);
    // this.getTableData(this.past15Days, this.presentDate);
  }

  lastMonthData() {
    this.filterDataForChip = null;
    this.datePickerVisible = false;
    const pastMonthDay = new Date(
      new Date().getTime() - 29 * 24 * 60 * 60 * 1000
    );
    this.dateFilter = '4';
    let parsedDate = moment(pastMonthDay, "YYYY-MM-DD");
    this.pastMonthDay = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.fromDate = this.pastMonthDay;
    this.dashboardForm.value.toDate = this.presentDate;
    this.toDate = this.presentDate;
    this.fromDate = this.pastMonthDay;
    this.dashboardForm.get('toDate')?.setValue(this.toDate);
    this.dashboardForm.get('fromDate')?.setValue(this.fromDate);
    this.dashboardForm.get('pagination')?.setValue('1');
    this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
    // this.dashboardForm.controls["pagination"].reset();
    this.getCampaignTotalData(this.pastMonthDay, this.presentDate);
  }

  last3MonthData() {
    this.filterDataForChip = null;
    this.datePickerVisible = false;
    const past3Month = new Date(
      new Date().getTime() - 89 * 24 * 60 * 60 * 1000
    );
    this.dateFilter = '5';
    let parsedDate = moment(past3Month, "YYYY-MM-DD");
    this.past3Month = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.fromDate = this.past3Month;
    this.dashboardForm.value.toDate = this.presentDate;
    this.toDate = this.presentDate;
    this.fromDate = this.past3Month;
    this.dashboardForm.get('toDate')?.setValue(this.toDate);
    this.dashboardForm.get('fromDate')?.setValue(this.fromDate);
    this.dashboardForm.get('pagination')?.setValue('1');
    this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
    // this.dashboardForm.controls["pagination"].reset();
    this.getCampaignTotalData(this.past3Month, this.presentDate);
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  // quickSearch(e: any) {
  //   // if (e.target.value.length >= 3 || e.target.value.length === 0) {
  //   this.gridApi.setQuickFilter(e.target.value);
  //   // }
  // }

  searchClick(event: any) {//method not used any more
    this.gridApi.setQuickFilter(event.target.value);
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    this.frameworkComponents = {
      // toggleComponent: ToggleComponent,
      edit: EditComponent,
      // cellRendererFramework: RouterLinkRendererComponentComponent
    };

    header = this.commonUtilService.getColumnHeader(
      "uuid",
      'Unique ID'
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 100;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "product_name",
      this.placeHolders['LBL_TBL_NAME']
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 200;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "created_date",
      this.placeHolders['LBL_TBL_DATE_CREATED']
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "category_type",
      this.placeHolders['LBL_TBL_CATEGORY']
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 100;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "status",
      this.placeHolders['LBL_TBL_STATUS']
    );
    header.cellRenderer = SwitchToggleComponent;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 50;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "budget",
      this.placeHolders['LBL_TBL_BUDGET']
    );
    // header.pinned = "right";
    header.filter = false;
    header.maxWidth = 90;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);


    header = this.commonUtilService.getColumnHeader(
      "total_spent",
      this.placeHolders['LBL_TBL_ACTUAL_SPENT']
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.maxWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "spentAlreadyCharged", 'Charged'
      //this.placeHolders['LBL_TBL_ACTUAL_SPENT']
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.maxWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    // header = this.commonUtilService.getColumnHeader(
    //   "",
    //   this.placeHolders['LBL_TBL_ACTION']
    // );
    // header.cellRenderer = EditComponent;
    // header.cellRendererParams = {
    //   clicked: (field: any) => {
    //     this.campaignService.setData(field);
    //     this.router.navigate(["/campaign"]);
    //   }
    // },
    //   header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.maxWidth = 74
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);


    // if (this.isProd) {
    //   header = this.commonUtilService.getColumnHeader(
    //     "",
    //     this.placeHolders['LBL_TBL_DEALTYPE']
    //   );
    //   header.cellRenderer = CreateflashdealComponent;
    //   header.cellRendererParams = {
    //     clicked: (field: any) => {
    //       // this.campaignService.createEdit(true);
    //       if (!field.flash) {
    //         this.campaignService.setData(field);
    //         this.router.navigate(["/campaign"], { queryParams: { createFlash: true } });
    //       } else {
    //         field.preventDefault();
    //       }
    //     }
    //   },
    //     header.filterParams = this.commonUtilService.getFilterButtons();
    //   header.filter = false;
    //   header.minWidth = 100
    //   header.filterParams.suppressAndOrCondition = true;
    //   header.filterParams.filterOptions =
    //     this.commonUtilService.getCommaSeparatedFilter();
    //   this.columnDefs.push(header);
    // }

    //FLASH DEAL UNCOMMENT ABOVE TO WORK 

    // header = this.commonUtilService.getColumnHeader(
    //   "",
    //   "Type"
    // );
    // header.cellRenderer = ViewComponent;
    // // header.cellRendererParams = {
    // //   clicked: (field: any) => {
    // //     // this.campaignService.createEdit(true);
    // //     this.campaignService.setData(field);
    // //     this.router.navigate(["/campaign"],{ queryParams: {createFlash: true}});
    // //   }
    // // },
    // header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.maxWidth = 74
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);
  }


  createCampaign() {
    // if (this.userData.stripeCustomerId) {
    this.campaignService.setData(null);
    this.router.navigate(["/campaign"]);
    // } else {
    //   if (!this.isCharge){
    //     this.campaignService.setData(null);
    //     this.router.navigate(["/campaign"]);
    //   }else{
    //     this.notifyService.showError("Please set payment method in profile section", "Alert");
    //   }

    // }

  }

  // filterCampaign() {
  //   this.filterDataForChip = null;
  //   const modalref = this.dialog.open(FilterCampaignComponent, {
  //     height: 'auto',
  //     width: '500px',
  //     position: {
  //       top: '12vh',
  //       left: '30vw'
  //     },
  //     autoFocus: false,
  //     backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
  //     disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
  //   });
  //   this.dashboardService.getCloseEvent().subscribe((res: any) => {
  //     this.filterDataForChip = [];
  //     let filterData = res;
  //     if (filterData !== null && filterData !== undefined) {
  //       this.dashboardForm.value.categories = [];
  //       let categoriesArray: any = [];
  //       if (filterData.category && filterData.category.length !== 0) {
  //         filterData.category.forEach((item: any) => {
  //           categoriesArray.push(item);
  //         })
  //       }
  //       this.dashboardForm.value.categories = categoriesArray;
  //       this.dashboardForm.value.filterStatus = filterData.status;
  //       this.dashboardForm.value.filterbudget = filterData.budget;
  //       this.dashboardForm.value.filterSpent = filterData.actualSpent;

  //       this.dashboardForm.get('categories')?.setValue(categoriesArray);
  //       this.dashboardForm.get('pagination')?.setValue('1');
  //       this.dashboardForm.get('filterStatus')?.setValue(filterData.status);
  //       this.dashboardForm.get('filterbudget')?.setValue(filterData.budget);
  //       this.dashboardForm.get('filterSpent')?.setValue(filterData.actualSpent);

  //       this.getTableData();
  //       this.filterDataForChip = filterData.chipData;
  //     }

  //   });
  // }

  addEvent(date: any) {
    this.datePickerInput = date.value;
  }

  setFromDate(event: any) {
    let value = event.target.value;
    let parsedDate = moment(value, "YYYY-MM-DD");
    this.fromDate = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.fromDate = this.fromDate;

  }

  setToDate(event: any) {
    let value = event.target.value;
    let parsedDate = moment(value, "YYYY-MM-DD");
    this.toDate = parsedDate.format("MM/DD/YYYY");
    this.dashboardForm.value.toDate = this.toDate;
  }

  // customOkClick() {
  //   // this.dashboardForm.controls["pagination"].reset(); 

  //   if (this.selectedStatus) {
  //     this.dashboardForm.get('filterStatus')?.setValue(this.selectedStatus);
  //     this.getTableData();
  //   }
  //   debugger
  //   if (this.dateFrom && this.dateTo) {
  //     this.filterDataForChip = null;
  //     const dateCompare = this.compareDates(this.dateFrom, this.dateTo);
  //     if (dateCompare === 1) {
  //       this.notifyService.showError("To Date should be greater than From Date", "Invalid Date");
  //     } else {
  //       this.dashboardForm.get('toDate')?.setValue(this.dateTo);
  //       this.dashboardForm.get('fromDate')?.setValue(this.dateFrom);
  //       this.dashboardForm.get('pagination')?.setValue('1');
  //       this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
  //       let parsedDate = moment(this.dateFrom, "YYYY-MM-DD");
  //       this.fromDate = parsedDate.format("MM/DD/YYYY");
  //       let parsedTo = moment(this.dateTo, "YYYY-MM-DD");
  //       this.toDate = parsedTo.format("MM/DD/YYYY");
  //       this.getCampaignTotalData(this.fromDate, this.toDate);
  //     }
  //   } else {
  //     this.notifyService.showError("Please select from/to date", "Alert");
  //   }
  // }


  customOkClick() {
    // Reset filter data
    this.filterDataForChip = null;
    debugger
    // Check if status is selected
    if (this.selectedStatus && !this.dateFrom) {
      this.dashboardForm.get('filterStatus')?.setValue(this.selectedStatus);
      this.getTableData();  // Always fetch table data if status is present
    }

    // Check if both dates are provided
    if (this.dateFrom && this.dateTo) {
      const dateCompare = this.compareDates(this.dateFrom, this.dateTo);

      if (dateCompare === 1) {
        this.notifyService.showError("To Date should be greater than From Date", "Invalid Date");
        return;
      }

      // Update form fields with selected dates
      this.dashboardForm.patchValue({
        toDate: this.dateTo,
        fromDate: this.dateFrom,
        pagination: '1',
        dateFilter: this.dateFilter,
        filterStatus: this.selectedStatus ? this.selectedStatus : ''
      });

      // Format dates for API call
      this.fromDate = moment(this.dateFrom, "YYYY-MM-DD").format("MM/DD/YYYY");
      this.toDate = moment(this.dateTo, "YYYY-MM-DD").format("MM/DD/YYYY");

      // Fetch data for the selected date range
      this.getCampaignTotalData(this.fromDate, this.toDate);
    } else if (!this.selectedStatus) {
      // Show error if neither status nor dates are provided
      this.notifyService.showError("Please select from/to date", "Alert");
    }
  }


  compareDates(fromDateStr: string, toDateStr: string): number {
    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);

    // Compare the dates using getTime() method of Date
    if (fromDate.getTime() > toDate.getTime()) {
      return 1; // From date is greater than to date
    } else if (fromDate.getTime() < toDate.getTime()) {
      return -1; // From date is less than to date
    } else {
      return 0; // Dates are equal
    }
  }

  customClick() {
    this.datePickerVisible = true;
    this.dateFilter = '1';
    this.dashboardForm.get('dateFilter')?.setValue(this.dateFilter);
    this.fromDate = null
    this.toDate = null
  }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    this.dashboardForm.value.pagination = activePageNumber;
    // this.getTableData();
    this.afterPaginationGetTableData();
  }

  afterPaginationGetTableData() {
    this.data = [];

    this.dashboardService.getBrandSpecificDeals(this.dashboardForm.value, this.userData.marketplace_id).subscribe((res: any) => {
      this.recordTotalCount = 0;
      this.campaignTotalData = res?.body?.data;
      // this.createDashboardForm();
      this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
      if (res && res?.body.data && res?.body.data.DEALS && res?.body.data.DEALS.length !== 0) {
        // this.recordTotalCount = res?.body.data.TOTAL_DEALS ;
        res?.body.data.DEALS.forEach((item: any) => {
          let tableData = item;
          // if (item && item.categories[0]) {
          //   // let data = this.allCategory?.find((i: any) => i.category_id === item.categories[0]);
          //   // tableData.category_type = data?.category_type;
          //   let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
          //   tableData.created_date = parsedDate.format("DD/MM/YYYY");
          //   this.data.push(tableData);
          // }
          if (item && item.categories) {
            let categoryTypes: string[] = [];
            item.categories.forEach((categoryId: number) => {
              let data = this.allCategory?.find((i: any) => i.category_id === categoryId);
              if (data) {
                categoryTypes.push(data.category_type);
              }
            });
            let parsedDate = moment(tableData.created_date, "YYYY-MM-DD");
            tableData.created_date = parsedDate.format("DD/MM/YYYY");
            tableData.category_type = categoryTypes.join(', ');
            this.data.push(tableData);
          }
        });
        this.createColumnDefs();
      } else {
        //to add mock data here if no data is there in API
        //this.data = this.dashboardData.AllCampaignTestData;
        // to remove when API data comes        
      }
    }, (error) => {

      //this.data = this.dashboardData.AllCampaignTestData;
    });
  }

  search() {
    this.getTableData();
  }

  clearAll() {
    this.filterDataForChip = null;
    // this.dashboardForm.reset();

    // this.dashboardForm.value.categories = [];
    // this.dashboardForm.value.filterStatus = '';
    // this.dashboardForm.value.filterbudget = '';
    // this.dashboardForm.value.filterSpent = '';


    this.dashboardForm.controls["filterStatus"].reset();
    this.dashboardForm.controls["filterbudget"].reset();
    this.dashboardForm.controls["filterSpent"].reset();
    this.dashboardForm.controls["categories"].reset();

    this.dashboardForm.get('filterStatus')?.setValue('');
    this.dashboardForm.get('filterbudget')?.setValue('');
    this.dashboardForm.get('filterSpent')?.setValue('');
    this.dashboardForm.get('categories')?.setValue('');
    this.dashboardForm.value.categories = [];
    this.dashboardForm.value.filterStatus = '';
    this.dashboardForm.value.filterbudget = '';
    this.dashboardForm.value.filterSpent = '';
    this.dashboardForm.get('pagination')?.setValue('1');
    this.last15DaysData();
  }

  getDynamicClass() {
    return this.placeholders['locale'] === 'en' ? 'classEn' : 'classFr';
  }

  createrecordtext() {
    return this.placeholders['locale'] === 'en' ? 'createrecordtextEn' : 'createrecordtextFr';
  }

  reload() {
    window.location.reload();
  }






  dealsData = [
    { id: 'D-1LBKAY6L5405K', name: 'Test Deal for status', date: '2025-03-12', category: 'Apparel & Shoes', status: 'Pending', budget: 10, spent: 0, charged: 0 },
    { id: 'D-17SFGMSZH1XLI', name: 'Test deal for draft date', date: '2025-02-26', category: 'Financials', status: 'Draft', budget: 10, spent: 0, charged: 0 },
    { id: 'D-10RE73SP91PGK', name: 'test deal for draft', date: '2025-02-26', category: 'Financials', status: 'Completed', budget: 10, spent: 0, charged: 0 },
    { id: 'D-1E2NSSCTF5STK', name: 'Test Deal for Image', date: '2025-02-12', category: 'Financials', status: 'Active', budget: 0, spent: 0, charged: 0 },
  ];

  selectedStatus = ''; // For dropdown filter
  dateFrom = '';
  dateTo = '';
  statusList = ['Pending', 'Draft', 'Completed', 'Active', 'Expired'];

  filteredDeals = [...this.dealsData]; // Copy of original data

  filterDeals() {
    debugger
    this.filteredDeals = this.dealsData.filter(deal => {
      const isStatusMatch = this.selectedStatus ? deal.status === this.selectedStatus : true;
      const isDateMatch = this.dateFrom && this.dateTo
        ? new Date(deal.date) >= new Date(this.dateFrom) && new Date(deal.date) <= new Date(this.dateTo)
        : true;
      return isStatusMatch && isDateMatch;
    });
  }

  resetFilters() {
    this.selectedStatus = '';
    this.dateFrom = '';
    this.dateTo = '';
    this.dashboardForm.get('filterStatus')?.setValue('');
    this.filteredDeals = [...this.dealsData];
    this.last3MonthData()
  }

  // generatePDF() {
  //   debugger
  //   this.data
  //   const doc = new jsPDF();

  //   const reportDate = new Date().toLocaleDateString(); // Get current date

  //   // 🏢 Merchant Details
  //   const merchantDetails = `
  //   Merchant: ABC Pvt Ltd
  //   Address: 123, Business Street, NY, USA
  //   Contact: support@abc.com | +1 234 567 890
  //   Report Date: ${reportDate}
  //   `;

  //   // 📝 Title & Merchant Info
  //   doc.setFontSize(18);
  //   doc.text('Deals Report', 14, 20);
  //   doc.setFontSize(12);
  //   doc.text(merchantDetails, 14, 30);


  //   // doc.setFontSize(16);
  //   // doc.text('Deals Report', 14, 20);

  //   const tableColumn = ['Unique ID', 'Name', 'Date Created', 'Category', 'Status', 'Budget ($)', 'Spent ($)', 'Charged ($)'];
  //   const tableRows: any[] = [];

  //   this.data.forEach((deal:any) => {
  //     tableRows.push([
  //       deal.uuid,
  //       deal.product_name,
  //       deal.created_date,
  //       deal.category_type,
  //       deal.status,
  //       `$${deal.budget}`,
  //       `$${deal.total_spent}`,
  //       `$${deal.spentAlreadyCharged}`,
  //     ]);
  //   });

  //   autoTable(doc, { head: [tableColumn], body: tableRows, startY: 30 });

  //   doc.save('Filtered_Deals_Report.pdf');
  // }


  generatePDF() {
    const doc = new jsPDF();
    const reportDate = new Date().toLocaleDateString(); // Get current date

    // 🏢 Merchant Details
    const merchantDetails = `Merchant: ${this.userData.marketplace_name}
Address: ${this.userData.addressLine1}, ${this.userData.city_name}, ${this.userData.country_name}
Contact: +${this.userData.business_contact_number}
Report Date: ${reportDate}
    `;

    // Load the brand logo (Base64 or image path)
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src =  'assets/images/expenses.png'; //environment.assetsUrl + '/assets/' + this.userData?.marketplace_image // Change path as needed

    img.onload = () => {
      // Convert image to Base64 and add to PDF
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      const imgData = canvas.toDataURL("image/png");

      // Add Logo to PDF


      // 🎨 Style the Header (Like an Invoice)
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('DEALS REPORT', 105, 20, { align: 'center' });

      doc.addImage(imgData, 'PNG', 10, 20, 40, 30);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(merchantDetails, 60, 30);

      // 🏷️ Table Headers
      const tableColumn = ['Unique ID', 'Name', 'Date Created', 'Category', 'Status', 'Budget ($)', 'Spent ($)', 'Charged ($)'];
      const tableRows: any[] = [];

      // 🔄 Loop through data & format rows
      this.alldata.forEach((deal: any) => {
        tableRows.push([
          deal.uuid,
          deal.product_name,
          deal.created_date,
          deal.category_type,
          deal.status,
          `$${deal.budget}`,
          `$${deal.total_spent}`,
          `$${deal.spentAlreadyCharged}`,
        ]);
      });

      doc.setLineWidth(0.5); // Set line thickness
      doc.line(10, 50, 200, 50);

      const startY = 60; // Position below line
      doc.setFontSize(11);
      doc.setFont('helvetica', 'bold');

      doc.text( `Total Deals:`,20, startY);
      doc.text( `${this.reportTotalDeals}`,45, startY);
  
      doc.text( `Total Budget:`,55, startY);
      doc.text( `$${this.reportTotalBudget}`,80, startY);
  
      doc.text( `Total Spent:`,100, startY);
      doc.text( `$${this.reportTotalSpent}`,125, startY);

      doc.text( `Total Charged Till Now:`,140, startY);
      doc.text( `$${this.reportSpendCharged}`,185, startY);

      
  
      // Draw another line below summary for separation
      doc.line(10, startY + 5, 200, startY + 5); // (x1, y1, x2, y2)

      // 🏆 Generate Table with AutoTable (Add Padding & Styling)
      autoTable(doc, {
        head: [tableColumn],
        body: tableRows,
        startY: 70,
        margin: { left: 10 },
        theme: 'grid',
        styles: { fontSize: 8, cellPadding: 3 }, // Add padding inside table cells
        columnStyles: {
          0: { cellWidth: 25 }, // Unique ID
          1: { cellWidth: 30 }, // Name
          2: { cellWidth: 30 }, // Date Created
          3: { cellWidth: 30 }, // Category
          4: { cellWidth: 20 }, // Status
          5: { cellWidth: 18 }, // Budget
          6: { cellWidth: 18 }, // Spent
          7: { cellWidth: 18 }, // Charged
        },
        headStyles: { fillColor: [44, 62, 80], textColor: [255, 255, 255], fontStyle: 'bold' }, // Dark header with white text
        // margin: { top: 50 } // Add margin from top
      });

      // 📥 Save PDF File
      doc.save(`Deals_Report_${reportDate}.pdf`);
    }




    // exportToPDF() {
    //   debugger
    //   const pdf = new jsPDF('p', 'mm', 'a4'); // Create PDF instance

    //   // Select the chart container and convert it to an image
    //   const chartElement = document.getElementById('dealsChart'); // Change ID if needed
    //   if (chartElement) {
    //     html2canvas(chartElement).then((canvas) => {
    //       const imgData = canvas.toDataURL('image/png'); // Convert canvas to image

    //       pdf.addImage(imgData, 'PNG', 10, 10, 180, 90); // Add chart to PDF (x, y, width, height)

    //       // Now capture the table
    //       const tableElement = document.getElementById('dealsTable'); // Change ID if needed
    //       if (tableElement) {
    //         html2canvas(tableElement).then((tableCanvas) => {
    //           const tableImgData = tableCanvas.toDataURL('image/png');
    //           pdf.addImage(tableImgData, 'PNG', 10, 110, 180, 120); // Add table below chart

    //           pdf.save('Deals_Report.pdf'); // Download the PDF
    //         });
    //       }
    //     });
    //   }
    // }


    // ngAfterViewInit() {
    //   // this.createCharts();
    // }

    // createCharts() { 
    //   // Deals Bar Chart
    //   // new Chart('dealsChart', {
    //   //   type: 'bar',
    //   //   data: {
    //   //     labels: this.dealsData.map(d => d.name),
    //   //     datasets: [{
    //   //       label: 'Budget ($)',
    //   //       data: this.dealsData.map(d => d.budget),
    //   //       backgroundColor: 'blue',
    //   //     }]
    //   //   }
    //   // });

    //   // Group deals by category and count occurrences
    //   const categoryCounts: { [key: string]: number } = {};
    //   this.dealsData.forEach(deal => {
    //     categoryCounts[deal.category] = (categoryCounts[deal.category] || 0) + 1;
    //   });

    //   // Extract labels (categories) and values (number of deals per category)
    //   const categoryLabels = Object.keys(categoryCounts);
    //   const categoryValues = Object.values(categoryCounts);

    //   // Deals Bar Chart: Categories vs Number of Deals
    //   new Chart('dealsChart', {
    //     type: 'bar',
    //     data: {
    //       labels: categoryLabels, // Categories on X-axis
    //       datasets: [{
    //         label: 'Number of Deals',
    //         data: categoryValues, // Number of deals in each category
    //         backgroundColor: 'blue',
    //       }]
    //     },
    //     options: {
    //       responsive: true,
    //       plugins: {
    //         legend: {
    //           display: false
    //         }
    //       },
    //       scales: {
    //         y: {
    //           beginAtZero: true,
    //           title: {
    //             display: true,
    //             text: 'Number of Deals'
    //           }
    //         },
    //         x: {
    //           title: {
    //             display: true,
    //             text: 'Categories'
    //           }
    //         }
    //       }
    //     }
    //   });

    //   // Budget Pie Chart
    //   new Chart('budgetChart', {
    //     type: 'pie',
    //     data: {
    //       labels: this.dealsData.map(d => d.name),
    //       datasets: [{
    //         label: 'Budget Distribution',
    //         data: this.dealsData.map(d => d.budget),
    //         backgroundColor: ['red', 'green', 'blue', 'yellow'],
    //       }]
    //     }
    //   });

    //   // Status Distribution Chart
    //   const statusCounts = this.statusList.map(status => this.dealsData.filter(d => d.status === status).length);
    //   new Chart('statusChart', {
    //     type: 'doughnut',
    //     data: {
    //       labels: this.statusList,
    //       datasets: [{
    //         label: 'Status Distribution',
    //         data: statusCounts,
    //         backgroundColor: ['orange', 'gray', 'blue', 'green', 'red'],
    //       }]
    //     }
    //   });
    // }

    // updateCharts() {
    //   this.createCharts(); // Re-draw charts after filtering
    // }

  }
}