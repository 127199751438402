<div *ngIf="!paymentInitiate">
    <p *ngIf="userData.status === 'IA'" style="text-align: center;
color: #870606;
font-size: large;
background: #f2acac;
border-radius: 10px;">Payment Method Failed: Your payment method could not be processed. Please update your payment details to activate your account and send messages to customers.</p>
    <a routerLink="/messaging" class="div-26">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="rgba(135,138,155,1)">
            <path
                d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM12 11H16V13H12V16L8 12L12 8V11Z">
            </path>
        </svg>
        {{placeHolders['LBL_BACK']}}</a>
    <mat-card style="padding: 5px;">
        <div>
            <div class="row">
                <div class="col-md-8">
                    <p class="mt-2 mb-1"><b>{{placeHolders['LBL_TBL_DEAL_TITLE']}} :</b>
                        <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                        {{dealData.deal.product_name}}
                    </p>
                </div>
                <div class="col-md-4">
                    <p class="mt-2 mb-1"><b>{{placeHolders['LBL_COUPOUN_CODE']}}:</b>
                        <i
                            class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>{{dealData.deal.coupon_code}}
                    </p>
                </div>
            </div> <!-- end row -->
            <div class="row">
                <div class="col-md-8">
                    <p class="mt-2 mb-1 deal-description">
                        <span class="label"><b>{{placeHolders['LBL_DEAL_DESCRIPTION']}} :</b></span>
                        <span class="content">
                            <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                            {{dealData.deal.product_description}}
                        </span>
                    </p>
                </div>
                <div class="col-md-4">
                    <p class="mt-2 mb-1"><b>{{placeHolders['LBL_DISCOUNT_TYPE']}}:</b>
                        <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                        {{dealData.deal.discountDescription}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <label><b>{{placeHolders['LBL_TARGET_AUDEINEC_FOR_DEAL']}}:</b></label>
                </div>
                <div class="col-md-4">
                    <p class="mt-2 mb-1"><b>{{placeHolders['LBL_VALIDITY_FROM']}}:</b>
                        <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                        {{dealData.deal.available_from | date: 'dd-MM-yyyy' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 row">
                    <div class="col-md-2"><b>{{placeHolders['LBL_GENDER']}}: </b><i>{{dealData.deal.gender}}</i>
                    </div>
                    <div class="col-md-3"><b>{{placeHolders['LBL_AGE_RANGE']}}: </b><i>{{ageRangeLabel}}</i>
                    </div>
                    <div class="col-md-3"><b>{{placeHolders['LBL_LOCATION']}}: </b><i>{{dealData.deal.countryName}}</i>
                    </div>
                </div>
                <div class="col-md-4" style="margin-left: 24px;">
                    <p class="mt-2 mb-1"><b>{{placeHolders['LBL_VALIDITY_TO']}}:</b>
                        <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                        {{dealData.deal.available_to |
                        date: 'dd-MM-yyyy'}}
                    </p>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="tabs pt-2">
        <button class="btn-nav" (click)="activeTab = 'messaging'" [class.active]="activeTab === 'messaging'"
            [class.bg-messaging]="activeTab === 'messaging'"
            [disabled]="dealData.deal.status !== 'A'">{{placeHolders['LBL_TITLE_MESSAGING']}}</button>
        <button class="btn-nav" style="margin-left: 1px;" (click)="activeTab = 'history'"
            [class.active]="activeTab === 'history'"
            [class.bg-history]="activeTab === 'history'">{{placeHolders['LBL_TAB_HISTORY']}}</button>
    </div>
    <mat-card style="padding: 5px; margin-top: 6px;">


        <div [ngSwitch]="activeTab">
            <ng-container *ngSwitchCase="'messaging'">
                <div class="p-2">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-chip class="chip-color">
                                <p class="mt-2 mb-1">#{{placeHolders['LBL_HIGH_PROSPECTUS']}}:
                                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                    {{dealData.details.totalUniqueProspects}}
                                </p>
                            </mat-chip>
                        </div>
                        <div class="col-md-3">
                            <mat-chip class="chip-color">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_RETARGTED']}}:
                                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                    {{dealData.details.totalCustomerOfferMade}}
                                </p>
                            </mat-chip>
                        </div>
                        <div class="col-md-3">
                            <mat-chip class="chip-color">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_PENDING_PROSPECTUS']}}:
                                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                    {{dealData.details.totalPendingProspects}}
                                </p>
                            </mat-chip>
                        </div>
                    </div> <!-- end row -->
                    <div class="row pt-2">

                        <div class="col-md-3">
                            <mat-chip class="chip-color">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_COST_PER_MSG']}}:
                                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                    {{msgSendCost?.cost}}
                                </p>
                            </mat-chip>
                        </div>
                        <div class="col-md-3">
                            <mat-chip class="chip-color">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_TBL_TOTAL_SPENT']}}:
                                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                    {{spent}}
                                </p>
                            </mat-chip>
                        </div>

                    </div> <!-- end row -->
                    <div class="row">

                    </div> <!-- end row -->
                </div>
                <mat-card style="background-color: #36849d78;padding: 16px;">
                    <div class="filter-controls">
                        <div class="control-group">
                            <label for="gender">{{placeHolders['LBL_GENDER']}}:</label>
                            <select id="gender" [(ngModel)]="selectedGender">
                                <option value="">{{placeHolders['LBL_SELECT_GENDER']}}</option>
                                <option value="male">{{placeHolders['LBL_MALE']}}</option>
                                <option value="female">{{placeHolders['LBL_FEMALE']}}</option>
                                <option value="">{{placeHolders['LBL_ALL']}}</option>
                            </select>
                        </div>

                        <div class="control-group">
                            <label for="ageRange">{{placeHolders['LBL_AGE_RANGE']}}:</label>
                            <select id="ageRange" [(ngModel)]="selectedAgeRange">
                                <option value="">{{placeHolders['LBL_SELECT_AGE_RANGE']}}</option>
                                <option value="">{{placeHolders['LBL_ALL']}}</option>
                                <option *ngFor="let range of filteredAgeRanges"
                                    [value]="range.minAge + '-' + range.maxAge">
                                    {{ range.label }}
                                </option>
                                <!-- <option value="1-100">All</option>
                            <option value="13-17">13-17</option>
                            <option value="18-25">18-25</option>
                            <option value="26-40">26-40</option>
                            <option value="41-60">41-60</option>
                            <option value="61-Above">61+</option> -->
                            </select>
                        </div>

                        <div class="control-group">
                            <label for="state">{{placeHolders['LBL_STATE']}}:</label>
                            <select id="state" [(ngModel)]="selectedState" (change)="stateSelectionForCity($event)">
                                <option value="">{{placeHolders['LBL_SELECT_STATE']}}</option>
                                <option *ngFor="let state of stateData" [value]="state.state_id">
                                    {{ state.state_name }}
                                </option>
                            </select>
                        </div>

                        <div class="control-group">
                            <label for="city">{{placeHolders['LBL_CITY']}}:</label>
                            <select id="city" [(ngModel)]="selectedCity">
                                <option value="">{{placeHolders['LBL_SELECT_CITY']}}</option>
                                <option *ngFor="let city of cityData" [value]="city.city_name">
                                    {{ city.city_name }}
                                </option>
                                <!-- Add city options here -->
                            </select>
                        </div>

                        <button style="margin-top: 22px;" (click)="getFilteredData()"
                            class="filter-button">{{placeHolders['LBL_APPLY_FILTER']}}</button>
                    </div>
                </mat-card>
                <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
                    <div style="padding: 20px;">
                        <div class="row">
                            <div class="col-md-6">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_TARGETED_PROSPECTUS']}} :
                                    <input style="background-color: #80808063;" class="inputstyle" matInput
                                        placeholder="Target" formControlName="targetProspectus" [readonly]="true">
                                    <!-- <mat-error
                                    *ngIf="messageForm?.get('targetProspectus')?.invalid && messageForm?.get('targetProspectus')?.touched">
                                    Please enter a valid target (minimum 1)
                                </mat-error> -->
                                </p>
                            </div>
                            <div class="col-md-6">
                                <p class="mt-2 mb-1" >{{placeHolders['LBL_NEW_COUPOUN_CODE']}}<span style="color: red;">*</span>:
                                    <input style="width: 46%;" class="inputstyle" matInput
                                        placeholder="{{placeHolders['LBL_COUPOUN_CODE']}}" formControlName="couponCode"
                                        maxlength="20">
                                    <!-- <mat-error
                                    *ngIf="messageForm?.get('couponCode')?.invalid && messageForm?.get('couponCode')?.touched">
                                    Please enter a valid coupon code (minimum 3 characters)
                                </mat-error> -->
                                </p>
                            </div>
                        </div> <!-- end row -->
                        <div class="row">
                            <div class="col-md-12">
                                <p class="mt-2 mb-1" >{{placeHolders['LBL_MESSAGE_DESC']}}<span style="color: red;">*</span>:
                                    <textarea style="width: 88%; border-radius: 5px;" matInput rows="5" maxlength="300"
                                        placeholder="{{placeHolders['LBL_ENTER_DEAL_DESCRIPTION']}}"
                                        formControlName="messageDescription"></textarea>
                                    <span style="float: inline-end; font-size: small;margin-right: 125px;">
                                        {{placeHolders['LBL_CHAR_COUNT']}}: {{
                                        charCount}}/300</span>
                                    <!-- <mat-error
                                    *ngIf="messageForm?.get('messageDescription')?.invalid && messageForm?.get('messageDescription')?.touched">
                                    Please enter a message description (maximum 300 characters)
                                </mat-error> -->
                                </p>

                            </div>
                        </div> <!-- end row -->
                        <div class="row mt-2">
                            <div class="col-md-7">
                                <p class="mt-2 mb-1">{{placeHolders['LBL_TOTAL_COST']}}:
                                    <input style="background-color: #80808063;" class="inputstyle" matInput
                                        placeholder="Total" formControlName="totalCost" [readonly]="true">
                                    <!-- <mat-error
                                    *ngIf="messageForm?.get('totalCost')?.invalid && messageForm?.get('totalCost')?.touched">
                                    Please enter a valid total cost (minimum 0)
                                </mat-error> -->
                                </p>
                            </div>
                            <div class="col-md-2" *ngIf="isMsgCharge">
                                <button *ngIf="userData.status !== 'IA' " type="button" [disabled]="messageForm.invalid" style="float: left;margin-left: -330px;" (click)="openPaymentDialog()"
                                    class="btn btn-primary btn-block">Pay & {{placeHolders['LBL_SEND_MESSAGE']}} </button>
                            </div>
                            <div class="col-md-2" *ngIf="!isMsgCharge">
                                <!--  -->
                                <button type="submit" class="btn div-63" style="float: left;margin-left: -330px;"
                                    [disabled]="messageForm.invalid">{{placeHolders['LBL_SEND_MESSAGE']}}</button>
                            </div>
                        </div> <!-- end row -->
                    </div>
                </form>
                <!-- <div style="padding: 20px;">
                <div class="row">
                    <div class="col-md-6">
                        <p class="mt-2 mb-1">Target Prospectus :
                            <input class="inputstyle" matInput placeholder="Target">
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p class="mt-2 mb-1"> New Coupon Code :
                            <input class="inputstyle" matInput placeholder="Coupon code">
                        </p>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1">Message Description :
                            <textarea style="width: 88%; border-radius: 5px;" matInput rows="5" maxlength="300"
                                placeholder="Enter deal description"></textarea>
                        </p>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-8">
                        <p class="mt-2 mb-1">Total Cost :
                            <input class="inputstyle" matInput placeholder="Total">
                        </p>
                    </div>
                    <div class="col-md-2">
                        <button class="btn div-63">Send Message</button>
                    </div>

                </div> 
            </div> -->
            </ng-container>
            <ng-container *ngSwitchCase="'history'">
                <div class="col-md-2 pt-3">
                    <mat-chip class="chip-color">
                        <p class="mt-2 mb-1">{{placeHolders['LBL_TOTAL_SPENT']}}: <span>{{totalAmountSpent}}</span></p>
                    </mat-chip>
                </div>
                <table class="mt-2">
                    <thead>
                        <tr>
                            <th>{{placeHolders['LBL_MESSAGES_SENT_ON']}}</th>
                            <th>{{placeHolders['LBL_MESSAGE_DESC']}}</th>
                            <th>{{placeHolders['LBL_COUPOUN_CODE']}}</th>
                            <th>{{placeHolders['LBL_MESSAGES_SENT']}}</th>
                            <th>{{placeHolders['LBL_MESSAGES_VIEWED']}}</th>
                            <th>{{placeHolders['LBL_TBL_TOTAL_SPENT']}}</th>
                            <th>{{placeHolders['LBL_APPLIED_FILTER']}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of messageData">
                            <td>{{ data.sentOn | date:'short' }}</td>
                            <td>{{ data.description }}</td>
                            <td>{{ data.couponCode }}</td>
                            <td>{{ data.messagesSent }}</td>
                            <td>{{ data.messagesViewed }}</td>
                            <td>{{ data.totalSpent | currency }}</td>
                            <td>{{ data.appliedFilter}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </mat-card>
</div>
<mat-card *ngIf="paymentInitiate"  style="padding: 10px;">
    <div class="col-12 row">
        <div class="col-1">
            <button (click)="back()" class="btn btn-primary btn-block">Back</button>
        </div>
        <div class="col-5 right-border">
            <h3 style="justify-content: center;">Summary</h3><br>
            <span class="pt-3"><b>Deal Title</b>: {{dealData.deal.product_name}}</span><br>
            <span><b>Start Date: </b> {{dealData.deal.available_from | date: 'dd-MM-yyyy'}}</span> | <span><b>End
                    Date:</b> {{dealData.deal.available_to | date: 'dd-MM-yyyy'}}</span>

            <h4 class="pt-3">Cost : {{costTotal}}</h4>
            <div className=" p-4 space-y-2" style="background: #e3e3e3;border-radius: 6px;">
                <!-- <h3 className="font-semibold text-lg">Transaction Fee:</h3> -->
                <p style="margin-left: 10px;" className="text-sm text-muted-foreground">2.9% + $0.30 for online credit
                    card transactions in the
                    U.S.</p>
            </div>
            <!-- 
        <div class="pt-3">
            <img *ngIf="imagePreview; else dealImage" [src]="imagePreview" alt="Image"
                style="max-width: 300px; max-height: 300px;" />

            <ng-template #dealImage>
                <img [src]="brandimage" alt="Image" style="max-width: 300px; max-height: 300px;" />
               
            </ng-template>
        </div> -->
        </div>
        <div class="col-6">
            <app-paymentcheckout [amount]="billAmount" [currency]="billCurrency" [description]="billDescription"
                [brandId]="brandId" [type]="type">
            </app-paymentcheckout>
        </div>

    </div>
</mat-card>