import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { LoginService } from 'src/app/services/login.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenStorage } from 'src/app/token.storage';
import { SessionStorageService } from 'ngx-webstorage';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
// import { LocationService } from 'src/app/services/location.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs';

// import getMAC, { isMAC } from 'getmac';

import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/translation.service';
import { defaultConfig } from 'src/config/default-config';
import { botswanaConfig } from 'src/config/botswana-config';
import { zimConfig } from 'src/config/zim-config';
import { coteivoryConfig } from '../../../config/Cote-ivory-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  show = false;
  logInForm: FormGroup = new FormGroup({
    email_id: new FormControl(''),
    password: new FormControl(''),
  });
  user: any;
  private loggedIn = false;
  private token1: any;
  isRemember = false;
  login: any;
  // location: any;
  macAddress: any;
  placeHolders: any
  apiPlaceHolders: any;
  placeholders = environment.placeholders as { [key: string]: string };
  logoUrl: any;
  faviconLogo: any;
  countryConfig: any;
  postlogoUrl: any;
  countrylogoUrl: any;
  countryName: any;

  constructor(
    private router: Router, private service: CommonUtilService, private fb: FormBuilder,
    private cookieService: CookieService, private token: TokenStorage,
    private storageService: SessionStorageService,
    private clientStorage: ClientSideStorageService, private http: HttpClient,
    private notifyService: NotificationAlertService, private loginService: LoginService,
    private renderer: Renderer2, private translationService: TranslationService,

  ) {

    // const postValue =  this.clientStorage.get('postcountry')  ?? history.state.countryConfig.country 

    const postValue = this.clientStorage.get('postcountry')
      ?? history.state?.countryConfig?.country;

    if (!postValue) {
      // Redirect to a blank route if postValue is undefined or null
      this.router.navigate(['/']);
    }
    this.countryConfig = this.getConfigByCountry(postValue) ?? defaultConfig;
    this.logoUrl = this.countryConfig.logoUrl;
    this.faviconLogo = this.countryConfig.shortLogo;
    this.postlogoUrl = this.countryConfig.loginPostlogo
    this.countrylogoUrl = this.countryConfig.loginCountrylogo
    this.countryName = this.countryConfig.country
    this.placeHolders = this.placeholders['login_page']
    this.apiPlaceHolders = this.placeholders['API_Messages']

  }

  getConfigByCountry(country: string): any {
    switch (country) {
      case 'Botswana':
        return botswanaConfig;
      case 'Zimbabwe':
        return zimConfig;
      case 'Cote De Ivory':
        return coteivoryConfig;
      default:
        return defaultConfig;
    }
  }

  ngOnInit() {
    this.clientStorage.set('userName', JSON.stringify(null));
    const token = this.cookieService.get('token');
    if (token != '' && token != null) {
      this.router.navigate(['/dashboard']);
    } else {
      this.clearSession();
    }
    this.createLogInForm();
    this.macAddress = Math.random();
    let payload = {
      'location': null,
      'macAddress': this.macAddress
    }
    this.clientStorage.set('country', JSON.stringify([]));
    this.clientStorage.set('macAddress', JSON.stringify(this.macAddress));
    this.clientStorage.set('postcountry', this.countryConfig.country);
    // navigator.geolocation.getCurrentPosition((position: any) => {

    //   let lat = position.coords.latitude;
    //   let lng = position.coords.longitude;
    //   //this.http.get('https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=' + lat + '&longitude=' + lng + '&localityLanguage=en').subscribe((location: any) => {
    //   // if (location) {
    //   // this.location = location;
    //   this.macAddress = Math.random();
    //   let payload = {
    //     'location': null,
    //     'macAddress': this.macAddress
    //   }
    //   this.clientStorage.set('country', JSON.stringify([]));
    //   this.clientStorage.set('macAddress', JSON.stringify(this.macAddress));
    //   // }
    //   // });
    // });    

  }

  createLogInForm() {
    this.logInForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.logInForm.controls;
  }

  togglePasswordVisibility() {
    this.show = !this.show;
  }

  logIn() {
    if (this.logInForm.invalid) {
      if (this.logInForm.controls['email_id'].touched !== true && this.logInForm.controls['password'].touched !== true) {
        this.notifyService.showError(this.placeHolders['MSG_EMAIL_PWD_NOT_EMPTY'], "Invalid");
      } else if (this.logInForm.controls['email_id'].touched !== true) {
        this.notifyService.showError(this.placeHolders['MSG_EMAIL_NOT_EMPTY'], "Invalid");
      } else if (this.logInForm.controls['password'].touched !== true) {
        this.notifyService.showError(this.placeHolders['MSG_PASSWORD_EMPTY'], "Invalid");
      } else if (this.logInForm.controls['email_id'].invalid && this.logInForm.controls['password'].invalid) {
        this.notifyService.showError(this.placeHolders['MSG_INVALID_EMAIL_PWD'], "Invalid");
      } else if (this.logInForm.controls['email_id'].invalid) {
        this.notifyService.showError(this.placeHolders['MSG_INVALID_EMAIL'], "Invalid");
      } else if (this.logInForm.controls['password'].invalid) {
        this.notifyService.showError(this.placeHolders['MSG_INVALID_PASSWORD'], "Invalid");
      }
    } else {
      // let address = this.getMacAddress();
      let data = {
        email_id: this.logInForm.value.email_id.toLowerCase(),
        password: this.logInForm.value.password
      }
      // this.loginService.logIn(data, [], this.macAddress).subscribe((response: any) => {
      //   // this.router.navigate(["/dashboard"]);
      //   if (response.body?.status === "ERROR") {
      //     this.notifyService.showError(response.body.message, response.body.status);
      //   } else {
      //     let authToken = response.headers.get('auth-token');
      //     let refreshToken = response.headers.get('refresh-token');
      //     if (authToken != undefined && response.body?.data) {
      //       this.token1 = authToken;
      //       this.clientStorage.set('token', JSON.stringify(authToken));
      //       this.clientStorage.set('refreshToken', JSON.stringify(refreshToken));
      //       // this.token.saveToken(authToken);
      //       this.clientStorage.set('userName', JSON.stringify(this.logInForm.value.email_id.toLowerCase()));
      //       this.loginService.setSystemUser(response.body.data);
      //       this.clientStorage.set('userId', JSON.stringify(response.body?.data.personId));
      //       // this.clientStorage.set('country', JSON.stringify(this.location.countryCode));
      //       // this.clientStorage.set('macAddress', JSON.stringify(this.macAddress));
      //       this.clientStorage.set('loggedIn', JSON.stringify(true));

      //       this.router.navigate(["/companyProfile"]);
      //     } else { //for dev enviroment(which is without security)

      //       this.clientStorage.set('userName', JSON.stringify(this.logInForm.value.email_id.toLowerCase()));
      //       this.loginService.setSystemUser(response.body.data);
      //       this.clientStorage.set('userId', JSON.stringify(response.body?.data.personId));
      //       this.clientStorage.set('loggedIn', JSON.stringify(true));
      //       this.router.navigate(["/dashboard"]);
      //     }
      //   }
      // });

      const userInput = data
      this.loginService.logIn(userInput, [], this.macAddress).pipe(
        switchMap((firstApiResponse: any) => {
          if (firstApiResponse.body?.status === "ERROR") {
            this.notifyService.showError(this.apiPlaceHolders[firstApiResponse.body.message] + '  ' + this.apiPlaceHolders[firstApiResponse.body.data], firstApiResponse.body.status);
          } else {
            const otherData = firstApiResponse.body.data.personId;
            let authToken = firstApiResponse.headers.get('auth-token');
            let refreshToken = firstApiResponse.headers.get('refresh-token');

            const createdDateString = firstApiResponse.body.data.createdDate; // The date string from the response
            const createdDate = new Date(createdDateString); // Convert the string to a Date object
            const today = new Date();
            createdDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            if (createdDate < today) {
              sessionStorage.setItem('isNewUser', 'true');
            } else {
              sessionStorage.setItem('isNewUser', 'false');
            }

            if (authToken != undefined && firstApiResponse.body?.data) {
              this.token1 = authToken;
              this.clientStorage.set('token', JSON.stringify(authToken));
              this.clientStorage.set('refreshToken', JSON.stringify(refreshToken));
              // this.token.saveToken(authToken);
              this.clientStorage.set('userName', JSON.stringify(this.logInForm.value.email_id.toLowerCase()));
              this.loginService.setSystemUser(firstApiResponse.body.data);
              this.clientStorage.set('userId', JSON.stringify(firstApiResponse.body?.data.personId));
              // this.clientStorage.set('country', JSON.stringify(this.location.countryCode));
              // this.clientStorage.set('macAddress', JSON.stringify(this.macAddress));
              this.clientStorage.set('loggedIn', JSON.stringify(true));
              // this.loadChatbotScript(firstApiResponse.body.data)
              this.clientStorage.setChatbotData(firstApiResponse.body.data);

            }
          }
          return this.service.getRegisterCompanyDetails();
        })
      ).subscribe((finalApiResponse: any) => {

        // Handle the company response here

        let marketplaceData;
        if (finalApiResponse?.data) {
          marketplaceData = finalApiResponse?.data;
          console.log(marketplaceData)

          if (marketplaceData.country === "384") {
            this.translationService.setUserLocale('fr');
          }

          if (finalApiResponse?.data) {
            marketplaceData = finalApiResponse?.data;
            this.clientStorage.set('marketplaceData', JSON.stringify(marketplaceData));
          }
          // && marketplaceData.marketplace_type === null   marketplaceData.categories === null && 
          if (marketplaceData && marketplaceData.marketplace_image === null) {
            this.router.navigate(["/companyProfile"]);
            sessionStorage.setItem('isDashboardActive', 'false');
          } else {
            const isComingFromSource = true; // Your condition to set the flag
            // this.router.navigate(["/dashboard",isComingFromSource]);
            //Approval Flow to be replaced with super user
            if (data.email_id === 'mayurpatil.bvcoek@gmail.com') {
              this.router.navigate(["/approval"]);
              sessionStorage.setItem('isDashboardActive', 'true');
            } else if (marketplaceData.status === "R") {
              this.router.navigate(["/companyProfile"]);
              sessionStorage.setItem('isDashboardActive', 'false');
            }
            else {
              this.router.navigate(["/dashboard"]);
              sessionStorage.setItem('isDashboardActive', 'true');
            }
          }
          // this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
        }

      });

    }
  }

  // getMacAddress() {
  //   let address = getMAC();
  //   if (isMAC(address)) {
  //     return address;
  //   } else {
  //     return null;
  //   }
  // }


  // navigateToRegisterZim() {
  //   let id: any = 'Z';
  //   this.navigateToRegister(id);
  // }

  // navigateToRegisterBots() {
  //   let id: any = 'B';
  //   this.navigateToRegister(id);
  // }

  navigateToRegister() {
    this.service.setData(null);

    // if(this.countryConfig.country ==='Botswana'){
    //   this.router.navigate(["/merchantregister"],{ queryParams: { id: 'B' }});
    // }
    // else{
    //   this.router.navigate(["/merchantregister"]);
    // }




    const countryRouteMap: { [key: string]: { path: string; queryParams?: { [key: string]: any } } } = {
      'Botswana': { path: '/merchantregister', queryParams: { id: 'B' } },
      // Add more configurations here
      'Zimbabwe': { path: '/merchantregister', queryParams: { id: 'Z' } },
      'Cote De Ivory': { path: '/merchantregister', queryParams: { id: 'C' } },
    };

    // Default route configuration
    const defaultRoute = { path: '/merchantregister' };

    // Get the route configuration based on the country
    const routeConfig = countryRouteMap[this.countryConfig.country] || defaultRoute;

    // Navigate to the appropriate route
    if (routeConfig.queryParams) {
      this.router.navigate([routeConfig.path], { queryParams: routeConfig.queryParams });
    } else {
      this.router.navigate([routeConfig.path]);
    }
    // });
  }

  setLoggedIn(loggedIn: boolean, token?: string) {
    this.loggedIn = loggedIn;
    this.token1 = token;
  }


  manageLoginResponse(data: any) {
    this.user.username = data.username;
    this.user.firstName = data.firstName;
    this.user.lastName = data.lastName;
    this.user.personId = data.personId;
    this.user.status = data.status;
    // this.storageService.store('token', JSON.stringify(data.token));
    // this.storageService.store('username', JSON.stringify(data.email_id));
    // this.storageService.store('user', JSON.stringify(this.user));
    this.token.saveToken(data.token);
    this.token.saveAlterToken(data.token);
    return this.user;
  }

  clearSession() {
    this.cookieService.delete('token');
    this.cookieService.delete('exptime');
    this.token.clearStorage();
    localStorage.clear();
    sessionStorage.clear();
  }

  loadChatbotScript(data: any): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = '../../../assets/JS/script.js';
    script.onload = () => {
      // You can initialize the chatbot here if needed
      // let marketplaceData = JSON.parse(this.clientStorage.get("marketplaceData"));
      this.initializeChatbot(data);
    };
    this.renderer.appendChild(document.body, script);
  }

  initializeChatbot(attributes: any): void {
    // Pass attributes to the chatbot script
    if (window) {
      // window['Chatbot'].init(attributes);
      window.updateXelp({
        appSecretKey: "a475638631d942af372adcadd766c4c4", //eb4b9d234e5cbad8e71fe4672feda5c9
        uniqueId: attributes.personId,
        email: attributes.email_id,
        name: attributes.firstName + ' ' + attributes.lastName,
        phone: attributes.mobile,
        language: "en"
      });
    }
  }

  trimWhitespace(field: string) {
    const value = this.logInForm.controls[field].value;
    if (value) {
      this.logInForm.controls[field].setValue(value.replace(/\s/g, ''), { emitEvent: false });
    }
  }

  // switchLanguage(event: Event){
  //   debugger
  //   const selectElement = event.target as HTMLSelectElement;
  //   const selectedLanguage = selectElement.value;
  //   this.translationService.changeLocale(selectedLanguage)
  // } 

}
